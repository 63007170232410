import { useEffect, useCallback, useRef } from "react";
import { observer } from "mobx-react";

import useOnboarding from "../state/useOnboarding";
import Spinner from "../components/Spinner";
import { LivenessFrame } from "../components/LivenessFrame";
import { ButtonBar, ButtonItem } from "../components/form/FormButtons";
import useAppState from "../state/useAppState";
import { generateNewLivenessUrl } from "./../backend";
import useToaster from "../hooks/useToaster";
import { MetaEntryType } from "../state/metaModel";
import { MainSection } from "../components/Sections";
import { OnboardingLivenessBreadcrumbs } from "../components/OnboardingBreadcrumbs";
import useNavigation from "../hooks/useNavigation";

export default observer(function Liveness() {
  const { metaTreezor } = useAppState();
  const { navigateToTop } = useNavigation();
  const { toastError } = useToaster();

  let errorMessage = useRef("");
  const onboardingRequest = useOnboarding();
  const legalRepId = onboardingRequest.legalRepresentative?.id;
  let meta: MetaEntryType | undefined = undefined;
  if (legalRepId) {
    meta = metaTreezor.metaEntries.get(legalRepId);
  } else {
    errorMessage.current =
      "Aucun représentant légal trouvé. Merci d'ajouter un représentant légal, puis de relancer la vérification.";
  }

  const handleNewLivenessUrlCreation = useCallback(
    async (onboardingRequestId: string) => {
      const response = await generateNewLivenessUrl(onboardingRequestId);
      if (!response.success) {
        errorMessage.current = `Erreur lors du chargement: ${response.error_type}`;
      }
    },
    [],
  );

  useEffect(() => {
    if (legalRepId && meta && !meta.hasValidLivenessUrl)
      handleNewLivenessUrlCreation(onboardingRequest.id);
  }, [legalRepId, onboardingRequest.id, meta, handleNewLivenessUrlCreation]);

  useEffect(() => {
    if (errorMessage.current !== "") {
      toastError(errorMessage.current, true);
    }
  }, [errorMessage, toastError]);

  return (
    <>
      {meta && meta.livenessUrl && meta.hasValidLivenessUrl ? (
        <MainSection centerContent>
          <OnboardingLivenessBreadcrumbs />

          <LivenessFrame livenessUrl={meta.livenessUrl} />
        </MainSection>
      ) : (
        <>
          <MainSection centerContent>
            <OnboardingLivenessBreadcrumbs />

            <h2>Chargement de la vérification, merci de patienter...</h2>
            <Spinner size={50} />
          </MainSection>

          <MainSection>
            <ButtonBar>
              <ButtonItem
                text="Retour au résumé"
                onClick={() => navigateToTop("/dashboard")}
              ></ButtonItem>
            </ButtonBar>
          </MainSection>
        </>
      )}
    </>
  );
});
