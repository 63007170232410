import { useState } from "react";
import { observer } from "mobx-react";
import styled from "styled-components/macro";
import axios from "axios";
import { getSnapshot, SnapshotOut } from "mobx-state-tree";
import { useHistory } from "react-router-dom";
import * as Sentry from "@sentry/react";

import { InputText, InputPhone } from "../components/form/FormItems";
import { ButtonBar, ButtonItem } from "../components/form/FormButtons";
import { RowTwoColumns } from "../components/form/FormRow";
import useAppState from "../state/useAppState";
import { requestBusinessRegistration } from "../backend";

import useToaster from "../hooks/useToaster";
import BusinessRegistrationCreation from "../state/businessCreationModel";
import NoticeArea from "../components/NoticeArea";
import { BusinessOwnerRegistrationBreadcrumbs } from "../components/CreationBreadcrumbs";
import { MainSection } from "../components/Sections";
import { RedText } from "../theme";

const GeneralNotice = styled(NoticeArea)`
  margin-bottom: 3rem;
`;

const getCaptchaToken = async (action: string): Promise<string> => {
  const captchaAPI: any = (window as any).grecaptcha;

  await new Promise<void>((resolve) => {
    captchaAPI.ready(() => resolve());
  });

  const token = await captchaAPI.execute(
    process.env.REACT_APP_RECAPTCHA_SITEKEY,
    {
      action,
    },
  );
  return token;
};

export default observer(function FormBusinessInfo() {
  const { toastError } = useToaster();
  const appState = useAppState();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);

  const owner = appState?.businessCreation?.owner;
  if (!owner) return null;

  const request = async () => {
    if (!appState || !appState.businessCreation) return;

    setIsLoading(true);

    try {
      const token = await getCaptchaToken("submit");
      const creation = getSnapshot(appState.businessCreation);

      const response = await requestBusinessRegistration(
        token,
        creation as SnapshotOut<typeof BusinessRegistrationCreation>,
      );
      const { uploadURL } = response;

      const image = appState.businessCreation.business.image;

      if (image) {
        await axios.put(uploadURL, image, {
          headers: {
            "content-type": "application/octet-stream",
          },
        });
      }
    } catch (e) {
      console.error(e);
      Sentry.captureException(e);
      toastError(
        "Erreur lors de l'envoi des données. Merci de réessayer ou de contacter notre équipe.",
      );
      setIsLoading(false);
      return;
    }

    setIsLoading(false);
    history.push("/register/validated");
  };

  return (
    <MainSection>
      <BusinessOwnerRegistrationBreadcrumbs />
      <GeneralNotice>
        Ces informations vont servir à la création de votre compte personnel.
        Vous pourrez vous connecter avec votre numéro de portable sur
        l’application LocallyPay pour accepter les encaissements instantanément.
      </GeneralNotice>

      <form>
        <RowTwoColumns>
          <InputText
            id="firstname"
            label="Prénom"
            placeholder="Alexandre"
            value={owner.firstname}
            onChange={(firstname) => owner.update({ firstname })}
          />

          <InputText
            id="lastname"
            label="Nom"
            placeholder="Tilleman"
            value={owner.lastname}
            onChange={(lastname) => owner.update({ lastname })}
          />
        </RowTwoColumns>
        <RowTwoColumns>
          <InputText
            id="email"
            label="Email personel"
            placeholder="alexandre@locallypay.com"
            value={owner.email}
            errorMessages={owner.emailErrors}
            onChange={(email) => owner.update({ email })}
          />

          <InputPhone
            id="phoneNumber"
            label="Numéro de téléphone portable"
            placeholder="06 00 00 00 00"
            value={owner.phoneNumber}
            errorMessages={owner.phoneErrors}
            onChange={(phoneNumber) => owner.update({ phoneNumber })}
          />
        </RowTwoColumns>

        {!owner.isValid && (
          <NoticeArea>
            {(owner.phoneErrors.length > 0 || owner.emailErrors.length > 0) && (
              <p>
                <RedText>
                  Merci de corriger les erreurs indiquées ci-dessus.
                </RedText>
              </p>
            )}

            {owner.missingFields.length > 0 && (
              <p>
                Merci de compléter les champs suivants:
                <br />
                <RedText>{owner.missingFields.join(", ")}</RedText>
              </p>
            )}
          </NoticeArea>
        )}

        <ButtonBar>
          <ButtonItem
            disabled={isLoading || !owner.isValid}
            text={!isLoading ? "Valider" : "Chargement..."}
            onClick={() => request()}
          ></ButtonItem>
        </ButtonBar>
      </form>
    </MainSection>
  );
});
