import { initializeApp } from "firebase/app";
import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
} from "firebase/storage";

import { SnapshotOut } from "mobx-state-tree";

import { getFunctions, httpsCallable } from "firebase/functions";
import { v4 as uuidv4 } from "uuid";
import * as Sentry from "@sentry/react";
import { getAuth, onAuthStateChanged, User } from "firebase/auth";

import { FunctionResponse, FUNCTIONS_REGION } from "./state/constants";
import { AppStateInterface } from "./state/appState";

import BusinessRegistrationCreation from "./state/businessCreationModel";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_APIKEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTHDOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECTID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGEBUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGINGSENDERID,
  appId: process.env.REACT_APP_FIREBASE_APPID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENTID,
};

export function init() {
  initializeApp(firebaseConfig);
}

export function uploadFile(file: File | Blob, id: string, filename?: string) {
  const storage = getStorage();
  const path = `onboardingRequests/${id}/${filename || uuidv4()}`;
  const storageRef = ref(storage, path);
  const task = uploadBytesResumable(storageRef, file);
  return {
    task,
    path,
  };
}

export const initAuth = (appState: AppStateInterface) => {
  const checkLoggedInUser = async (user: User | null) => {
    if (user) {
      const { claims } = await user.getIdTokenResult();
      if (claims.role === "admin") {
        appState.setAdminLoggedIn(true);
        return;
      }
    }
    appState.setAdminLoggedIn(false);
  };

  onAuthStateChanged(getAuth(), checkLoggedInUser);
};

export function downloadRef(reference: string) {
  return getDownloadURL(ref(getStorage(), reference));
}

export async function sendOnboardingToTreezor(onboardingRequestId: string) {
  const updateOnboardingRequest = httpsCallable<
    { onboardingRequestId: string },
    FunctionResponse
  >(getFunctions(undefined, FUNCTIONS_REGION), "updateOnboardingRequest");

  const response = await updateOnboardingRequest({
    onboardingRequestId,
  });

  if (!response.data.success) {
    console.error(response);
    Sentry.captureException(response);
  }

  return response.data;
}

export async function generateNewLivenessUrl(onboardingRequestId: string) {
  const postLivenessRequest = httpsCallable<
    { onboardingRequestId: string },
    FunctionResponse
  >(getFunctions(undefined, FUNCTIONS_REGION), "postLivenessRequest");

  const response = await postLivenessRequest({
    onboardingRequestId,
  });

  if (!response.data.success) {
    console.error(response);
    Sentry.captureException(response);
  }

  return response.data;
}

export async function requestBusinessRegistration(
  token: string,
  creation: SnapshotOut<typeof BusinessRegistrationCreation>,
) {
  const askForBusinessRegistration = httpsCallable<
    {
      token: string;
      creation: SnapshotOut<typeof BusinessRegistrationCreation>;
    },
    FunctionResponse & { uploadURL: string; id: string }
  >(getFunctions(undefined, FUNCTIONS_REGION), "askForBusinessRegistration");

  const response = await askForBusinessRegistration({
    token,
    creation,
  });

  if (!response.data.success) {
    console.error(response);
    Sentry.captureException(response);
  }

  return response.data;
}
