import styled from "styled-components/macro";

const InnerSection = styled.div<{ centerContent: boolean }>`
  margin: auto;
  padding: 1rem 2rem;
  width: 100%;
  width: min(100%, 940px);
  ${({ centerContent }) => centerContent && "text-align: center;"}
`;

export const MainSection = ({
  children,
  className,
  centerContent = false,
}: {
  children: React.ReactNode;
  className?: string;
  centerContent?: boolean;
}) => {
  return (
    <section className={className}>
      <InnerSection centerContent={centerContent}>{children}</InnerSection>
    </section>
  );
};

export const MainSectionBlue = styled(MainSection)`
  padding: 1rem 0;
  line-height: 1em;
  background: var(--color-blue);
  color: white;
  font-size: var(--font-size-big);
  font-weight: bold;
  text-transform: uppercase;
`;
