import { observer } from "mobx-react";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components/macro";

import {
  InputText,
  InputPhone,
  InputAddress,
  Select,
  Checkbox,
} from "../components/form/FormItems";
import { ImageDropzone } from "../components/form/DropzoneItem";
import { ButtonBar, ButtonItem } from "../components/form/FormButtons";
import {
  RowTwoColumns,
  RowOneThirdTwoThirdColumn,
  RowThreeColumns,
} from "../components/form/FormRow";
import useAppState from "../state/useAppState";
import { CATEGORIES, initCategories } from "../state/businessCreationModel";
import NoticeArea from "../components/NoticeArea";
import { BusinessRegistrationBreadcrumbs } from "../components/CreationBreadcrumbs";
import { MainSection } from "../components/Sections";
import { RedText } from "../theme";

const GeneralNotice = styled(NoticeArea)`
  margin-bottom: 3rem;
`;

export default observer(function FormBusinessInfo() {
  const appState = useAppState();
  const history = useHistory();
  useEffect(() => {
    initCategories();
  }, []);

  const business = appState?.businessCreation?.business;
  if (!business) return null;

  return (
    <MainSection>
      <BusinessRegistrationBreadcrumbs />
      <GeneralNotice>
        <p>
          Ces informations seront visibles sur votre fiche publique pour
          permettre aux utilisateurs de découvrir votre point de vente et vos
          produits.
        </p>
        <p>
          Décrivez au mieux ce qui vous différencie et donnez envie qu’on vienne
          vous découvrir ;-)
        </p>
      </GeneralNotice>

      <form>
        <RowOneThirdTwoThirdColumn>
          <InputText
            id="name"
            label="Nom de votre commerce"
            placeholder="LocallyPay"
            value={business.name}
            onChange={(name) => business.update({ name })}
          />

          <InputAddress
            id="address"
            label="Adresse du commerce"
            placeholder="28 Rue René Barthélémy, Antony, France"
            value={business.address}
            onChange={business.address.updateDetails}
            errorMessages={business.address.errors}
          />
        </RowOneThirdTwoThirdColumn>

        <RowOneThirdTwoThirdColumn>
          <Select
            id="category"
            label="Catégorie"
            value={business.category}
            onChange={(category) => business.update({ category })}
            options={Object.keys(CATEGORIES.get())}
            optionLabels={Object.values(CATEGORIES.get()).map(
              (o) => o.lname.fr || o.name,
            )}
          />
          <InputText
            id="catchPhrase"
            label="Décrivez votre commerce simplement en une phrase"
            placeholder="Boostez la visibilité locale de votre commerce"
            value={business.catchPhrase}
            onChange={(catchPhrase) => business.update({ catchPhrase })}
          />
        </RowOneThirdTwoThirdColumn>

        <InputText
          textarea
          required={false}
          id="description"
          label="Description de votre activité"
          placeholder="LocallyPay propose un système de chèques cadeaux favorisant le commerce local."
          value={business.description}
          onChange={(description) => business.update({ description })}
        />

        <InputText
          textarea
          required={false}
          id="scheduleText"
          label="Horaires d'ouvertue"
          placeholder="Du lundi au samedi, de 8h à 18h"
          value={business.scheduleText}
          onChange={(scheduleText) => business.update({ scheduleText })}
        />

        <RowTwoColumns>
          <InputText
            id="email"
            label="Email de contact public"
            placeholder="alexandre@locallypay.com"
            value={business.email}
            errorMessages={business.emailErrors}
            onChange={(email) => business.update({ email })}
          />

          <InputPhone
            id="phoneNumber"
            label="Téléphone principal"
            placeholder="06 00 00 00 00"
            value={business.phoneNumber}
            errorMessages={business.phoneErrors}
            onChange={(phoneNumber) => business.update({ phoneNumber })}
          />
        </RowTwoColumns>
        <RowThreeColumns>
          <InputText
            id="websiteUrl"
            label="Site web"
            placeholder="www.locallypay.com"
            required={false}
            value={business.websiteUrl}
            onChange={(websiteUrl) => business.update({ websiteUrl })}
          />

          <InputText
            id="facebookUrl"
            label="Adresse Facebook"
            placeholder="www.facebook.com/locallypay"
            required={false}
            value={business.facebookUrl}
            onChange={(facebookUrl) => business.update({ facebookUrl })}
          />

          <InputText
            id="instagramUrl"
            label="Adresse Instagram"
            placeholder="www.instagram.com/locallypay"
            required={false}
            value={business.instagramUrl}
            onChange={(instagramUrl) => business.update({ instagramUrl })}
          />
        </RowThreeColumns>

        <ImageDropzone
          id="coverUrl"
          label="Photo de votre commerce"
          required={false}
          image={business.imageSrc}
          updateImage={business.updateImage}
        />

        <Checkbox
          id="acceptCGV"
          label="Conditions générales"
          checkboxText={
            <>
              J'accepte les&nbsp;
              <a
                href="https://www.locallypay.com/pages/cgv"
                rel="noopener noreferrer"
                target="_blank"
              >
                conditions générales de vente (CGV)
              </a>
              &nbsp;de LocallyPay
            </>
          }
          value={business.acceptCGV}
          onChange={(acceptCGV) => business.update({ acceptCGV })}
        />

        {!business.isValid && (
          <NoticeArea>
            {(business.phoneErrors.length > 0 ||
              business.emailErrors.length > 0) && (
              <p>
                <RedText>
                  Merci de corriger les erreurs indiquées ci-dessus.
                </RedText>
              </p>
            )}

            {business.missingFields.length > 0 && (
              <p>
                Merci de compléter les champs suivants:
                <br />
                <RedText>{business.missingFields.join(", ")}</RedText>
              </p>
            )}
          </NoticeArea>
        )}

        <ButtonBar>
          <ButtonItem
            disabled={!business.isValid}
            text="Suivant"
            onClick={() => history.push("/register/owner")}
          ></ButtonItem>
        </ButtonBar>
      </form>
    </MainSection>
  );
});
