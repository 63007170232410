import React from "react";
import ReactDOM from "react-dom";
import "@fontsource/poppins";
import { Router } from "react-router-dom";
import { createBrowserHistory } from "history";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { Crash } from "./pages/Error";
import { AppToaster } from "./components/Toast";

import GlobalStyle from "./theme";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { AppStateContextProvider } from "./state/useAppState";
import AppState from "./state/appState";
import { init as initBackend, initAuth } from "./backend";

initBackend();
const appState = AppState.create({});
const history = createBrowserHistory();
initAuth(appState);

Sentry.init({
  dsn:
    process.env.REACT_APP_SENTRY_DSN ||
    "https://examplePublicKey@o0.ingest.sentry.io/0",
  integrations: [
    new Integrations.BrowserTracing({
      routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
    }),
  ],
  environment: process.env.REACT_APP_SENTRY_ENV || "local-dev",

  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0,
});

ReactDOM.render(
  <React.StrictMode>
    <Sentry.ErrorBoundary showDialog fallback={<Crash />}>
      <Router history={history}>
        <AppStateContextProvider value={appState}>
          <GlobalStyle />
          <App />
          <AppToaster />
        </AppStateContextProvider>
      </Router>
    </Sentry.ErrorBoundary>
  </React.StrictMode>,
  document.getElementById("root"),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
