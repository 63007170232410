import styled from "styled-components/macro";
import { ValidatedRegistrationBreadcrumbs } from "../components/CreationBreadcrumbs";
import { MainSection } from "../components/Sections";
import Validated from "../icons/Validated";

const ValidationBody = styled.div`
  display: flex;
  flex-direction: column;

  & > svg {
    margin: auto;
    width: 100%;
    max-width: 400px;
  }

  color: var(--color-blue);
  & > h1 {
    font-weight: bold;
    font-size: var(--font-size-biggest);
    text-transform: uppercase;
    margin: 10px 0;
  }

  & > p {
    margin: 0;
    margin-bottom: 2rem;
  }
`;

export default function CreationValidated() {
  return (
    <MainSection>
      <ValidatedRegistrationBreadcrumbs />
      <ValidationBody>
        <Validated />
        <h1>Félicitations!</h1>
        <p>
          Nous avons bien reçu vos informations, notre équipe va les valider au
          plus vite et vous recevrez un email pour accéder à votre compte.
        </p>
      </ValidationBody>
    </MainSection>
  );
}
