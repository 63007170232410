import { useHistory, useRouteMatch } from "react-router-dom";

const useNavigation = () => {
  const history = useHistory();
  const match = useRouteMatch("/onboarding/:onboardingId");

  const navigateToTop = (path: string) => {
    history.push(`${match?.url}${path}`);
    window.scrollTo(0, 0);
  };

  return { navigateToTop };
};

export default useNavigation;
