import { useHistory } from "react-router-dom";
import styled from "styled-components/macro";
import { Stepper, StepItemBox } from "../components/Stepper";
import { BREAKPOINT_MOBILE } from "../theme";

const BreadcrumbsWrapper = styled.div`
  display: flex;
  margin: 2rem 0;

  @media screen and ${BREAKPOINT_MOBILE} {
    flex-direction: column;
  }
`;

export const BusinessRegistrationBreadcrumbs = () => {
  return (
    <BreadcrumbsWrapper>
      <Stepper>
        <StepItemBox mode="active">01. Votre commerce</StepItemBox>
      </Stepper>
      <Stepper>
        <StepItemBox>02. Vous</StepItemBox>
      </Stepper>
      <Stepper>
        <StepItemBox>03. Validation</StepItemBox>
      </Stepper>
    </BreadcrumbsWrapper>
  );
};

const ClickableSetItemBox = styled(StepItemBox)`
  cursor: pointer;
`;

export const BusinessOwnerRegistrationBreadcrumbs = () => {
  const history = useHistory();
  return (
    <BreadcrumbsWrapper>
      <Stepper>
        <ClickableSetItemBox
          mode="done"
          onClick={() => history.push("/register")}
        >
          01. Votre commerce
        </ClickableSetItemBox>
      </Stepper>
      <Stepper>
        <StepItemBox mode="active">02. Vous</StepItemBox>
      </Stepper>
      <Stepper>
        <StepItemBox>03. Validation</StepItemBox>
      </Stepper>
    </BreadcrumbsWrapper>
  );
};

export const ValidatedRegistrationBreadcrumbs = () => {
  return (
    <BreadcrumbsWrapper>
      <Stepper>
        <StepItemBox mode="done">01. Votre commerce</StepItemBox>
      </Stepper>
      <Stepper>
        <StepItemBox mode="done">02. Vous</StepItemBox>
      </Stepper>
      <Stepper>
        <StepItemBox mode="active">03. Validation</StepItemBox>
      </Stepper>
    </BreadcrumbsWrapper>
  );
};
