import styled from "styled-components/macro";
import { Stepper, StepItemBox } from "../components/Stepper";
import useNavigation from "../hooks/useNavigation";

const BreadcrumbsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 2rem 0;
`;

const ClickableSetItemBox = styled(StepItemBox)`
  cursor: pointer;
`;

export const OnboardingBusinessBreadcrumbs = () => {
  return (
    <BreadcrumbsWrapper>
      <Stepper>
        <StepItemBox mode="active">01. Votre commerce</StepItemBox>
      </Stepper>
      <Stepper>
        <StepItemBox>02. Le représentant légal</StepItemBox>
      </Stepper>
      <Stepper>
        <StepItemBox>03. Les bénéficiaires</StepItemBox>
      </Stepper>
      <Stepper>
        <StepItemBox>04. Validation de votre identité</StepItemBox>
      </Stepper>
    </BreadcrumbsWrapper>
  );
};

export const OnboardingLegalRepBreadcrumbs = () => {
  const { navigateToTop } = useNavigation();

  return (
    <BreadcrumbsWrapper>
      <Stepper>
        <ClickableSetItemBox
          mode="done"
          onClick={() => navigateToTop("/commerce")}
        >
          01. Votre commerce
        </ClickableSetItemBox>
      </Stepper>
      <Stepper>
        <StepItemBox mode="active">02. Le représentant légal</StepItemBox>
      </Stepper>
      <Stepper>
        <StepItemBox>03. Les bénéficiaires</StepItemBox>
      </Stepper>
      <Stepper>
        <StepItemBox>04. Validation de votre identité</StepItemBox>
      </Stepper>
    </BreadcrumbsWrapper>
  );
};

export const OnboardingBenefBreadcrumbs = () => {
  const { navigateToTop } = useNavigation();

  return (
    <BreadcrumbsWrapper>
      <Stepper>
        <ClickableSetItemBox
          mode="done"
          onClick={() => navigateToTop("/commerce")}
        >
          01. Votre commerce
        </ClickableSetItemBox>
      </Stepper>
      <Stepper>
        <ClickableSetItemBox
          mode="done"
          onClick={() => navigateToTop("/representant")}
        >
          02. Le représentant légal
        </ClickableSetItemBox>
      </Stepper>
      <Stepper>
        <StepItemBox mode="active">03. Les bénéficiaires</StepItemBox>
      </Stepper>
      <Stepper>
        <StepItemBox>04. Validation de votre identité</StepItemBox>
      </Stepper>
    </BreadcrumbsWrapper>
  );
};

export const OnboardingLivenessBreadcrumbs = () => {
  return (
    <BreadcrumbsWrapper>
      <Stepper>
        <StepItemBox mode="active">
          04. Validation de votre identité
        </StepItemBox>
      </Stepper>
    </BreadcrumbsWrapper>
  );
};
