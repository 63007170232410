import { useState, useEffect } from "react";
import styled from "styled-components/macro";
import Dropzone from "react-dropzone";
import * as Sentry from "@sentry/react";
import { v4 as uuidv4 } from "uuid";
import { observer } from "mobx-react";

import IconDownload from "../../icons/IconDownload";
import IconBin from "../../icons/Bin";
import Download from "../../icons/Download";
import { ErrorMessage } from "./FormItem";
import { uploadFile, downloadRef } from "../../backend";
import useOnboarding from "../../state/useOnboarding";
import FormItem from "./FormItem";
import Spinner from "../Spinner";
import useToaster from "../../hooks/useToaster";
import { ButtonItem } from "./FormButtons";

const BaseContainer = styled.div<{ hasError?: boolean; disabled?: boolean }>`
  width: 100%;
  padding: 0.5rem;
  border-width: 2px;
  border-radius: 2px;
  border: 1px solid;
  box-shadow: 1px 1px 2px var(--color-gray-light);
  border-radius: 5px;
  border-color: ${(props) =>
    props.hasError ? "var(--color-red)" : "var(--color-gray-light)"};
  background: ${(props) =>
    props.disabled ? "var(--color-blue-vlight)" : "white"};
`;

const IconButton = styled.button`
  display: inline-block;
  border: none;
  box-sizing: border-box;

  height: 2em;
  line-height: 1em;
  padding: 0.5em;

  border-radius: 1em;
  margin: 0;
  margin-left: 0.5em;
  text-decoration: none;

  cursor: pointer;
  text-align: center;
  transition: background 250ms ease-in-out, transform 150ms ease;
  -webkit-appearance: none;
  -moz-appearance: none;

  color: white;
  font-size: var(--font-size-small);

  background: var(--color-blue-vlight);
  :hover {
    background: var(--color-gray-light);
  }
`;
const IconAButton = IconButton.withComponent("a");

const IconList = styled.ul`
  font-size: var(--font-size-small);
  & > :not(:last-child) {
    margin-right: 0.5em;
  }
`;

const FileZoneContainer = styled.div`
  flex: 1;
`;

const DropzoneContainer = styled(BaseContainer)`
  font-size: var(--font-size-small);
  text-align: center;

  & img {
    padding: 0.5em;
    width: 100%;
    max-height: 250px;
    object-fit: contain;
    box-sizing: border-box;
  }
`;

const DlReference = styled(BaseContainer)`
  margin-bottom: 1rem;
  padding: 1rem;
  font-size: var(--font-size-small);
`;

const DLReferenceTitle = styled.h4`
  margin: 0;
`;

const FilesList = ({
  name,
  references,
  onClear,
  disabled = false,
}: {
  name: string;
  references: Map<string, string>;
  onClear: (key: string) => void;
  disabled?: boolean;
}) => {
  const [url, setUrl] = useState<Map<string, string>>(new Map());

  useEffect(() => {
    Array.from(references.entries()).forEach(([key, value]) => {
      downloadRef(value)
        .then((r) =>
          setUrl((prev) => new Map([...Array.from(prev.entries()), [key, r]])),
        )
        .catch((e) => console.log(e));
    });
  }, [references]);

  return (
    <DlReference>
      <DLReferenceTitle>✅&nbsp;&nbsp;Fichiers fournis:</DLReferenceTitle>

      <IconList>
        {Array.from(references.entries()).map(([key, value]) => (
          <li key={key}>
            {key}
            {!disabled && (
              <IconButton
                onClick={(e) => {
                  e.preventDefault();
                  onClear(key);
                }}
              >
                <IconBin />
              </IconButton>
            )}
            {url && (
              <IconAButton href={url.get(key)} download={value} target="_blank">
                <Download />
              </IconAButton>
            )}
          </li>
        ))}
      </IconList>
    </DlReference>
  );
};

export default observer(function DropzoneItem({
  id,
  label,
  subLabel,
  linkText,
  linkUrl,
  tooltipText,
  required = true,
  references,
  updateReference,
  deleteReference,
  disabled = false,
}: {
  id: string;
  label?: string;
  subLabel?: string;
  linkText?: string;
  linkUrl?: string;
  tooltipText?: string;
  required?: boolean;
  references?: Map<string, string | null>;
  updateReference: Function;
  deleteReference: Function;
  disabled?: boolean;
}) {
  const { toastError } = useToaster();
  const onboarding = useOnboarding();
  const [hasError, setError] = useState(false);
  const [isUploading, setIsUploading] = useState(false);

  let filteredReferences = new Map();
  if (references) {
    filteredReferences = new Map(
      Array.from(references.entries()).filter(([key, value]) => value !== null),
    ) as Map<string, string>;
  }

  const [hideDropzone, setHideDropzone] = useState(filteredReferences.size > 0);

  // Handler
  const handleFileUpload = async (files: Array<File>) => {
    if (!files.length) {
      setError(true);
      return;
    }
    setError(false);
    try {
      setIsUploading(true);

      await Promise.all(
        files.map((file) => {
          const fileName = `${id}-${uuidv4()}`;
          const { task, path } = uploadFile(file, onboarding.id, fileName);

          return new Promise((resolve, reject) => {
            task.on("state_changed", null, reject, () => {
              updateReference(fileName, path);
              resolve(fileName);
            });
          });
        }),
      );

      setHideDropzone(true);
      setIsUploading(false);
    } catch (e) {
      setIsUploading(false);
      console.log(e);
      Sentry.captureException(e);
      toastError(
        "Erreur lors de l'envoi de votre fichier. Merci de recommencer",
      );
    }
  };

  const dropzone = (
    <Dropzone
      onDrop={handleFileUpload}
      maxSize={95000000}
      multiple={true}
      accept={[
        "application/pdf",
        "image/jpg",
        "image/jpeg",
        "image/png",
        "image/tiff",
        "image/svg+xml",
      ]}
      disabled={disabled}
    >
      {({ getRootProps, getInputProps }) => (
        <DropzoneContainer hasError={hasError} {...getRootProps()}>
          {isUploading ? (
            <>
              <p>Fichiers en cours d'envoi...</p>
              <Spinner />
            </>
          ) : (
            <>
              <input id={id} {...getInputProps()} />
              <p>Glisser les documents ici (pdf, jpeg ou png)</p>
              <IconDownload width={30} />
              <p>ou cliquer ici pour parcourir (taille maximum: 10Mo)</p>
            </>
          )}
        </DropzoneContainer>
      )}
    </Dropzone>
  );

  const dropzoneButton = (
    <ButtonItem
      text="Ajouter un autre fichier"
      onClick={() => {
        setHideDropzone(false);
      }}
      disabled={disabled}
    />
  );

  return (
    <FormItem
      id={id}
      label={label}
      subLabel={subLabel}
      linkText={linkText}
      linkUrl={linkUrl}
      tooltipText={tooltipText}
      required={required}
    >
      <FileZoneContainer>
        {filteredReferences.size > 0 && (
          <FilesList
            name={id}
            references={filteredReferences}
            onClear={(key) => {
              if (
                window.confirm(
                  `Êtes-vous sûr de vouloir supprimer ce fichier: ${key} ?`,
                )
              ) {
                deleteReference(key);
              }
            }}
            disabled={disabled}
          />
        )}

        {hideDropzone ? dropzoneButton : dropzone}
      </FileZoneContainer>

      {hasError && (
        <ErrorMessage>
          Vous devez choisir un fichier pdf, jpeg ou png de taille de moins de
          10Mo.
        </ErrorMessage>
      )}
    </FormItem>
  );
});

export const ImageDropzone = function ImageDropzone({
  id,
  label,
  tooltipText,
  image,
  updateImage,
  required = true,
  disabled = false,
}: {
  id: string;
  label?: string;
  tooltipText?: string;
  required?: boolean;
  disabled?: boolean;
  image?: string | null;
  updateImage: (f: File) => void;
}) {
  const [hasError, setError] = useState(false);

  // Handler
  const handleFileUpload = async (files: Array<File>) => {
    if (!files.length) {
      setError(true);
      return;
    }
    updateImage(files[0]);
    setError(false);
  };

  return (
    <FormItem
      id={id}
      label={label}
      tooltipText={tooltipText}
      required={required}
    >
      <Dropzone
        onDrop={handleFileUpload}
        maxSize={95000000}
        multiple={false}
        accept={["image/jpg", "image/jpeg", "image/png", "image/tiff"]}
        disabled={disabled}
      >
        {({ getRootProps, getInputProps }) => (
          <DropzoneContainer hasError={hasError} {...getRootProps()}>
            <input id={id} {...getInputProps()} />
            {image ? (
              <img src={image} alt="Upload preview" />
            ) : (
              <>
                <p>Image (jpeg ou png)</p>
                <IconDownload width={30} />
                <p>ou cliquer ici pour parcourir (taille maximum: 10Mo)</p>
              </>
            )}
          </DropzoneContainer>
        )}
      </Dropzone>

      {hasError && (
        <ErrorMessage>
          Vous devez choisir un fichier jpeg ou png de taille de moins de 10Mo.
        </ErrorMessage>
      )}
    </FormItem>
  );
};
