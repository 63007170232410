import styled from "styled-components/macro";
import { useRouteMatch } from "react-router-dom";
import { BREAKPOINT_MOBILE } from "../theme";

export const Stepper = styled.nav`
  display: flex;
  align-items: flex-start;
  gap: 1rem;
  margin: 0 0 1rem 1rem;

  @media screen and ${BREAKPOINT_MOBILE} {
    flex-flow: column wrap;
  }
`;

const stepItemColor = ({ mode = "default" }) => {
  if (mode === "active") return "white";
  return "var(--color-blue)";
};

const stepItemBg = ({ mode = "default" }) => {
  if (mode === "default") return "var(--color-blue-vlight)";
  if (mode === "done") return "var(--color-green)";
  if (mode === "active") return "var(--color-blue)";
};

const stepItemBorder = ({ mode = "default" }) => {
  if (mode === "done") return "var(--color-green)";
  return "var(--color-blue)";
};

export const StepItemBox = styled.span<{
  mode?: "active" | "done" | "default";
}>`
  padding: 0.2rem 0.8rem;
  border-radius: 4px;
  font-size: var(--font-size-big);
  font-weight: bold;
  border: 1px solid ${stepItemBorder};
  color: ${stepItemColor};
  background: ${stepItemBg};
  text-transform: uppercase;
`;

export const StepItem = ({
  path,
  children,
}: {
  path: string;
  children: React.ReactNode;
}) => {
  const match = useRouteMatch(path);
  return (
    <StepItemBox mode={match ? "active" : "default"}>{children}</StepItemBox>
  );
};
