import countries from "i18n-iso-countries";
import countriesLocale from "i18n-iso-countries/langs/fr.json";
import { LEGAL_FORMS, LEGAL_FORMS_LABELS } from "./legal-forms-constants";
export { LEGAL_FORMS_LABELS, LEGAL_FORMS };
export type LegalFormType = typeof LEGAL_FORMS[number];

countries.registerLocale(countriesLocale);

export const COMPANY_SIZES = ["0", "1-9", "10-99", "100-249", "250-*"] as const;
export const COMPANY_SIZES_LABELS = [
  "0",
  "1 à 9",
  "10 à 99",
  "100 à 249",
  "plus de 250",
];
export type CompanySizesType = typeof COMPANY_SIZES[number];

export const TURNOVER = [
  "0-39",
  "40-99",
  "100-249",
  "250-999",
  "1000-2999",
  "3000-9999",
  "10000-99999",
  "100000-*",
];
export const TURNOVER_LABELS = [
  "0 - 39 000€",
  "40 000€ - 99 000€",
  "100 000€ - 249 000€",
  "250 000€ - 999 000€",
  "1 000 000€ - 2 999 000€",
  "3 000 000€ - 9 999 000€",
  "10 000 000€ - 99 999 000€",
  "Plus de 100 000 000€",
];
export type TurnoverType = typeof TURNOVER[number];

export const BENEFIT = ["0-4", "5-9", "10-49", "50-149", "150-499", "500-*"];
export const BENEFIT_LABELS = [
  "0 - 4 000€",
  "5 000€ - 9 000€",
  "10 000€ - 49 000€",
  "50 000€ - 149 000€",
  "150 000€ - 499 000€",
  "Plus de 500 000€",
];
export type BenefitType = typeof BENEFIT[number];

const countriesNames = countries.getNames("fr", { select: "official" });
export const COUNTRY = Object.keys(countriesNames);
export const COUNTRY_LABELS = Object.values(countriesNames);
export type CountryType = typeof COUNTRY[number];

export const INCOME_RANGE = [
  "0-18",
  "19-23",
  "24-27",
  "28-35",
  "36-56",
  "57-*",
];
export const INCOME_RANGE_LABELS = [
  "0 - 18 000€",
  "19 000€ - 23 000€",
  "24 000€ - 27 000€",
  "28 000€ - 35 000€",
  "36 000€ - 56 000€",
  "Plus de 57 000€",
];
export type IncomeRangeType = typeof INCOME_RANGE[number];

export const PERSONAL_ASSETS = [
  "0-2",
  "3-22",
  "23-128",
  "129-319",
  "320-464",
  "465-",
];
export const PERSONAL_ASSETS_LABELS = [
  "0 - 2 000€",
  "3 000€ - 22 000€",
  "23 000€ - 128 000€",
  "129 000€ - 319 000€",
  "320 000€ - 464 000€",
  "Plus de 465 000€",
];
export type PersonalAssetsType = typeof PERSONAL_ASSETS[number];

export const TITLES = ["M", "MME", "MLLE"];
export type TitlesType = typeof TITLES[number];

export const FUNCTIONS_REGION = "europe-west1";

export interface FunctionResponse {
  success: boolean;
  error_type?: string;
}
