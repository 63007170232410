export default function Validated() {
  return (
    <svg viewBox="0 0 400 400" fill="none">
      <path
        d="m15.576 203.032 216.117 124.696c7.431 4.199 20.352 3.876 29.075-1.292l130.187-74.947c8.722-4.846 9.368-12.599 1.938-16.798L176.776 109.995c-7.43-4.199-20.352-3.876-29.074 1.293L17.515 186.234c-8.4 5.169-9.369 12.599-1.939 16.798Z"
        fill="#53E8CF"
      />
      <path
        d="M244.292 332.574c-5.169 0-10.015-.97-13.568-3.231L14.607 204.648c-3.877-2.262-5.815-5.492-5.815-9.046 0-4.199 2.907-8.076 8.076-10.983l130.187-74.947c9.369-5.491 22.936-5.814 31.013-1.292l216.117 124.696c3.876 2.261 5.815 5.491 5.815 9.045 0 4.2-2.908 8.076-8.077 10.983l-130.187 75.27c-5.168 2.907-11.306 4.2-17.444 4.2ZM16.545 201.417l216.117 124.696c6.784 3.876 19.06 3.553 27.136-1.293l130.187-74.946c3.877-2.261 6.138-5.169 6.138-7.753 0-2.584-2.261-4.523-4.2-5.492L175.806 111.611c-6.784-3.877-19.059-3.554-27.135 1.292L18.483 187.849c-3.876 2.262-6.138 5.169-6.138 7.753 0 2.908 2.262 4.846 4.2 5.815Z"
        fill="#3F24F5"
      />
      <path
        d="m15.576 194.633 216.117 124.695c7.431 4.2 20.352 3.877 29.075-1.292l130.187-74.946c8.722-4.846 9.368-12.599 1.938-16.799L176.776 101.596c-7.43-4.2-20.352-3.876-29.074 1.292L17.515 177.835c-8.4 5.168-9.369 12.598-1.939 16.798Z"
        fill="#53E8CF"
      />
      <path
        d="M244.292 324.175c-5.169 0-10.015-.969-13.568-3.231L14.607 196.249c-3.877-2.262-5.815-5.492-5.815-8.722 0-4.2 2.907-8.077 8.076-10.984l130.187-74.946c9.369-5.492 22.936-5.815 31.013-1.293L394.185 225c3.876 2.261 5.815 5.492 5.815 9.045 0 4.2-2.908 8.076-8.077 10.984l-130.187 74.946c-5.168 2.907-11.306 4.2-17.444 4.2ZM16.545 193.018l216.117 124.696c6.784 3.876 19.06 3.553 27.136-1.292l130.187-74.947c3.877-2.261 6.138-5.169 6.138-7.753 0-2.584-2.261-4.523-4.2-5.492L175.806 103.212c-6.784-3.877-19.059-3.554-27.135 1.292L18.483 179.45c-3.876 2.262-6.138 5.169-6.138 7.754 0 2.907 2.262 4.845 4.2 5.814Z"
        fill="#3F24F5"
      />
      <path
        d="M15.576 186.234 231.693 310.93c7.431 4.199 20.352 3.876 29.075-1.293l130.187-74.946c8.722-4.846 9.368-12.599 1.938-16.798L176.776 93.197c-7.43-4.2-20.352-3.876-29.074 1.293L17.515 169.435c-8.4 5.169-9.369 12.599-1.939 16.798Z"
        fill="#53E8CF"
      />
      <path
        d="M244.292 315.775c-5.169 0-10.015-.969-13.568-3.23L14.607 187.85c-3.877-2.262-5.815-5.492-5.815-9.046 0-4.199 2.907-8.076 8.076-10.983l129.864-74.947c9.369-5.491 22.936-5.814 31.013-1.292l216.117 124.696c3.876 2.261 5.815 5.491 5.815 9.045 0 4.199-2.908 8.076-8.077 10.983l-129.864 75.27c-5.168 2.907-11.306 4.199-17.444 4.199ZM16.545 184.619l216.117 124.696c6.784 3.876 19.06 3.553 27.136-1.293l130.187-74.946c3.877-2.261 6.138-5.169 6.138-7.753 0-2.585-2.261-4.523-4.2-5.492L175.806 94.813c-6.784-3.877-19.059-3.554-27.135 1.292L18.483 171.05c-3.876 2.262-6.138 5.169-6.138 7.753 0 2.908 2.262 4.846 4.2 5.815Z"
        fill="#3F24F5"
      />
      <path
        d="M245.262 330.959c5.491-.323 10.983-1.615 15.183-4.2l130.187-74.946c6.784-3.877 7.43-11.307 5.815-13.891 1.938-2.907 1.938-5.815 0-8.399 2.584-4.2 1.615-8.722-3.554-11.63l-45.226-26.166H245.262v139.232Z"
        fill="#3F24F5"
      />
      <path
        d="m15.576 177.836 216.117 124.695c7.431 4.2 20.352 3.877 29.075-1.292l129.864-74.947c8.722-4.845 9.368-12.598 1.938-16.798L176.776 84.799c-7.43-4.2-20.352-3.877-29.074 1.292L17.515 161.037c-8.4 5.169-9.369 12.599-1.939 16.799Z"
        fill="#53E8CF"
      />
      <path
        d="M169.992 99.013 39.804 174.282c-7.107 4.2-9.045 9.691-5.491 14.214l176.382 101.759c7.43 3.554 19.706 2.908 27.782-1.615l130.187-75.269c7.43-4.2 9.046-10.661 4.523-14.861L198.097 97.397c-7.43-3.876-19.706-3.23-28.105 1.616Z"
        fill="#fff"
      />
      <path
        d="M244.292 307.377c-5.169 0-10.015-.969-13.568-3.231L14.607 179.451c-3.877-2.261-5.815-5.492-5.815-9.045 0-4.2 2.907-8.076 8.076-10.984l129.864-74.946c9.369-5.492 22.936-5.815 31.013-1.293L393.862 207.88c3.876 2.261 5.815 5.492 5.815 9.045 0 4.2-2.908 8.076-8.077 10.984l-129.864 75.269c-5.168 2.908-11.306 4.2-17.444 4.2ZM16.545 176.22l216.117 124.696c6.784 3.876 19.06 3.553 27.136-1.292l130.187-74.947c3.877-2.261 6.138-5.169 6.138-7.753 0-2.584-2.261-4.523-4.2-5.492L175.806 86.414c-6.784-3.877-19.059-3.554-27.135 1.292L18.483 162.653c-3.876 2.261-6.138 5.168-6.138 7.753 0 2.907 2.262 4.845 4.2 5.814Z"
        fill="#3F24F5"
      />
      <path
        d="m349.134 200.582-131.441 75.991 1.941 3.356 131.44-75.991-1.94-3.356ZM223.335 110.518l-161.084 93.13 1.94 3.356 161.084-93.13-1.94-3.356ZM190.842 109.133l-131.44 75.992 1.94 3.355 131.44-75.991-1.94-3.356Z"
        fill="#3F24F5"
      />
      <path
        d="m247.846 315.13-.324-2.908c4.523-.323 9.046-1.615 12.276-3.876l130.187-74.947c2.262-1.292 4.2-2.907 5.169-4.522l2.584 1.615c-1.292 2.261-3.553 3.877-6.137 5.492l-130.188 75.269c-3.876 1.939-8.399 3.554-13.567 3.877ZM247.846 323.852l-.324-2.908c4.523-.323 9.046-1.615 12.276-3.876l130.187-74.947c2.262-1.292 4.2-2.907 5.169-4.522l2.584 1.615c-1.292 2.261-3.553 3.877-6.137 5.492l-130.188 74.946c-3.876 2.262-8.399 3.554-13.567 4.2ZM157.716 107.735c4.104 0 7.43-2.025 7.43-4.523 0-2.498-3.326-4.522-7.43-4.522-4.103 0-7.43 2.024-7.43 4.522s3.327 4.523 7.43 4.523Z"
        fill="#53E8CF"
      />
      <path
        d="M157.716 109.673c-2.261 0-4.523-.646-6.138-1.615-1.938-1.292-3.23-2.908-3.23-4.846 0-1.938 1.292-3.553 3.23-4.846 3.554-1.938 9.045-1.938 12.599 0 1.938 1.293 3.23 2.908 3.23 4.846 0 1.938-1.292 3.554-3.23 4.846-1.938.969-4.2 1.615-6.461 1.615Zm0-9.045c-1.615 0-3.23.323-4.523.969-.646.323-1.292.969-1.292 1.615 0 .323.646.969 1.292 1.615 2.262 1.292 6.461 1.292 8.723 0 .646-.323 1.292-.969 1.292-1.615 0-.323-.646-.969-1.292-1.615-.97-.323-2.585-.969-4.2-.969Z"
        fill="#3F24F5"
      />
      <path
        d="M130.258 112.904c-2.908 1.615-2.908 4.523 0 6.138 2.907 1.615 7.753 1.615 10.66 0 2.908-1.615 2.908-4.523 0-6.138-2.907-1.615-7.753-1.615-10.66 0Z"
        fill="#53E8CF"
      />
      <path
        d="M135.749 122.272c-2.261 0-4.522-.646-6.138-1.615-1.938-1.292-3.23-2.908-3.23-4.846 0-1.938 1.292-3.553 3.23-4.846 3.554-1.938 9.046-1.938 12.599 0 1.938 1.293 3.231 2.908 3.231 4.846 0 1.938-1.293 3.554-3.231 4.846-1.938.969-4.2 1.615-6.461 1.615Zm0-8.722c-1.615 0-3.23.323-4.522.969-.647.323-1.293.969-1.293 1.615 0 .323.646.969 1.293 1.615 2.261 1.293 6.46 1.293 8.722 0 .646-.323 1.292-.969 1.292-1.615 0-.323-.646-.969-1.292-1.615-.969-.646-2.585-.969-4.2-.969Z"
        fill="#3F24F5"
      />
      <path
        d="M108.29 125.502c-2.907 1.616-2.907 4.523 0 6.138 2.907 1.615 7.753 1.615 10.66 0 2.908-1.615 2.908-4.522 0-6.138-2.907-1.615-7.753-1.615-10.66 0Z"
        fill="#53E8CF"
      />
      <path
        d="M113.459 134.871c-2.261 0-4.523-.646-6.138-1.616-1.938-1.292-3.23-2.907-3.23-4.845 0-1.939 1.292-3.554 3.23-4.846 3.554-1.938 9.046-1.938 12.599 0 1.938 1.292 3.23 2.907 3.23 4.846 0 1.938-1.292 3.553-3.23 4.845-1.938 1.293-4.2 1.616-6.461 1.616Zm0-8.723c-1.615 0-3.23.323-4.523.97-.646.323-1.292.969-1.292 1.615 0 .323.646.969 1.292 1.615 2.262 1.292 6.461 1.292 8.723 0 .646-.323 1.292-.969 1.292-1.615 0-.323-.646-.969-1.292-1.615-.969-.647-2.585-.97-4.2-.97Z"
        fill="#3F24F5"
      />
      <path
        d="M91.493 145.854c4.103 0 7.43-2.024 7.43-4.522s-3.327-4.523-7.43-4.523c-4.104 0-7.43 2.025-7.43 4.523 0 2.498 3.326 4.522 7.43 4.522Z"
        fill="#53E8CF"
      />
      <path
        d="M91.492 147.793c-2.261 0-4.522-.646-6.138-1.616-1.938-1.292-3.23-2.907-3.23-4.845 0-1.939 1.292-3.554 3.23-4.846 3.554-1.938 9.046-1.938 12.6 0 1.938 1.292 3.23 2.907 3.23 4.846 0 1.938-1.293 3.553-3.23 4.845-1.939.97-4.2 1.616-6.462 1.616Zm-4.2-7.753c-.645.323-1.291.969-1.291 1.615 0 .323.646.969 1.292 1.615 2.261 1.292 6.46 1.292 8.722 0 .646-.323 1.292-.969 1.292-1.615 0-.323-.646-.969-1.292-1.615-2.584-1.293-6.46-1.293-8.722 0Z"
        fill="#3F24F5"
      />
      <path
        d="M69.525 158.775c4.103 0 7.43-2.025 7.43-4.522 0-2.498-3.327-4.523-7.43-4.523-4.104 0-7.43 2.025-7.43 4.523 0 2.497 3.326 4.522 7.43 4.522Z"
        fill="#53E8CF"
      />
      <path
        d="M69.525 160.391c-2.262 0-4.523-.646-6.138-1.615-1.939-1.293-3.23-2.908-3.23-4.846 0-1.938 1.291-3.554 3.23-4.846 3.553-1.938 9.045-1.938 12.599 0 1.938 1.292 3.23 2.908 3.23 4.846 0 1.938-1.292 3.553-3.23 4.846-1.939 1.292-4.2 1.615-6.461 1.615Zm0-8.722c-1.616 0-3.23.323-4.523.969-.646.323-1.292.969-1.292 1.615 0 .323.646.969 1.292 1.615 2.261 1.292 6.46 1.292 8.722 0 .646-.323 1.292-.969 1.292-1.615 0-.323-.646-.969-1.292-1.615-.969-.646-2.584-.969-4.2-.969Z"
        fill="#3F24F5"
      />
      <path
        d="M42.066 163.945c-2.907 1.615-2.907 4.522 0 6.138 2.908 1.615 7.753 1.615 10.66 0 2.908-1.616 2.908-4.523 0-6.138-2.907-1.938-7.752-1.938-10.66 0Z"
        fill="#53E8CF"
      />
      <path
        d="M47.558 173.313c-2.262 0-4.523-.646-6.138-1.616-1.938-1.292-3.23-2.907-3.23-4.845 0-1.938 1.292-3.554 3.23-4.846 3.553-1.938 9.045-1.938 12.599 0 1.938 1.292 3.23 2.908 3.23 4.846 0 1.938-1.292 3.553-3.23 4.845-1.939.97-4.2 1.616-6.461 1.616Zm0-9.046c-1.615 0-3.23.323-4.523.97-.646.323-1.292.969-1.292 1.615 0 .323.646.969 1.292 1.615 2.261 1.292 6.461 1.292 8.722 0 .646-.323 1.293-.969 1.293-1.615 0-.323-.647-.969-1.293-1.615-.969-.323-2.584-.97-4.2-.97ZM71.786 157.484l-4.846-.646s1.292-12.275-4.846-20.352c-2.907-3.876-7.43-6.137-13.244-6.784-6.461-.646-12.276 1.293-16.476 6.138-4.522 5.169-6.46 12.922-4.522 20.029l-5.17 1.292c-1.937-9.045 0-18.09 5.816-24.551 5.168-5.815 12.598-8.722 20.675-7.753 7.107.969 12.598 3.876 16.475 8.722 7.753 9.691 6.138 23.259 6.138 23.905Z"
        fill="#3F24F5"
      />
      <path
        d="m93.753 144.885-4.846-.646s1.293-12.276-4.845-20.352c-2.908-3.877-7.43-6.138-13.245-6.784-6.461-.646-12.276 1.292-16.475 6.138-4.523 5.169-6.461 12.922-4.523 20.029l-4.846 1.292c-2.261-8.722 0-18.091 5.815-24.552 5.169-5.814 12.599-8.722 20.675-7.753 7.107.969 12.599 3.877 16.475 8.723 7.43 9.691 6.138 23.259 5.815 23.905Z"
        fill="#3F24F5"
      />
      <path
        d="m116.043 131.963-4.846-.646s1.293-12.276-4.845-20.352c-2.908-3.876-7.43-6.138-13.245-6.784-6.461-.646-12.276 1.292-16.475 6.138-4.523 5.169-6.461 12.922-4.523 20.029l-4.846 1.292c-2.261-8.722 0-18.091 5.815-24.551 5.169-5.815 12.599-8.723 20.675-7.754 7.107.97 12.599 3.877 16.475 8.723 7.43 9.691 5.815 23.259 5.815 23.905Z"
        fill="#3F24F5"
      />
      <path
        d="m138.011 119.688-4.846-.646s1.292-12.276-4.846-20.352c-2.907-3.876-7.43-6.138-13.244-6.784-6.461-.646-12.276 1.292-16.476 6.138-4.522 5.169-6.46 12.922-4.522 20.029l-4.846 1.292c-2.261-8.722 0-18.091 5.815-24.551 5.168-5.815 12.599-8.723 20.675-7.754 7.107.97 12.598 3.877 16.475 8.723 7.43 9.368 5.815 23.259 5.815 23.905Z"
        fill="#3F24F5"
      />
      <path
        d="m159.978 106.443-4.846-.646s1.292-12.276-4.846-20.352c-2.907-3.876-7.43-6.138-13.245-6.784-6.461-.646-12.275 1.292-16.475 6.138-4.523 5.169-6.461 12.922-4.523 20.029l-4.845 1.292c-2.262-8.722 0-18.09 5.815-24.552 5.168-5.814 12.598-8.722 20.674-7.752 7.107.969 12.599 3.876 16.476 8.722 7.43 9.691 5.815 23.259 5.815 23.905ZM8.47 183.651c-8.723-9.045-10.984-21.967-5.492-32.628 4.522-9.368 13.89-14.537 24.551-13.245 7.107.97 12.599 3.877 16.475 8.723 7.753 9.691 6.138 23.259 5.815 23.905l-4.846-.646s1.293-12.276-4.845-20.352c-2.908-3.876-7.43-6.138-13.245-6.784-8.4-.969-15.83 2.908-19.383 10.661-4.522 8.722-2.584 19.705 4.846 27.135l-3.877 3.231Z"
        fill="#3F24F5"
      />
      <path
        d="m27.206 142.623-2.907-.969s.646-2.584 2.261-4.522l2.585 1.615c-1.293 1.615-1.939 3.876-1.939 3.876ZM49.496 129.702l-2.907-.97s.646-2.584 2.261-4.522l2.584 1.615c-1.292 1.615-1.938 3.877-1.938 3.877ZM71.463 117.103l-2.907-.969s.646-2.584 2.261-4.523l2.584 1.616c-1.292 1.615-1.938 3.876-1.938 3.876ZM93.753 104.181l-2.907-.969s.646-2.584 2.261-4.522l2.584 1.615c-1.292 1.615-1.938 3.876-1.938 3.876ZM115.074 92.551l-2.907-.969s.646-2.584 2.261-4.522l2.585 1.615c-1.293 1.615-1.939 3.553-1.939 3.876Z"
        fill="#53E8CF"
      />
      <path
        d="M167.407 164.591v-38.766c0-4.845 2.585-9.368 6.784-11.629l75.27-43.288c2.584-1.293 5.814.323 5.814 3.553v92.391c0 4.846-2.584 9.368-6.783 11.63l-75.27 43.611c-2.584 1.615-5.815-.323-5.815-3.231v-54.271Z"
        fill="#3F24F5"
      />
      <path
        d="M171.284 225c-.969 0-2.261-.323-3.23-.969-1.939-1.292-3.231-3.231-3.231-5.492v-92.714c0-5.815 3.231-10.983 8.076-13.891l75.27-42.965a5.87 5.87 0 0 1 6.461 0c1.938 1.292 3.23 3.23 3.23 5.492v92.391c0 5.815-3.23 10.983-8.076 13.891l-75.269 43.288c-1.293.646-2.262.969-3.231.969Zm80.115-152.154c-.323 0-.323 0-.646.323l-75.269 43.288c-3.554 1.938-5.492 5.815-5.492 9.691v92.714c0 .646.323.969.646.969s.969.323 1.615 0l75.27-43.611c3.553-1.938 5.492-5.815 5.492-9.691V74.461c0-.646-.324-.97-.647-.97-.323-.322-.646-.645-.969-.645Z"
        fill="#3F24F5"
      />
      <path
        d="M177.099 168.467v-38.766c0-4.845 2.584-9.368 6.784-11.629l75.269-43.288c2.584-1.293 5.815.646 5.815 3.553v92.391c0 4.846-2.584 9.368-6.784 11.63l-75.27 43.611c-2.584 1.615-5.814-.323-5.814-3.231v-54.271Z"
        fill="#53E8CF"
      />
      <path
        d="M180.976 228.876c-.97 0-2.262-.323-3.231-.969-1.938-1.292-3.23-3.23-3.23-5.492v-92.714c0-5.814 3.23-10.983 8.076-13.89l75.269-42.966a5.869 5.869 0 0 1 6.461 0c1.938 1.293 3.231 3.231 3.231 5.492v92.391c0 5.815-3.231 10.984-8.076 13.891l-75.27 43.288c-.969.646-2.261.969-3.23.969Zm80.115-151.83c-.323 0-.323 0-.646.322l-75.27 42.965c-3.553 1.938-5.492 5.815-5.492 9.691v92.714c0 .647.323.97.646.97.324 0 .647.323 1.616 0l75.269-43.612c3.554-1.938 5.492-5.814 5.492-9.691V78.337c0-.646-.323-.969-.646-.969-.323-.323-.646-.323-.969-.323Z"
        fill="#3F24F5"
      />
      <path
        d="m170.414 116.979-2.352 4.237 9.04 5.016 2.351-4.237-9.039-5.016ZM244.475 72.429l-2.351 4.237 9.039 5.016 2.351-4.237-9.039-5.016ZM175.16 217.57l-9.045-5.169 2.262-4.522 9.045 5.168-2.262 4.523Z"
        fill="#53E8CF"
      />
      <path
        d="m198.097 148.761 21.967 32.305 30.69-72.362c.323-.97-.323-1.293-.97-.323l-29.074 52.333-21.644-13.245c-.323-.323-1.292.646-.969 1.292Z"
        fill="#3F24F5"
      />
    </svg>
  );
}
