import styled from "styled-components/macro";
import { BREAKPOINT_MOBILE, RedText } from "../../theme";

const FormItemContainer = styled.div`
  padding: 0.75rem 0.8rem;
`;

const InputContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

const TooltipMessage = styled.div`
  visibility: hidden;
  position: absolute;
  width: 200px;
  bottom: 50px;
  right: 0;
  padding: 0.5em;
  border: 1px solid var(--color-gray-light);
  box-shadow: 1px 1px 2px var(--color-gray-light);
  border-radius: 5px;
  font-size: var(--font-size-small);
  background: white;
`;
const TooltipContainer = styled.div`
  position: relative;
  align-self: center;
  &:hover ${TooltipMessage} {
    visibility: visible;
  }
`;
const TooltipIcon = styled.div`
  width: 1.2rem;
  height: 1.2rem;
  line-height: 1.2rem;
  margin: auto;
  border: 1px solid var(--color-gray-light);
  box-shadow: 1px 1px 2px var(--color-gray-light);
  border-radius: 1.2rem;
  text-align: center;
  font-size: var(--font-size-normal);
  color: var(--color-green);
  cursor: pointer;
  &:hover {
    background: var(--color-green);
    color: white;
  }
`;

export const ErrorMessage = styled.div`
  margin-top: 0.5em;
  font-size: var(--font-size-small);
  color: var(--color-red);
`;

const FormItemHeader = styled.div`
  display: flex;
  @media screen and ${BREAKPOINT_MOBILE} {
    display: block;
  }
`;
const LabelContainer = styled.label`
  flex: 1;
  display: block;
  padding: 0 0 0.5rem 1rem;
  font-size: var(--font-size-normal);
  font-weight: bold;
`;
const Required = styled.span`
  color: var(--color-red);
`;
const FormItemLink = styled.a`
  padding: 0 1.8rem 0.5rem 1rem;
  font-size: var(--font-size-normal);
`;

const Label = function Label({
  forId,
  label,
  subLabel,
  required = false,
}: {
  forId: string;
  label: string;
  subLabel?: string;
  required?: boolean;
}) {
  return (
    <LabelContainer htmlFor={forId}>
      {label}
      {required && <Required> *</Required>}
      <br />
      <RedText>{subLabel}</RedText>
    </LabelContainer>
  );
};

const Tooltip = function ({ tooltipText }: { tooltipText: string }) {
  return (
    <TooltipContainer>
      <TooltipIcon>?</TooltipIcon>
      <TooltipMessage>{tooltipText}</TooltipMessage>
    </TooltipContainer>
  );
};

const FormItem = function FormItem({
  id,
  label,
  subLabel,
  linkText,
  linkUrl,
  tooltipText,
  required = true,
  errorMessages = [],
  children,
}: {
  id: string;
  label?: string;
  subLabel?: string;
  linkText?: string;
  linkUrl?: string;
  tooltipText?: string;
  required?: boolean;
  errorMessages?: string[];
  children?: React.ReactNode;
}) {
  return (
    <FormItemContainer>
      <FormItemHeader>
        {label && (
          <Label
            forId={id}
            label={label}
            subLabel={subLabel}
            required={required}
          />
        )}
        {linkText && <FormItemLink href={linkUrl}>{linkText}</FormItemLink>}
      </FormItemHeader>

      <InputContainer>
        {children}
        {tooltipText && <Tooltip tooltipText={tooltipText} />}
      </InputContainer>

      {errorMessages.map((e) => (
        <ErrorMessage key={e}>{e}</ErrorMessage>
      ))}
    </FormItemContainer>
  );
};

export default FormItem;
