import { types, SnapshotIn } from "mobx-state-tree";
import { observable } from "mobx";

import { getFirestore, getDocs, collection } from "firebase/firestore";

import { validate as validateEmail } from "email-validator";
import { isPossiblePhoneNumber } from "libphonenumber-js/core";
import metadata from "libphonenumber-js/metadata.min.json";

import { Address } from "./model";

const BusinessCategory = types.model("BusinessCategory", {
  icon: types.string,
  name: types.string,
  id: types.string,
  lname: types.model({ fr: types.string }),
});

const CATEGORIES = observable.box(
  {} as Record<string, SnapshotIn<typeof BusinessCategory>>,
  { deep: false },
);

const initCategories = async () => {
  const db = getFirestore();
  const sn: any = await getDocs(collection(db, "categories"));

  CATEGORIES.set(
    Object.fromEntries(
      sn.docs.map((d: any) => {
        const data: any = { id: d.id, ...d.data() };
        return [data.id, data];
      }),
    ),
  );
};

export const BusinessCreation = types
  .model("BusinessCreation", {
    name: types.maybe(types.string),
    address: types.optional(Address, {}),

    categories: types.array(BusinessCategory),
    catchPhrase: types.maybe(types.string),
    description: types.maybe(types.string),
    scheduleText: types.maybe(types.string),

    websiteUrl: types.maybe(types.string),
    facebookUrl: types.maybe(types.string),
    instagramUrl: types.maybe(types.string),

    email: types.maybe(types.string),
    phoneNumber: types.maybe(types.string),

    acceptCGV: types.optional(types.boolean, true),
  })
  .views((self) => ({
    get category() {
      return self.categories[0]?.id;
    },
  }))
  .actions((self) => ({
    update({
      name,

      category,
      catchPhrase,
      description,
      scheduleText,

      websiteUrl,
      facebookUrl,
      instagramUrl,

      email,
      phoneNumber,
      acceptCGV,
    }: {
      name?: string;

      category?: string;
      catchPhrase?: string;
      description?: string;
      scheduleText?: string;

      websiteUrl?: string;
      facebookUrl?: string;
      instagramUrl?: string;

      email?: string;
      phoneNumber?: string;
      acceptCGV?: boolean;
    }) {
      if (name || name === "") self.name = name;

      const categories = CATEGORIES.get();
      if (category && categories[category]) {
        self.categories.replace([categories[category]]);
      }

      if (catchPhrase || catchPhrase === "") self.catchPhrase = catchPhrase;
      if (description || description === "") self.description = description;
      if (scheduleText || scheduleText === "") self.scheduleText = scheduleText;
      if (websiteUrl || websiteUrl === "") self.websiteUrl = websiteUrl;
      if (facebookUrl || facebookUrl === "") self.facebookUrl = facebookUrl;
      if (instagramUrl || instagramUrl === "") self.instagramUrl = instagramUrl;

      if (email || email === "") self.email = email;
      if (phoneNumber || phoneNumber === "") self.phoneNumber = phoneNumber;

      if (acceptCGV || acceptCGV === false) self.acceptCGV = acceptCGV;
    },
  }))
  .views((self) => ({
    get phoneErrors() {
      if (!self.phoneNumber) return [];
      const errors: string[] = [];
      if (!isPossiblePhoneNumber(self.phoneNumber, metadata))
        errors.push("Mauvais format de numéro de téléphone");
      return errors;
    },

    get emailErrors() {
      if (!self.email) return [];
      const errors: string[] = [];
      if (!validateEmail(self.email)) errors.push("Email non valide");
      return errors;
    },

    get missingFields() {
      const missingFields = [];
      if (!self.name) missingFields.push("Nom de votre commerce");
      if (self.address.isMissing) missingFields.push("Adresse du commerce");
      if (!self.categories.length) missingFields.push("Catégorie");
      if (!self.catchPhrase) missingFields.push("Phrase de description");
      if (!self.email) missingFields.push("Email");
      if (!self.phoneNumber) missingFields.push("Téléphone");
      if (!self.acceptCGV) missingFields.push("Conditions générales");

      return missingFields;
    },
  }))

  .views((self) => ({
    get isValid() {
      return (
        !self.missingFields.length &&
        !self.phoneErrors.length &&
        !self.emailErrors.length
      );
    },
  }))
  .extend(() => {
    const imageFile = observable.box();

    return {
      views: {
        get image() {
          return imageFile.get();
        },

        get imageSrc() {
          const file = imageFile.get();
          if (!file) return null;
          return URL.createObjectURL(file);
        },
      },
      actions: {
        updateImage(newImage: File) {
          imageFile.set(newImage);
        },
      },
    };
  });

export const ContactCreation = types
  .model("ContactCreation", {
    firstname: types.maybe(types.string),
    lastname: types.maybe(types.string),

    email: types.maybe(types.string),
    phoneNumber: types.maybe(types.string),
  })
  .actions((self) => ({
    update({
      firstname,
      lastname,

      email,
      phoneNumber,
    }: {
      firstname?: string;
      lastname?: string;

      email?: string;
      phoneNumber?: string;
    }) {
      if (firstname || firstname === "") self.firstname = firstname;
      if (lastname || lastname === "") self.lastname = lastname;
      if (email || email === "") self.email = email;
      if (phoneNumber || phoneNumber === "") self.phoneNumber = phoneNumber;
    },
  }))
  .views((self) => ({
    get phoneErrors() {
      if (!self.phoneNumber) return [];
      const errors: string[] = [];
      if (!isPossiblePhoneNumber(self.phoneNumber, metadata))
        errors.push("Mauvais format de numéro de téléphone");
      return errors;
    },

    get emailErrors() {
      if (!self.email) return [];
      const errors: string[] = [];
      if (!validateEmail(self.email)) errors.push("Email non valide");
      return errors;
    },

    get missingFields() {
      const missingFields = [];
      if (!self.firstname) missingFields.push("Prénom");
      if (!self.lastname) missingFields.push("Nom");
      if (!self.email) missingFields.push("Email");
      if (!self.phoneNumber) missingFields.push("Téléphone");

      return missingFields;
    },
  }))
  .views((self) => ({
    get isValid() {
      return (
        !self.missingFields.length &&
        !self.phoneErrors.length &&
        !self.emailErrors.length
      );
    },
  }));

export { CATEGORIES, initCategories };
export default types.optional(
  types.model("NewBusiness", {
    business: types.optional(BusinessCreation, {}),
    owner: types.optional(ContactCreation, {}),
  }),
  {},
);
