import { Switch, Route, Redirect } from "react-router-dom";

import AppOnboarding from "./pages/AppOnboarding";
import AppBusinessCreation from "./pages/AppBusinessCreation";

export default function App() {
  return (
    <Switch>
      <Route path="/onboarding/:onboardingId" component={AppOnboarding} />
      <Route path="/register" component={AppBusinessCreation} />
      <Route path="/">
        <Redirect to="/register" />
      </Route>
    </Switch>
  );
}
