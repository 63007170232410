import styled from "styled-components/macro";
import { Toaster } from "react-hot-toast";
import { Link, useRouteMatch } from "react-router-dom";

const ToastLink = styled(Link)`
  color: white;
`;

const ToastContent = styled.div`
  text-align: center;
`;

export const AppToaster = () => {
  return (
    <Toaster
      position="bottom-center"
      toastOptions={{
        error: {
          duration: 10000,
          icon: null,
          style: {
            background: "var(--color-red)",
            color: "white",
            fontSize: "var(--font-size-normal)",
          },
        },
      }}
    />
  );
};

export const CustomToast = ({
  children,
  linkToDashboard = false,
}: {
  children?: React.ReactNode;
  linkToDashboard?: boolean;
}) => {
  const match = useRouteMatch("/onboarding/:onboardingId");

  return (
    <ToastContent>
      <p>{children}</p>
      {linkToDashboard && (
        <p>
          <ToastLink to={`${match?.url}/dashboard`}>
            Revenir au résumé
          </ToastLink>
        </p>
      )}
    </ToastContent>
  );
};
