import { useState, useEffect } from "react";
import styled from "styled-components/macro";
import { useHistory } from "react-router-dom";

const Frame = styled.div`
  & iframe {
    width: 100%;
    height: 100vh;
    border-style: none;
  }
`;

export const LivenessFrame = function LivenessFrame({
  livenessUrl,
}: {
  livenessUrl: string;
}) {
  const [livenessElement, setLivenessElement] = useState<HTMLDivElement | null>(
    null,
  );

  const history = useHistory();

  useEffect(() => {
    if (!livenessElement) return;

    const livenessMng = new (window as any).Ubble.IDV(livenessElement, {
      allowCamera: true,
      width: "100%",
      height: "100vh",
      identificationUrl: livenessUrl,
      events: {
        onComplete: () => {
          history.push("dashboard");
          livenessMng.destroy();
        },
        onAbort: (e: any) => {
          console.log("aborted: ", e);
          history.push("dashboard");
          livenessMng.destroy();
        },
      },
    });

    return () => {
      livenessMng.destroy();
    };
  }, [livenessElement, livenessUrl, history]);

  return <Frame ref={setLivenessElement} />;
};
