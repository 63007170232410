import { useEffect } from "react";
import styled from "styled-components/macro";
import {
  Route,
  Switch,
  useRouteMatch,
  Redirect,
  useLocation,
} from "react-router-dom";
import { observer } from "mobx-react";

import useAppState from "./../state/useAppState";
import { GreenText } from "./../theme";
import FillerHelper from "./../components/FillerHelper";
import { MainSectionBlue } from "../components/Sections";
import Header from "./../components/Header";
import { Footer } from "./../components/Footer";
import FormBusinessInfo from "./FormBusinessInfo";
import FormLegalRep from "./FormLegalRep";
import FormBeneficiary from "./FormBeneficiary";
import Liveness from "./Liveness";
import Dashboard from "./Dashboard";
import Login from "./Login";
import Error from "./Error";
import Loading from "./Loading";

const AppContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

const AppOnboarding = observer(() => {
  const appState = useAppState();

  // @ts-ignore
  const { path, url, params } = useRouteMatch("/onboarding/:onboardingId");
  const isDashboard = useLocation().pathname.includes("dashboard");

  const showTitle = !isDashboard;
  const showInformation = appState.ready && !isDashboard;
  const onboardingId = params?.onboardingId;

  useEffect(() => {
    appState.loadOnboardingFromDB(onboardingId);
  }, [onboardingId, appState]);

  return (
    <AppContainer>
      {appState.ready && <FillerHelper />}
      <Header>
        {showTitle && (
          <>
            Création
            <br /> de mon compte
          </>
        )}
      </Header>

      <main>
        {appState.notFound && <Error />}
        {appState.loading && <Loading />}

        {appState.ready && (
          <>
            {showInformation && (
              <MainSectionBlue>
                Pour bénéficier <GreenText>immédiatement</GreenText> et{" "}
                <GreenText>gratuitement</GreenText> des bons d'achats et chèques
                cadeaux LocallyPay, il vous suffit de saisir les informations
                qui vont nous présenter votre commerce.
              </MainSectionBlue>
            )}

            <Switch>
              <Route path={`${path}/dashboard`}>
                <Dashboard />
              </Route>
              <Route exact path={`${url}`}>
                <Redirect
                  to={`${url}${url.slice(-1) === "/" ? "" : "/"}dashboard`}
                />
              </Route>
              <Route path={`${path}/commerce`}>
                <FormBusinessInfo />
              </Route>
              <Route path={`${path}/representant`}>
                <FormLegalRep />
              </Route>
              <Route path={`${path}/beneficiaire/:beneficiaryid`}>
                <FormBeneficiary />
              </Route>
              <Route path={`${path}/liveness`}>
                <Liveness />
              </Route>
              <Route path={`${path}/login`}>
                <Login />
              </Route>
            </Switch>
          </>
        )}
      </main>

      <Footer />
    </AppContainer>
  );
});

export default AppOnboarding;
