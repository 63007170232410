import styled from "styled-components/macro";
import { Route, Switch } from "react-router-dom";

import Header from "./../components/Header";
import { Footer } from "./../components/Footer";
import { MainSectionBlue } from "../components/Sections";
import { GreenText } from "./../theme";
import FormBusinessCreation from "./FormBusinessCreation";
import FormBusinessOwnerCreation from "./FormBusinessOwnerCreation";
import CreationValidated from "./CreationValidated";

const AppContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

const AppBusinessCreation = () => {
  return (
    <AppContainer>
      <Header>
        Inscription
        <br /> de mon commerce
      </Header>

      <main>
        <MainSectionBlue>
          Pour bénéficier <GreenText>immédiatement</GreenText> et{" "}
          <GreenText>gratuitement</GreenText> des bons d'achats et chèques
          cadeaux LocallyPay, il vous suffit de saisir les informations qui vont
          nous présenter votre commerce.
        </MainSectionBlue>

        <Switch>
          <Route path="/register/owner" component={FormBusinessOwnerCreation} />
          <Route path="/register/validated" component={CreationValidated} />
          <Route path="/register" component={FormBusinessCreation} />
        </Switch>
      </main>

      <Footer />
    </AppContainer>
  );
};

export default AppBusinessCreation;
