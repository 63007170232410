import React, { useState } from "react";

import { Modal as ModalComp } from "../components/Modal";

const useModal = (modalContent: React.ReactNode) => {
  const [modalDisplayed, setModalDisplayed] = useState<boolean>(false);

  const Modal = () => (
    <ModalComp
      modalDisplayed={modalDisplayed}
      setModalDisplayed={setModalDisplayed}
    >
      {modalContent}
    </ModalComp>
  );

  return { Modal, modalDisplayed, setModalDisplayed };
};

export default useModal;
