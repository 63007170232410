import { createGlobalStyle } from "styled-components";
import styled from "styled-components/macro";

export const BREAKPOINT_MOBILE = "(max-width: 700px)";

const GlobalStyle = createGlobalStyle`
  :root {

    font-size: 20px;
    @media screen and ${BREAKPOINT_MOBILE} {
        font-size: 16px;
    }

    --color-green: rgba(83, 232, 207, 1);
    --color-green-dark: rgba(83, 232, 133, 1);
    --color-blue: rgba(63, 36, 245, 1);
    --color-blue-vlight: rgba(63, 36, 245, 0.04);
    --color-red: rgba(255, 0, 0, 1);
    --color-gray: rgba(135, 135, 135, 1);
    --color-gray-light: rgba(0, 0, 0, 0.1);
    --color-gray-vlight: rgba(0, 0, 0, 0.04);
    --color-white: rgba(255, 255, 255, 1);

    --font-size-small: 0.7rem; /* 14 */
    --font-size-normal: 0.8rem; /* 16 */
    --font-size-normal-plus: 1rem; /* 20 */
    --font-size-big: 1.5rem; /* 30 */
    --font-size-bigger: 2rem; /* 40 */
    --font-size-biggest: 2.5rem; /* 60 */

    @media screen and ${BREAKPOINT_MOBILE} {
        --font-size-small: 12px;
        --font-size-normal: 14px;
        --font-size-normal-plus: 16px;
        --font-size-big: 20px;
        --font-size-bigger: 24px;
        --font-size-biggest: 30px;
    }

  }

  * {
    box-sizing: border-box;
  }

  body {
    margin: 0;
    font-family: Poppins, "-apple-system", BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", "sans-serif";
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  p {margin: 0;}
  p:not(:last-child) {margin-bottom: 1rem;}

  ul {margin: 0;}

  a{
    color: var(--color-blue);
    font-weight: bold;
  }

  .grecaptcha-badge { 
    visibility: hidden;
  }
`;
export default GlobalStyle;

export const BlueText = styled.span`
  color: var(--color-blue);
`;

export const GreenText = styled.span`
  color: var(--color-green);
`;

export const RedText = styled.span`
  color: var(--color-red);
`;
