import { Dispatch, ReactNode } from "react";
import styled from "styled-components/macro";

const WindowOverlay = styled.div<{ modalDisplayed: boolean }>`
  display: ${({ modalDisplayed }) => (modalDisplayed ? "flex" : "none")};
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
`;
const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: min(90vw, 800px);
  padding: 1rem;
  border-radius: 5px;
  background: white;
`;
const CloseModalButton = styled.span`
  color: var(--color-red);
  align-self: flex-end;
  cursor: pointer;
`;

export const Modal = ({
  children,
  modalDisplayed,
  setModalDisplayed,
}: {
  children: ReactNode;
  modalDisplayed: boolean;
  setModalDisplayed: Dispatch<boolean>;
}) => {
  return (
    <WindowOverlay
      modalDisplayed={modalDisplayed}
      onClick={() => setModalDisplayed(false)}
    >
      <ModalContainer>
        <CloseModalButton onClick={() => setModalDisplayed(false)}>
          x
        </CloseModalButton>
        {children}
      </ModalContainer>
    </WindowOverlay>
  );
};
