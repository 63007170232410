import { observer } from "mobx-react";
import { useHistory, useParams } from "react-router-dom";

import NoticeArea from "../components/NoticeArea";
import {
  InputText,
  InputDate,
  InputNumber,
  InputPhone,
  InputAddress,
  Select,
  Checkbox,
} from "../components/form/FormItems";
import FormStatus from "../components/form/FormStatus";
import DropzoneItem from "../components/form/DropzoneItem";
import { ButtonBar, ButtonItem } from "../components/form/FormButtons";
import useOnboarding from "../state/useOnboarding";
import {
  COUNTRY,
  COUNTRY_LABELS,
  INCOME_RANGE,
  INCOME_RANGE_LABELS,
  PERSONAL_ASSETS,
  PERSONAL_ASSETS_LABELS,
} from "../state/constants";
import useAppState from "../state/useAppState";
import { RowTwoColumns } from "../components/form/FormRow";
import { MainSection } from "../components/Sections";
import { LINKS } from "../utils/constants";
import { OnboardingBenefBreadcrumbs } from "../components/OnboardingBreadcrumbs";

export default observer(function FormBeneficiary() {
  const history = useHistory();
  const { loadingFromTreezor, adminLoggedIn, metaTreezor } = useAppState();
  const { id: onboardingId, getBeneficiary, status } = useOnboarding();

  const { beneficiaryid } = useParams<{ beneficiaryid: string }>();
  const beneficiary = getBeneficiary(beneficiaryid);

  if (!beneficiary) return null;

  const disableChanges =
    loadingFromTreezor ||
    status === "closed" ||
    (!adminLoggedIn &&
      !!metaTreezor.metaEntries.get(beneficiary.id)?.wasCreatedOnTreezor);

  return (
    <MainSection>
      <OnboardingBenefBreadcrumbs />

      {disableChanges ? (
        <NoticeArea>
          <p>
            Vos informations ont déjà été transmises, et ne peuvent plus être
            modifiées ici.
          </p>
          <p>
            <a href={LINKS.contact} target="_blank">
              Contactez-nous en cas de questions ou de changements à effectuer
            </a>
          </p>
        </NoticeArea>
      ) : (
        <NoticeArea>
          <p>
            Un bénéficiaire représente toute personne détenant au moins 25% de
            l’entreprise.
          </p>
        </NoticeArea>
      )}

      {beneficiary && (
        <form>
          <RowTwoColumns>
            <InputText
              id="firstname"
              label="Prénom"
              placeholder="Alexandre"
              value={beneficiary.firstname}
              onChange={beneficiary.setFirstname}
              disabled={disableChanges}
            />

            <InputText
              id="lastname"
              label="Nom"
              placeholder="Tilleman"
              value={beneficiary.lastname}
              onChange={beneficiary.setLastname}
              disabled={disableChanges}
            />
          </RowTwoColumns>

          <RowTwoColumns>
            <InputDate
              id="birthday"
              label="Date de naissance"
              value={beneficiary.birthday}
              onChange={beneficiary.setBirthday}
              disabled={disableChanges}
            />

            <InputAddress
              id="placeOfBirth"
              label="Lieu de naissance"
              placeholder="L'Haye les Roses, France"
              precision="(regions)"
              value={beneficiary.placeOfBirth}
              onChange={beneficiary.placeOfBirth.updateDetails}
              errorMessages={beneficiary.placeOfBirthErrors}
              disabled={disableChanges}
            />
          </RowTwoColumns>

          <RowTwoColumns>
            <Select
              id="nationality"
              label="Nationalité"
              value={beneficiary.nationality}
              onChange={beneficiary.setNationality}
              options={[...COUNTRY]}
              optionLabels={COUNTRY_LABELS}
              disabled={disableChanges}
            />
          </RowTwoColumns>

          <RowTwoColumns>
            <InputText
              id="email"
              label="Email"
              placeholder="alexandre@locallypay.com"
              value={beneficiary.email}
              onChange={beneficiary.setEmail}
              errorMessages={beneficiary.emailErrors}
              disabled={disableChanges}
            />

            <InputPhone
              id="phoneNumber"
              label="Numéro de téléphone"
              placeholder="06 00 00 00 00"
              value={beneficiary.phoneNumber}
              onChange={beneficiary.setPhoneNumber}
              errorMessages={beneficiary.phoneErrors}
              disabled={disableChanges}
            />
          </RowTwoColumns>

          <InputAddress
            id="address"
            label="Adresse principale"
            errorMessages={beneficiary.addressErrors}
            placeholder="28 Rue René Barthélémy, Antony, France"
            value={beneficiary.address}
            onChange={beneficiary.address.updateDetails}
            disabled={disableChanges}
          />

          <RowTwoColumns>
            <Select
              id="incomeRange"
              label="Revenus"
              tooltipText="Sélectionner la tranche de revenu qui correspond à votre activité"
              value={beneficiary.incomeRange}
              onChange={beneficiary.setIncomeRange}
              options={[...INCOME_RANGE]}
              optionLabels={[...INCOME_RANGE_LABELS]}
              disabled={disableChanges}
            />

            <Select
              id="personalAssets"
              label="Patrimoine"
              value={beneficiary.personalAssets}
              onChange={beneficiary.setPersonalAssets}
              options={[...PERSONAL_ASSETS]}
              optionLabels={[...PERSONAL_ASSETS_LABELS]}
              disabled={disableChanges}
            />
          </RowTwoColumns>

          <RowTwoColumns>
            <InputText
              id="position"
              label="Fonction / Description du poste"
              placeholder="Co-Fondateur"
              tooltipText="Décrivez simplement votre poste dans ce commerce"
              value={beneficiary.position}
              onChange={beneficiary.setPosition}
              disabled={disableChanges}
            />

            <InputNumber
              id="shareOfCapital"
              label="Pourcentage du capital détenu"
              placeholder="49.1"
              value={beneficiary.shareOfCapital}
              onChange={beneficiary.setShareOfCapital}
              disabled={disableChanges}
            />
          </RowTwoColumns>

          {!beneficiary.livenessId && (
            <DropzoneItem
              id="personalIdId"
              label="Pièce d'identité"
              references={beneficiary.personalIdId}
              updateReference={beneficiary.addPersonalIdId}
              deleteReference={beneficiary.deletePersonalIdRef}
              disabled={disableChanges}
            />
          )}

          <Checkbox
            id="frenchFiscalResident"
            label="Résidence fiscale"
            checkboxText="Je certifie que je suis uniquement un résident fiscal français"
            value={beneficiary.frenchFiscalResident}
            onChange={beneficiary.setFrenchFiscalResident}
            errorMessages={beneficiary.frenchFiscalResidentErrors}
            disabled={disableChanges}
          />

          <Checkbox
            id="specifiedUSPerson"
            label="US Person"
            checkboxText="Je certifie que je ne suis pas une US Person"
            value={beneficiary.notUSPerson}
            onChange={beneficiary.setNotUSPerson}
            disabled={disableChanges}
          />

          {!beneficiary.notUSPerson && (
            <>
              <DropzoneItem
                id="NIFFormId"
                label="FATCA / W9 (Formulaire NIF)"
                references={beneficiary.NIFFormId}
                updateReference={beneficiary.addNIFFormRef}
                deleteReference={beneficiary.deleteNIFFormRef}
                disabled={disableChanges}
              />

              <RowTwoColumns>
                <InputText
                  id="taxNumber"
                  label="Tax identifier"
                  placeholder="12-3456789"
                  value={beneficiary.taxNumber}
                  onChange={beneficiary.setTaxNumber}
                  disabled={disableChanges}
                />
              </RowTwoColumns>
            </>
          )}

          <FormStatus
            isLoading={loadingFromTreezor}
            modelData={beneficiary}
            disabled={false}
          />

          <ButtonBar>
            <ButtonItem
              text={disableChanges ? "Retour au résumé" : "Valider"}
              onClick={() => {
                history.push(`/onboarding/${onboardingId}/dashboard`);
              }}
            ></ButtonItem>
          </ButtonBar>
        </form>
      )}
    </MainSection>
  );
});
