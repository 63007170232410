import styled from "styled-components/macro";

const FooterContainer = styled.footer`
  margin-top: auto;
  padding: 1em 2em;
  background: var(--color-blue);
`;

const Link = styled.a`
  padding: 0 0.5em;
  color: white;
  text-decoration: none;
`;

export const Footer = function () {
  return (
    <FooterContainer>
      <Link
        href="https://www.locallypay.com/pages/cgv"
        rel="noopener noreferrer"
        target="_blank"
      >
        CGV
      </Link>

      <Link
        href="https://www.locallypay.com/pages/confidentialite"
        rel="noopener noreferrer"
        target="_blank"
      >
        Confidentialité
      </Link>

      <Link
        href="https://www.locallypay.com/#contact"
        rel="noopener noreferrer"
        target="_blank"
      >
        Contact
      </Link>
    </FooterContainer>
  );
};
