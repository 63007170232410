// Imported from
// https://docs.treezor.com/guide/users/corporations.html#know-your-customer-kyc
// using https://docs.treezor.com/guide/users/corporations.html#know-your-customer-kyc

const RAW = [
  {
    Code: 1000,
    Category: "Individual company",
    "Details (in french)":
      "Entrepreneur Individuel a Responsabilité Limitée (EIRL)",
  },
  {
    Code: 2110,
    Category: "Indivision and others",
    "Details (in french)": "Indivision entre personnes physiques",
  },
  {
    Code: 2120,
    Category: "Indivision and others",
    "Details (in french)": "Indivision avec personne morale",
  },
  {
    Code: 2210,
    Category: "Indivision and others",
    "Details (in french)": "Société créée de fait entre personnes physiques",
  },
  {
    Code: 2220,
    Category: "Indivision and others",
    "Details (in french)": "Société créée de fait avec personne morale",
  },
  {
    Code: 2310,
    Category: "Indivision and others",
    "Details (in french)": "Société en participation entre personnes physiques",
  },
  {
    Code: 2320,
    Category: "Indivision and others",
    "Details (in french)": "Société en participation avec personne morale",
  },
  {
    Code: 2385,
    Category: "Indivision and others",
    "Details (in french)": "Société en participation de professions libérales",
  },
  {
    Code: 2400,
    Category: "Indivision and others",
    "Details (in french)": "Fiducie",
  },
  {
    Code: 2700,
    Category: "Diverse",
    "Details (in french)": "Paroisse hors zone concordataire",
  },
  {
    Code: 2900,
    Category: "Economic Interest Grouping",
    "Details (in french)":
      "Autre groupement de droit privé non doté de la personnalité morale",
  },
  {
    Code: 3110,
    Category: "Foreign company",
    "Details (in french)":
      "Représentation ou agence commerciale d'état ou organisme public étranger immatriculé au RCS",
  },
  {
    Code: 3120,
    Category: "Foreign company",
    "Details (in french)": "Société étrangère immatriculée au RCS",
  },
  {
    Code: 3205,
    Category: "Foreign company",
    "Details (in french)": "Organisation internationale",
  },
  {
    Code: 3210,
    Category: "Foreign company",
    "Details (in french)": "Etat collectivité ou établissement public étranger",
  },
  {
    Code: 3220,
    Category: "Foreign company",
    "Details (in french)": "Société étrangère non immatriculée au RCS",
  },
  {
    Code: 3290,
    Category: "Foreign company",
    "Details (in french)": "(Autre) personne morale de droit étranger",
  },
  {
    Code: 4110,
    Category: "Administrations, Instituions",
    "Details (in french)":
      "Etablissement public national à caractère industriel ou commercial doté d'un comptable public",
  },
  {
    Code: 4120,
    Category: "Administrations, Instituions",
    "Details (in french)":
      "Etablissement public national à caractère industriel ou commercial non doté d'un comptable public",
  },
  {
    Code: 4130,
    Category: "Administrations, Instituions",
    "Details (in french)": "Exploitant public",
  },
  {
    Code: 4140,
    Category: "Administrations, Instituions",
    "Details (in french)":
      "Etablissement public local à caractère industriel ou commercial",
  },
  {
    Code: 4150,
    Category: "Administrations, Instituions",
    "Details (in french)":
      "Régie d'une collectivité locale à caractère industriel ou commercial",
  },
  {
    Code: 4160,
    Category: "Administrations, Instituions",
    "Details (in french)": "Institution Banque de France",
  },
  {
    Code: 5191,
    Category: "De facto undivided ownership company",
    "Details (in french)": "Société de caution mutuelle",
  },
  {
    Code: 5192,
    Category: "Diverse",
    "Details (in french)": "Société coopérative de banque populaire",
  },
  {
    Code: 5193,
    Category: "De facto undivided ownership company",
    "Details (in french)": "Caisse de crédit maritime mutuel",
  },
  {
    Code: 5194,
    Category: "De facto undivided ownership company",
    "Details (in french)": "Caisse (fédérale) de crédit mutuel",
  },
  {
    Code: 5195,
    Category: "Association fondation",
    "Details (in french)":
      "Association coopérative inscrite (droit local Alsace Moselle)",
  },
  {
    Code: 5196,
    Category: "De facto undivided ownership company",
    "Details (in french)":
      "Caisse d'épargne et de prévoyance à forme coopérative",
  },
  {
    Code: 5202,
    Category: "General partnership",
    "Details (in french)": "Société en nom collectif",
  },
  {
    Code: 5203,
    Category: "General partnership",
    "Details (in french)": "Société en nom collectif coopérative",
  },
  {
    Code: 5306,
    Category: "Limited company",
    "Details (in french)": "Société en commandite simple",
  },
  {
    Code: 5307,
    Category: "Limited company",
    "Details (in french)": "Société en commandite simple coopérative",
  },
  {
    Code: 5308,
    Category: "Limited company",
    "Details (in french)": "Société en commandite par actions",
  },
  {
    Code: 5309,
    Category: "Limited company",
    "Details (in french)": "Société en commandite par actions coopérative",
  },
  {
    Code: 5385,
    Category: "Limited company",
    "Details (in french)":
      "Société d'exercice libéral en commandite par action",
  },
  {
    Code: 5410,
    Category: "Limited Liability Company",
    "Details (in french)": "SARL nationale",
  },
  {
    Code: 5415,
    Category: "Limited Liability Company",
    "Details (in french)": "SARL d'économie mixte",
  },
  {
    Code: 5422,
    Category: "Limited Liability Company",
    "Details (in french)":
      "SARL immobilière pour le commerce et l'industrie (SICOMI)",
  },
  {
    Code: 5426,
    Category: "Limited Liability Company",
    "Details (in french)": "Société immobilière de gestion",
  },
  {
    Code: 5430,
    Category: "Limited Liability Company",
    "Details (in french)": "Safer en SARL",
  },
  {
    Code: 5431,
    Category: "Limited Liability Company",
    "Details (in french)": "SARL mixte d'intérêt agricole (SIMA)",
  },
  {
    Code: 5432,
    Category: "Limited Liability Company",
    "Details (in french)": "SARL d'intérêt collectif agricole (SICA)",
  },
  {
    Code: 5442,
    Category: "Limited Liability Company",
    "Details (in french)": "SARL d'attribution",
  },
  {
    Code: 5443,
    Category: "Limited Liability Company",
    "Details (in french)": "SARL coopérative de construction",
  },
  {
    Code: 5451,
    Category: "Limited Liability Company",
    "Details (in french)": "SARL coopérative de consommation",
  },
  {
    Code: 5453,
    Category: "Limited Liability Company",
    "Details (in french)": "SARL coopérative artisanale",
  },
  {
    Code: 5454,
    Category: "Limited Liability Company",
    "Details (in french)": "SARL coopérative d'intérêt maritime",
  },
  {
    Code: 5455,
    Category: "Limited Liability Company",
    "Details (in french)": "SARL coopérative de transports",
  },
  {
    Code: 5458,
    Category: "Limited Liability Company",
    "Details (in french)":
      "SARL coopérative ouvrière de production et de crédit (SCOP)",
  },
  {
    Code: 5459,
    Category: "Limited Liability Company",
    "Details (in french)": "SARL union de sociétés coopératives",
  },
  {
    Code: 5460,
    Category: "Limited Liability Company",
    "Details (in french)": "SARL coopérative",
  },
  {
    Code: 5485,
    Category: "Limited Liability Company",
    "Details (in french)":
      "Société d'exercice libéral à responsabilité limitée",
  },
  {
    Code: 5498,
    Category: "Limited Liability Company",
    "Details (in french)": "SARL unipersonnelle",
  },
  {
    Code: 5499,
    Category: "Limited Liability Company",
    "Details (in french)": "SARL",
  },
  {
    Code: 5505,
    Category: "Limited company",
    "Details (in french)":
      "SA à participation ouvrière à conseil d'administration",
  },
  {
    Code: 5510,
    Category: "Limited company",
    "Details (in french)": "SA nationale à conseil d'administration",
  },
  {
    Code: 5515,
    Category: "Limited company",
    "Details (in french)": "SA d'économie mixte à conseil d'administration",
  },
  {
    Code: 5520,
    Category: "Limited company",
    "Details (in french)":
      "Société d'investissement à capital variable (SICAV) à conseil d'administration",
  },
  {
    Code: 5522,
    Category: "Limited company",
    "Details (in french)":
      "Société anonyme immobilière pour le commerce et l'industrie (SICOMI) à conseil d'administration",
  },
  {
    Code: 5525,
    Category: "Limited company",
    "Details (in french)":
      "Société anonyme immobilière d'investissement à conseil d'administration",
  },
  {
    Code: 5530,
    Category: "Limited company",
    "Details (in french)": "Safer anonyme à conseil d'administration",
  },
  {
    Code: 5531,
    Category: "Limited company",
    "Details (in french)":
      "Société anonyme mixte d'intérêt agricole (SMIA) à conseil d'administration",
  },
  {
    Code: 5532,
    Category: "Limited company",
    "Details (in french)":
      "Société anonyme mixte d'intérêt collectif agricole (SICA) à conseil d'administration",
  },
  {
    Code: 5542,
    Category: "Limited company",
    "Details (in french)":
      "Société anonyme d'attribution à conseil d'administration",
  },
  {
    Code: 5543,
    Category: "Limited company",
    "Details (in french)":
      "Société anonyme coopérative de construction à conseil d'administration",
  },
  {
    Code: 5546,
    Category: "Limited company",
    "Details (in french)": "SA de HLM à conseil d'administration",
  },
  {
    Code: 5547,
    Category: "Limited company",
    "Details (in french)":
      "SA coopérative de production de HLM à conseil d'administration",
  },
  {
    Code: 5548,
    Category: "Limited company",
    "Details (in french)": "SA de crédit immobilier à conseil d'administration",
  },
  {
    Code: 5551,
    Category: "Limited company",
    "Details (in french)":
      "SA coopérative de consommation à conseil d'administration",
  },
  {
    Code: 5552,
    Category: "Limited company",
    "Details (in french)":
      "SA coopérative de commerçants détaillants à conseil d'administration",
  },
  {
    Code: 5553,
    Category: "Limited company",
    "Details (in french)":
      "SA coopérative artisanale à conseil d'administration",
  },
  {
    Code: 5554,
    Category: "Limited company",
    "Details (in french)":
      "SA coopérative (d'intérêt) maritime à conseil d'administration",
  },
  {
    Code: 5555,
    Category: "Limited company",
    "Details (in french)":
      "SA coopérative de transports à conseil d'administration",
  },
  {
    Code: 5558,
    Category: "Limited company",
    "Details (in french)": "SCOP à conseil d'administration",
  },
  {
    Code: 5559,
    Category: "Limited company",
    "Details (in french)":
      "SA union de sociétés coopératives à conseil d'administration",
  },
  {
    Code: 5560,
    Category: "Limited company",
    "Details (in french)": "SA coopérative à conseil d'administration",
  },
  {
    Code: 5585,
    Category: "Limited company",
    "Details (in french)":
      "Société d'exercice libéral à forme anonyme à conseil d'administration",
  },
  {
    Code: 5599,
    Category: "Limited company",
    "Details (in french)": "SA à conseil d'administration",
  },
  {
    Code: 5605,
    Category: "Limited company",
    "Details (in french)": "SA à participation ouvrière à directoire",
  },
  {
    Code: 5610,
    Category: "Limited company",
    "Details (in french)": "SA nationale à directoire",
  },
  {
    Code: 5615,
    Category: "Limited company",
    "Details (in french)": "SA d'économie mixte à directoire",
  },
  {
    Code: 5620,
    Category: "Limited company",
    "Details (in french)": "SICAV",
  },
  {
    Code: 5622,
    Category: "Limited company",
    "Details (in french)": "SICOMI",
  },
  {
    Code: 5625,
    Category: "Limited company",
    "Details (in french)":
      "Société immobilière d'investissement anonyme à directoire",
  },
  {
    Code: 5630,
    Category: "Limited company",
    "Details (in french)": "Safer anonyme à directoire",
  },
  {
    Code: 5631,
    Category: "Limited company",
    "Details (in french)": "Société anonyme mixte d'intérêt agricole (SMIA)",
  },
  {
    Code: 5632,
    Category: "Limited company",
    "Details (in french)": "SICA",
  },
  {
    Code: 5642,
    Category: "Limited company",
    "Details (in french)": "Société anonyme d'attribution à directoire",
  },
  {
    Code: 5643,
    Category: "Limited company",
    "Details (in french)":
      "Société anonyme coopérative de construction à directoire",
  },
  {
    Code: 5646,
    Category: "Limited company",
    "Details (in french)": "S.A. HLM à directoire",
  },
  {
    Code: 5647,
    Category: "Limited company",
    "Details (in french)":
      "Société coopérative de production de HLM anonyme à directoire",
  },
  {
    Code: 5648,
    Category: "Limited company",
    "Details (in french)": "SA de crédit immobilier à directoire",
  },
  {
    Code: 5651,
    Category: "Limited company",
    "Details (in french)": "SA coopérative de consommation à directoire",
  },
  {
    Code: 5652,
    Category: "Limited company",
    "Details (in french)":
      "SA coopérative de commerçants détaillants à directoire",
  },
  {
    Code: 5653,
    Category: "Limited company",
    "Details (in french)": "SA coopérative artisanale à directoire",
  },
  {
    Code: 5654,
    Category: "Limited company",
    "Details (in french)": "SA coopérative (d'intérêt) maritime à directoire",
  },
  {
    Code: 5655,
    Category: "Limited company",
    "Details (in french)": "SA coopérative de transport à directoire",
  },
  {
    Code: 5658,
    Category: "Limited company",
    "Details (in french)": "SCOP",
  },
  {
    Code: 5659,
    Category: "Limited company",
    "Details (in french)": "SA union de sociétés coopératives à directoire",
  },
  {
    Code: 5660,
    Category: "Limited company",
    "Details (in french)": "SA coopérative à directoire",
  },
  {
    Code: 5685,
    Category: "Limited company",
    "Details (in french)":
      "Société d'exercice libéral à forme anonyme à directoire",
  },
  {
    Code: 5699,
    Category: "Limited company",
    "Details (in french)": "(Autre) SA à directoire",
  },
  {
    Code: 5710,
    Category: "Limited company",
    "Details (in french)": "SAS",
  },
  {
    Code: 5720,
    Category: "Limited company",
    "Details (in french)": "SASU",
  },
  {
    Code: 5785,
    Category: "Limited company",
    "Details (in french)": "Société d'exercice libéral par action simplifiée",
  },
  {
    Code: 5800,
    Category: "Diverse",
    "Details (in french)": "Société européenne",
  },
  {
    Code: 6100,
    Category: "De facto undivided ownership company",
    "Details (in french)": "Caisse d'épargne et de prévoyance",
  },
  {
    Code: 6210,
    Category: "Economic Interest Grouping",
    "Details (in french)": "GEIE",
  },
  {
    Code: 6220,
    Category: "Economic Interest Grouping",
    "Details (in french)": "GIE",
  },
  {
    Code: 6316,
    Category: "Diverse",
    "Details (in french)": "CUMA",
  },
  {
    Code: 6317,
    Category: "Diverse",
    "Details (in french)": "Société coopérative agricole",
  },
  {
    Code: 6318,
    Category: "Diverse",
    "Details (in french)": "Union de sociétés coopératives agricoles",
  },
  {
    Code: 6411,
    Category: "De facto undivided ownership company",
    "Details (in french)": "Société d'assurance mutuelle",
  },
  {
    Code: 6521,
    Category: "Civils companies (without SCI)",
    "Details (in french)": "SCPI",
  },
  {
    Code: 6532,
    Category: "Civils companies (without SCI)",
    "Details (in french)": "SICA",
  },
  {
    Code: 6533,
    Category: "Civils companies (without SCI)",
    "Details (in french)": "GAEC",
  },
  {
    Code: 6534,
    Category: "Civils companies (without SCI)",
    "Details (in french)": "Groupement foncier agricole",
  },
  {
    Code: 6535,
    Category: "Civils companies (without SCI)",
    "Details (in french)": "Groupement agricole foncier",
  },
  {
    Code: 6536,
    Category: "Civils companies (without SCI)",
    "Details (in french)": "Groupement forestier",
  },
  {
    Code: 6537,
    Category: "Civils companies (without SCI)",
    "Details (in french)": "Groupement pastoral",
  },
  {
    Code: 6538,
    Category: "Civils companies (without SCI)",
    "Details (in french)": "Groupement foncier rural",
  },
  {
    Code: 6539,
    Category: "Civils companies (without SCI)",
    "Details (in french)": "Société civile foncière",
  },
  {
    Code: 6540,
    Category: "Civils companies",
    "Details (in french)": "SCI",
  },
  {
    Code: 6541,
    Category: "Civils companies",
    "Details (in french)": "SCI de construction vente",
  },
  {
    Code: 6542,
    Category: "Civils companies (without SCI)",
    "Details (in french)": "Société civile d'attribution",
  },
  {
    Code: 6543,
    Category: "Civils companies (without SCI)",
    "Details (in french)": "Société civile coopérative de construction",
  },
  {
    Code: 6544,
    Category: "Civils companies",
    "Details (in french)":
      "Société civile d'accession progressive à la propriété",
  },
  {
    Code: 6551,
    Category: "Civils companies (without SCI)",
    "Details (in french)": "Société civile coopérative de consommation",
  },
  {
    Code: 6554,
    Category: "Civils companies (without SCI)",
    "Details (in french)": "Société civile coopérative (d'intérêt) maritime",
  },
  {
    Code: 6558,
    Category: "Civils companies (without SCI)",
    "Details (in french)": "Société civile coopérative entre médecins",
  },
  {
    Code: 6560,
    Category: "Civils companies (without SCI)",
    "Details (in french)": "SCP",
  },
  {
    Code: 6561,
    Category: "Civils companies (without SCI)",
    "Details (in french)": "SCP d'avocats",
  },
  {
    Code: 6562,
    Category: "Civils companies (without SCI)",
    "Details (in french)": "SCP d'avocats aux conseil",
  },
  {
    Code: 6563,
    Category: "Civils companies (without SCI)",
    "Details (in french)": "SCP d'avoués d'appel",
  },
  {
    Code: 6564,
    Category: "Civils companies (without SCI)",
    "Details (in french)": "SCP d'huissiers",
  },
  {
    Code: 6565,
    Category: "Civils companies (without SCI)",
    "Details (in french)": "SCP de notaires",
  },
  {
    Code: 6566,
    Category: "Civils companies (without SCI)",
    "Details (in french)": "SCP de commissaires-priseurs",
  },
  {
    Code: 6567,
    Category: "Civils companies (without SCI)",
    "Details (in french)": "SCP de greffiers de tribunal de commerce",
  },
  {
    Code: 6568,
    Category: "Civils companies (without SCI)",
    "Details (in french)": "SCP de conseils juridiques",
  },
  {
    Code: 6569,
    Category: "Civils companies (without SCI)",
    "Details (in french)": "SCP de commissaires aux comptes",
  },
  {
    Code: 6571,
    Category: "Civils companies (without SCI)",
    "Details (in french)": "SCP de médecins",
  },
  {
    Code: 6572,
    Category: "Civils companies (without SCI)",
    "Details (in french)": "SCP de dentistes",
  },
  {
    Code: 6573,
    Category: "Civils companies (without SCI)",
    "Details (in french)": "SCP d'infirmiers",
  },
  {
    Code: 6574,
    Category: "Civils companies (without SCI)",
    "Details (in french)": "SCP de masseurs kinésithérapeutes",
  },
  {
    Code: 6575,
    Category: "Civils companies (without SCI)",
    "Details (in french)":
      "SCP de directeurs de laboratoire d'analyse médicale",
  },
  {
    Code: 6576,
    Category: "Civils companies (without SCI)",
    "Details (in french)": "SCP de vétérinaires",
  },
  {
    Code: 6577,
    Category: "Civils companies (without SCI)",
    "Details (in french)": "SCP de géomètres-experts",
  },
  {
    Code: 6578,
    Category: "Civils companies (without SCI)",
    "Details (in french)": "SCP d'architectes",
  },
  {
    Code: 6585,
    Category: "Civils companies (without SCI)",
    "Details (in french)": "SCP",
  },
  {
    Code: 6588,
    Category: "Civils companies (without SCI)",
    "Details (in french)": "Société civile laitière",
  },
  {
    Code: 6589,
    Category: "Civils companies (without SCI)",
    "Details (in french)": "Société civile de moyens",
  },
  {
    Code: 6595,
    Category: "Civils companies (without SCI)",
    "Details (in french)": "Caisse (locale) de crédit mutuel",
  },
  {
    Code: 6596,
    Category: "Civils companies (without SCI)",
    "Details (in french)": "Caisse de crédit agricole mutuel",
  },
  {
    Code: 6597,
    Category: "Civils companies (without SCI)",
    "Details (in french)": "Société civile d'exploitation agricole",
  },
  {
    Code: 6598,
    Category: "Farm operator",
    "Details (in french)": "Exploitation agricole à responsabilité limitée",
  },
  {
    Code: 6599,
    Category: "Civils companies (without SCI)",
    "Details (in french)": "Autre société civile",
  },
  {
    Code: 6901,
    Category: "Diverse",
    "Details (in french)":
      "Autres personnes de droit privé inscrites au registre du commerce et des sociétés",
  },
  {
    Code: 7111,
    Category: "Administrations, Instituions",
    "Details (in french)": "Autorité constitutionnelle",
  },
  {
    Code: 7112,
    Category: "Administrations, Instituions",
    "Details (in french)": "Autorité administrative indépendante",
  },
  {
    Code: 7113,
    Category: "Administrations, Instituions",
    "Details (in french)": "Ministère",
  },
  {
    Code: 7120,
    Category: "Administrations, Instituions",
    "Details (in french)": "Service central d'un ministère",
  },
  {
    Code: 7150,
    Category: "Administrations, Instituions",
    "Details (in french)": "Service du ministère de la Défense",
  },
  {
    Code: 7160,
    Category: "Administrations, Instituions",
    "Details (in french)":
      "Service déconcentré à compétence nation . D'un ministère (hors Défense)",
  },
  {
    Code: 7171,
    Category: "Administrations, Instituions",
    "Details (in french)":
      "Service déconcentré de l'Etat à compétence (inter) régionale",
  },
  {
    Code: 7172,
    Category: "Administrations, Instituions",
    "Details (in french)":
      "Service déconcentré de l'Etat à compétence (inter) départementale",
  },
  {
    Code: 7179,
    Category: "Administrations, Instituions",
    "Details (in french)":
      "(Autre) Service déconcentré de l'Etat à compétence territoriale",
  },
  {
    Code: 7190,
    Category: "Administrations, Instituions",
    "Details (in french)":
      "Ecole nationale non dotée de la personnalité morale",
  },
  {
    Code: 7210,
    Category: "Administrations, Instituions",
    "Details (in french)": "Commune",
  },
  {
    Code: 7220,
    Category: "Administrations, Instituions",
    "Details (in french)": "Département",
  },
  {
    Code: 7225,
    Category: "Administrations, Instituions",
    "Details (in french)": "Territoire d'Outre-Mer",
  },
  {
    Code: 7229,
    Category: "Administrations, Instituions",
    "Details (in french)": "(Autre) Collectivité territoriale",
  },
  {
    Code: 7230,
    Category: "Administrations, Instituions",
    "Details (in french)": "Région",
  },
  {
    Code: 7312,
    Category: "Administrations, Instituions",
    "Details (in french)": "Commune associée",
  },
  {
    Code: 7313,
    Category: "Administrations, Instituions",
    "Details (in french)": "Section de commune",
  },
  {
    Code: 7314,
    Category: "Administrations, Instituions",
    "Details (in french)": "Ensemble urbain",
  },
  {
    Code: 7321,
    Category: "Administrations, Instituions",
    "Details (in french)": "Association syndicale autorisée",
  },
  {
    Code: 7322,
    Category: "Administrations, Instituions",
    "Details (in french)": "Association foncière urbaine",
  },
  {
    Code: 7323,
    Category: "Administrations, Instituions",
    "Details (in french)": "Association foncière de remembrement",
  },
  {
    Code: 7331,
    Category: "Administrations, Instituions",
    "Details (in french)": "Etablissement public local d'enseignement",
  },
  {
    Code: 7340,
    Category: "Administrations, Instituions",
    "Details (in french)": "Pôle métropolitain",
  },
  {
    Code: 7341,
    Category: "Administrations, Instituions",
    "Details (in french)": "Secteur de commune",
  },
  {
    Code: 7342,
    Category: "Administrations, Instituions",
    "Details (in french)": "District urbain",
  },
  {
    Code: 7343,
    Category: "Administrations, Instituions",
    "Details (in french)": "Communauté urbaine",
  },
  {
    Code: 7344,
    Category: "Administrations, Instituions",
    "Details (in french)": "Métropole",
  },
  {
    Code: 7345,
    Category: "Administrations, Instituions",
    "Details (in french)": "Syndicat intercommunal à vocation multiple (SIVOM)",
  },
  {
    Code: 7346,
    Category: "Administrations, Instituions",
    "Details (in french)": "Communauté de commune",
  },
  {
    Code: 7347,
    Category: "Administrations, Instituions",
    "Details (in french)": "Communauté de villes",
  },
  {
    Code: 7348,
    Category: "Administrations, Instituions",
    "Details (in french)": "Communauté d'agglomération",
  },
  {
    Code: 7349,
    Category: "Administrations, Instituions",
    "Details (in french)":
      "Autre établissement public local de coopération non spécialisé ou entente",
  },
  {
    Code: 7351,
    Category: "Administrations, Instituions",
    "Details (in french)": "Institution interdépartementale ou entente",
  },
  {
    Code: 7352,
    Category: "Administrations, Instituions",
    "Details (in french)": "Institution interrégionale ou entente",
  },
  {
    Code: 7353,
    Category: "Administrations, Instituions",
    "Details (in french)": "Syndicat intercommunal à vocation unique (SIVU)",
  },
  {
    Code: 7354,
    Category: "Administrations, Instituions",
    "Details (in french)": "Syndicat mixte communal",
  },
  {
    Code: 7355,
    Category: "Administrations, Instituions",
    "Details (in french)": "Autre syndicat mixte",
  },
  {
    Code: 7356,
    Category: "Administrations, Instituions",
    "Details (in french)":
      "Commission syndicale pour la gestion des biens indivis des communes",
  },
  {
    Code: 7361,
    Category: "Administrations, Instituions",
    "Details (in french)": "Centre communal d'action sociale",
  },
  {
    Code: 7362,
    Category: "Administrations, Instituions",
    "Details (in french)": "Caisse des écoles",
  },
  {
    Code: 7363,
    Category: "Administrations, Instituions",
    "Details (in french)": "Caisse de crédit municipal",
  },
  {
    Code: 7364,
    Category: "Administrations, Instituions",
    "Details (in french)": "Etablissement d'hospitalisation",
  },
  {
    Code: 7365,
    Category: "Administrations, Instituions",
    "Details (in french)": "Syndicat inter hospitalier",
  },
  {
    Code: 7366,
    Category: "Administrations, Instituions",
    "Details (in french)": "Etablissement public local social et médico-social",
  },
  {
    Code: 7371,
    Category: "Administrations, Instituions",
    "Details (in french)": "Office public d'habitation à loyer modéré (OPHLM)",
  },
  {
    Code: 7372,
    Category: "Administrations, Instituions",
    "Details (in french)": "Service départemental d'incendie",
  },
  {
    Code: 7373,
    Category: "Administrations, Instituions",
    "Details (in french)": "Etablissement public local culturel",
  },
  {
    Code: 7378,
    Category: "Administrations, Instituions",
    "Details (in french)":
      "Régie d'une collectivité locale à caractère administratif",
  },
  {
    Code: 7379,
    Category: "Administrations, Instituions",
    "Details (in french)": "(Autre) Etablissement public administratif local",
  },
  {
    Code: 7381,
    Category: "Administrations, Instituions",
    "Details (in french)": "Organisme consulaire",
  },
  {
    Code: 7382,
    Category: "Administrations, Instituions",
    "Details (in french)":
      "Etablissement public national ayant fonction d'administration centrale",
  },
  {
    Code: 7383,
    Category: "Administrations, Instituions",
    "Details (in french)":
      "Etablissement public national à caractère scientifique culturel et professionnel",
  },
  {
    Code: 7384,
    Category: "Administrations, Instituions",
    "Details (in french)": "Autre établissement public national d'enseignement",
  },
  {
    Code: 7385,
    Category: "Administrations, Instituions",
    "Details (in french)":
      "Autre établissement public national administratif à compétence territoriale limitée",
  },
  {
    Code: 7389,
    Category: "Administrations, Instituions",
    "Details (in french)":
      "Etablissement public national à caractère administratif",
  },
  {
    Code: 7410,
    Category: "Administrations, Instituions",
    "Details (in french)": "Groupement d'intérêt public (GIP)",
  },
  {
    Code: 7430,
    Category: "Administrations, Instituions",
    "Details (in french)": "Etablissement public des cultes d'Alsace-Lorraine",
  },
  {
    Code: 7450,
    Category: "Administrations, Instituions",
    "Details (in french)":
      "Etablissement public, cercle et foyer dans les armées",
  },
  {
    Code: 7470,
    Category: "Administrations, Instituions",
    "Details (in french)":
      "Groupement de coopération sanitaire à gestion publique",
  },
  {
    Code: 7490,
    Category: "Administrations, Instituions",
    "Details (in french)": "Autre personne morale de droit administratif",
  },
  {
    Code: 8110,
    Category: "Administrations, Instituions",
    "Details (in french)": "Régime général de la sécurité sociale",
  },
  {
    Code: 8120,
    Category: "Administrations, Instituions",
    "Details (in french)": "Régime spécial de sécurité sociale",
  },
  {
    Code: 8130,
    Category: "Administrations, Instituions",
    "Details (in french)": "Institution de retraite complémentaire",
  },
  {
    Code: 8140,
    Category: "Administrations, Instituions",
    "Details (in french)": "Mutualité sociale agricole",
  },
  {
    Code: 8150,
    Category: "Administrations, Instituions",
    "Details (in french)": "Régime maladie des non-salariés non agricoles",
  },
  {
    Code: 8160,
    Category: "Administrations, Instituions",
    "Details (in french)":
      "Régime vieillesse ne dépendant pas du régime général de la sécurité sociale",
  },
  {
    Code: 8170,
    Category: "Administrations, Instituions",
    "Details (in french)": "Régime d'assurance chômage",
  },
  {
    Code: 8190,
    Category: "Administrations, Instituions",
    "Details (in french)": "Autre régime de prévoyance sociale",
  },
  {
    Code: 8210,
    Category: "De facto undivided ownership company",
    "Details (in french)": "Mutuelle",
  },
  {
    Code: 8250,
    Category: "De facto undivided ownership company",
    "Details (in french)": "Assurance mutuelle agricole",
  },
  {
    Code: 8290,
    Category: "De facto undivided ownership company",
    "Details (in french)": "Autre organisme mutualiste",
  },
  {
    Code: 8310,
    Category: "Association fondation",
    "Details (in french)": "Comité central d'entreprise",
  },
  {
    Code: 8311,
    Category: "Association fondation",
    "Details (in french)": "Comité d'établissement",
  },
  {
    Code: 8410,
    Category: "Association fondation",
    "Details (in french)": "Syndicat de salariés",
  },
  {
    Code: 8420,
    Category: "Association fondation",
    "Details (in french)": "Syndicat patronal",
  },
  {
    Code: 8450,
    Category: "Association fondation",
    "Details (in french)": "Ordre professionnel ou assimilé",
  },
  {
    Code: 8470,
    Category: "Association fondation",
    "Details (in french)":
      "Centre technique industriel ou comité professionnel du développement économique",
  },
  {
    Code: 8490,
    Category: "Association fondation",
    "Details (in french)": "Autre organisme professionnel",
  },
  {
    Code: 8510,
    Category: "Administrations, Instituions",
    "Details (in french)": "Institution de prévoyance",
  },
  {
    Code: 8520,
    Category: "Administrations, Instituions",
    "Details (in french)": "Institution de retraite supplémentaire",
  },
  {
    Code: 9110,
    Category: "Condominium syndicate",
    "Details (in french)": "Syndicat de copropriété",
  },
  {
    Code: 9150,
    Category: "Association fondation",
    "Details (in french)": "Association syndicale libre",
  },
  {
    Code: 9210,
    Category: "Association fondation",
    "Details (in french)": "Association non déclarée",
  },
  {
    Code: 9220,
    Category: "Association fondation",
    "Details (in french)": "Association déclarée",
  },
  {
    Code: 9221,
    Category: "Association fondation",
    "Details (in french)":
      "Association déclarée \"entreprises d'insertion par l'économique\"",
  },
  {
    Code: 9222,
    Category: "Association fondation",
    "Details (in french)": "Association intermédiaire",
  },
  {
    Code: 9223,
    Category: "Association fondation",
    "Details (in french)": "Groupement d'employeurs",
  },
  {
    Code: 9224,
    Category: "Association fondation",
    "Details (in french)":
      "Association d'avocats à responsabilité professionnelle individuelle",
  },
  {
    Code: 9230,
    Category: "Association fondation",
    "Details (in french)": "Association déclarée reconnue d'utilité publique",
  },
  {
    Code: 9240,
    Category: "Association fondation",
    "Details (in french)": "Congrégation",
  },
  {
    Code: 9260,
    Category: "Association fondation",
    "Details (in french)": "Association de droit local",
  },
  {
    Code: 9300,
    Category: "Association fondation",
    "Details (in french)": "Fondation",
  },
  {
    Code: 9900,
    Category: "Diverse",
    "Details (in french)": "Autre personne morale de droit privé",
  },
  {
    Code: 9970,
    Category: "Diverse",
    "Details (in french)":
      "Groupement de coopération sanitaire à gestion privée",
  },
];

export const LEGAL_FORMS = RAW.map(({ Code }) => `${Code}`);
export const LEGAL_FORMS_LABELS = RAW.map(
  (r) => `${r["Details (in french)"]}  (${r.Code})`,
);
