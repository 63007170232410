import { observer } from "mobx-react";
import { useHistory } from "react-router-dom";

import {
  InputText,
  InputDate,
  InputNumber,
  InputPhone,
  InputAddress,
  Select,
  Checkbox,
} from "../components/form/FormItems";
import FormStatus from "../components/form/FormStatus";
import DropzoneItem from "../components/form/DropzoneItem";
import { ButtonBar, ButtonItem } from "../components/form/FormButtons";
import useOnboarding from "../state/useOnboarding";
import {
  LEGAL_FORMS,
  LEGAL_FORMS_LABELS,
  COMPANY_SIZES,
  COMPANY_SIZES_LABELS,
  TURNOVER,
  TURNOVER_LABELS,
  BENEFIT,
  BENEFIT_LABELS,
} from "../state/constants";
import useAppState from "../state/useAppState";
import { RowTwoColumns } from "../components/form/FormRow";
import NoticeArea from "../components/NoticeArea";
import { MainSection } from "../components/Sections";
import { OnboardingBusinessBreadcrumbs } from "../components/OnboardingBreadcrumbs";
import { LINKS } from "../utils/constants";

export default observer(function FormBusinessInfo() {
  const history = useHistory();
  const { loadingFromTreezor, adminLoggedIn, metaTreezor } = useAppState();
  const onboarding = useOnboarding();

  const disableChanges =
    loadingFromTreezor ||
    onboarding.status === "closed" ||
    (!adminLoggedIn &&
      !!metaTreezor.metaEntries.get("business")?.wasCreatedOnTreezor);

  return (
    <MainSection>
      <OnboardingBusinessBreadcrumbs />

      {disableChanges ? (
        <NoticeArea>
          <p>
            Vos informations ont déjà été transmises, et ne peuvent plus être
            modifiées ici.
          </p>
          <p>
            <a href={LINKS.contact} target="_blank">
              Contactez-nous en cas de questions ou de changements à effectuer
            </a>
          </p>
        </NoticeArea>
      ) : (
        <NoticeArea>
          <p>
            Merci de saisir les informations légales de votre commerce. Elles
            sont publiques et accessibles depuis votre KBIS.
          </p>
          <p>
            <a href={LINKS.kbis} target="_blank">
              Où trouver mon KBIS ?
            </a>
          </p>
        </NoticeArea>
      )}

      <form>
        <RowTwoColumns>
          <InputText
            id="legalName"
            label="Raison sociale"
            tooltipText="Le nom de votre entreprise tel qu'il apparaît sur le KBIS"
            placeholder="LocallyPay"
            value={onboarding.businessInfo.legalName}
            onChange={onboarding.businessInfo.setLegalName}
            disabled={disableChanges}
          />

          <InputText
            id="SIREN"
            label="SIREN"
            tooltipText="Les 9 chiffres qui forment le SIREN permettent à l'administration publique d'identifier votre entreprise. Pour retrouver facilement votre SIREN, rendez-vous sur le site infogreffe (https://www.infogreffe.fr)"
            placeholder="901 240 630"
            value={onboarding.businessInfo.SIREN}
            onChange={onboarding.businessInfo.setSIREN}
            errorMessages={onboarding.businessInfo.SIRENErrors}
            disabled={disableChanges}
          />
        </RowTwoColumns>

        <RowTwoColumns>
          <InputDate
            id="legalRegistrationDate"
            label="Date d'immatriculation"
            tooltipText="Date effective à laquelle votre entreprise a été créée"
            placeholder="901 240 630"
            value={onboarding.businessInfo.legalRegistrationDate}
            onChange={onboarding.businessInfo.setDate}
            errorMessages={onboarding.businessInfo.dateErrors}
            disabled={disableChanges}
          />

          <Select
            id="legalForm"
            label="Forme juridique"
            value={onboarding.businessInfo.legalForm}
            onChange={onboarding.businessInfo.setLegalForm}
            options={[...LEGAL_FORMS]}
            optionLabels={LEGAL_FORMS_LABELS}
            disabled={disableChanges}
          />
        </RowTwoColumns>

        <RowTwoColumns>
          <InputNumber
            id="capital"
            label="Capital social"
            tooltipText="Montant total des apports de biens et d'argent réalisés par les associés en contrepartie de droits sociaux"
            placeholder="37 000€"
            value={onboarding.businessInfo.capital}
            onChange={onboarding.businessInfo.setCapital}
            errorMessages={onboarding.businessInfo.capitalErrors}
            disabled={disableChanges}
          />

          <InputAddress
            id="address"
            label="Adresse du siège"
            tooltipText="Correspond à l'adresse administrative de la société. Il est obligatoirement fixé dans les statuts. Autrement dit, c'est l'adresse &quot;officielle&quot; qui figurera sur votre extrait KBIS"
            placeholder="28 Rue René Barthélémy, Antony, France"
            value={onboarding.businessInfo.address}
            onChange={onboarding.businessInfo.address.updateDetails}
            errorMessages={onboarding.businessInfo.addressErrors}
            disabled={disableChanges}
          />
        </RowTwoColumns>

        <RowTwoColumns>
          <InputText
            id="email"
            label="Email de contact"
            placeholder="alexandre@locallypay.com"
            value={onboarding.businessInfo.email}
            onChange={onboarding.businessInfo.setEmail}
            errorMessages={onboarding.businessInfo.emailErrors}
            disabled={disableChanges}
          />

          <InputPhone
            id="phoneNumber"
            label="Numéro de téléphone principal"
            placeholder="06 00 00 00 00"
            value={onboarding.businessInfo.phoneNumber}
            onChange={onboarding.businessInfo.setPhoneNumber}
            errorMessages={onboarding.businessInfo.phoneErrors}
            disabled={disableChanges}
          />
        </RowTwoColumns>

        <RowTwoColumns>
          <Select
            id="companySize"
            label="Nombre d'employés / Effectif"
            value={onboarding.businessInfo.companySize}
            onChange={onboarding.businessInfo.setCompanySize}
            options={[...COMPANY_SIZES]}
            optionLabels={COMPANY_SIZES_LABELS}
            disabled={disableChanges}
          />

          <InputText
            id="NAFCode"
            label="Secteur d'activité / Code NAF"
            tooltipText="Un secteur regroupe des entreprises de fabrication, de commerce ou de service qui ont la même activité principale. Ce code est disponible sur votre KBIS"
            placeholder="8299Z"
            value={onboarding.businessInfo.NAFCode}
            onChange={onboarding.businessInfo.setNAFCode}
            errorMessages={onboarding.businessInfo.NAFErrors}
            disabled={disableChanges}
          />
        </RowTwoColumns>

        <RowTwoColumns>
          <InputText
            id="VATCode"
            label="Numéro de TVA"
            tooltipText="Le numéro de TVA intracommunautaire est un numéro d'identification individuel attribué aux entreprises assujetties à la TVA et domiciliées au sein de l'Union européenne. Il est constitué du code FR et de 11 chiffres (clé informatique de 2 chiffres et numéro SIREN à 9 chiffres de l'entreprise)"
            placeholder="FR 65901240630"
            value={onboarding.businessInfo.VATCode}
            onChange={onboarding.businessInfo.setVATCode}
            errorMessages={onboarding.businessInfo.VATErrors}
            disabled={disableChanges}
          />

          <Select
            id="turnover"
            label="Chiffre d'affaires annuel"
            tooltipText="Votre chiffre d'affaire du bilan précédent tel que vous êtes tenu de le déclarer chaque année"
            value={onboarding.businessInfo.turnover}
            onChange={onboarding.businessInfo.setTurnover}
            options={[...TURNOVER]}
            optionLabels={[...TURNOVER_LABELS]}
            disabled={disableChanges}
          />
        </RowTwoColumns>

        <RowTwoColumns>
          <Select
            id="benefit"
            label="Résultat/Bénéfice"
            tooltipText="Sélectionner la tranche correspondant à votre résultat de l'exercice précédent. Ces données sont publiées au journal officiel chaque année"
            value={onboarding.businessInfo.benefit}
            onChange={onboarding.businessInfo.setBenefit}
            options={[...BENEFIT]}
            optionLabels={[...BENEFIT_LABELS]}
            disabled={disableChanges}
          />
        </RowTwoColumns>

        <DropzoneItem
          id="KBISId"
          label="Fichier KBIS"
          subLabel="Important: il doit dater de moins de 3 mois"
          linkText="Téléchargez-le gratuitement ici"
          linkUrl={LINKS.kbis}
          tooltipText="Sélectionner la tranche correspondant à votre résultat de l'exercice précédent. Ces données sont publiées au journal officiel chaque année"
          references={onboarding.businessInfo.KBISId}
          updateReference={onboarding.businessInfo.addKBISRef}
          deleteReference={onboarding.businessInfo.deleteKBISRef}
          disabled={disableChanges}
        />

        <RowTwoColumns>
          <InputText
            id="IBAN"
            label="IBAN de votre compte commerce"
            tooltipText="Ça c'est pour qu'on puisse vous faire des virements instantannés des chèques cadeaux encaissés !"
            placeholder="FR00 1234 5678 1324 6543 7654 890"
            value={onboarding.businessInfo.IBAN}
            onChange={onboarding.businessInfo.setIBAN}
            errorMessages={onboarding.businessInfo.IBANErrors}
            disabled={disableChanges}
          />

          <InputText
            id="SWIFT"
            label="Code SWIFT de votre compte commerce"
            tooltipText="Ça c'est pour qu'on puisse vous faire des virements instantannés des chèques cadeaux encaissés !"
            placeholder="QNTPOFR15253"
            value={onboarding.businessInfo.SWIFT}
            onChange={onboarding.businessInfo.setSWIFT}
            errorMessages={onboarding.businessInfo.SWIFTErrors}
            disabled={disableChanges}
          />
        </RowTwoColumns>

        <Checkbox
          id="noActivityOutsideEU"
          label="Activités commerciales hors UE"
          checkboxText="Je certifie que ce commerce n'opère pas d'activités hors de l'Union Européenne"
          value={onboarding.businessInfo.noActivityOutsideEU}
          onChange={onboarding.businessInfo.setNoActivityOutsideEU}
          errorMessages={onboarding.businessInfo.noActivityOutsideEUErrors}
          disabled={disableChanges}
        />

        <Checkbox
          id="noFinancialActivity"
          label="Activités financières"
          checkboxText="Je certifie que ce commerce ne gère pas d'activités financières de type: dépôts de titres, investissements, assurances"
          value={onboarding.businessInfo.noFinancialActivity}
          onChange={onboarding.businessInfo.setNoFinancialActivity}
          errorMessages={onboarding.businessInfo.noFinancialActivityErrors}
          disabled={disableChanges}
        />

        <Checkbox
          id="acceptCGV"
          label="Conditions générales"
          checkboxText={
            <>
              J'accepte les&nbsp;
              <a
                href="https://www.locallypay.com/pages/cgv"
                rel="noopener noreferrer"
                target="_blank"
              >
                conditions générales de vente (CGV)
              </a>
              &nbsp;de LocallyPay
            </>
          }
          value={onboarding.businessInfo.acceptCGV}
          onChange={onboarding.businessInfo.setAcceptCGV}
          errorMessages={onboarding.businessInfo.acceptCGVErrors}
          disabled={disableChanges}
        />

        <FormStatus
          isLoading={loadingFromTreezor}
          modelData={onboarding.businessInfo}
          disabled={disableChanges}
        />

        <ButtonBar>
          <ButtonItem
            text={disableChanges ? "Retour au résumé" : "Valider"}
            onClick={() => {
              history.push(`/onboarding/${onboarding.id}/dashboard`);
            }}
          ></ButtonItem>
        </ButtonBar>
      </form>
    </MainSection>
  );
});
