import styled, { css } from "styled-components/macro";
import { Link } from "react-router-dom";

const ButtonItemContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  gap: 1rem;
  margin-bottom: 2rem;
  margin-top: 2rem;
`;

const ButtonStyle = css`
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 500px;
  font-size: var(--font-size-normal);
  font-weight: bold;
  color: white;
  background: var(--color-blue);
  cursor: pointer;
  white-space: nowrap;
  &:disabled {
    background: var(--color-gray-light);
    color: var(--color-blue);
    cursor: not-allowed;
  }
`;

const Button = styled.button`
  ${ButtonStyle}
`;

export const ButtonLink = styled(Link)<{ disabled?: boolean }>`
  ${ButtonStyle}

  display: block;
  width: fit-content;
  white-space: nowrap;
  text-decoration: none;

  ${({ disabled }) =>
    disabled &&
    `${"background: var(--color-gray-light);"} ${"color: var(--color-blue);"} ${"cursor: not-allowed;"}`}
`;

export const ButtonBar = function ({
  children,
}: {
  children: React.ReactNode;
}) {
  return <ButtonItemContainer>{children}</ButtonItemContainer>;
};

export const ButtonItem = function ({
  text,
  onClick,
  disabled = false,
}: {
  text: string;
  onClick: (e: React.MouseEvent<HTMLElement>) => void;
  disabled?: boolean;
}) {
  return (
    <Button disabled={disabled} onClick={onClick} type="button">
      {text}
    </Button>
  );
};
