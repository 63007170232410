import {
  Instance,
  types,
  getParent,
  addDisposer,
  applySnapshot,
} from "mobx-state-tree";
import { autorun } from "mobx";
import { getFirestore, collection, onSnapshot } from "firebase/firestore";

const MetaEntry = types
  .model("MetaEntry", {
    onboardingRequestId: types.maybe(types.string),
    userId: types.maybe(types.number),
    businessInfoUserId: types.maybe(types.number),
    personUserId: types.maybe(types.number),
    personId: types.maybe(types.string),
    livenessStatus: types.maybe(
      types.union(
        types.literal("NOT STARTED"),
        types.literal("STARTED"),
        types.literal("SUCCESS"),
        types.literal("FAILED"),
      ),
    ),
    livenessUrl: types.maybe(types.string),
  })
  .views((self) => ({
    get wasCreatedOnTreezor() {
      return !!self.userId;
    },
    get hasValidLivenessUrl() {
      // If a livenessUrl doesn't exist OR
      // the livenessStatus is FAILED,
      // generate a new url
      if (self.livenessUrl === undefined || self.livenessStatus === "FAILED")
        return false;
      else return true;
    },
  }));
export interface MetaEntryType extends Instance<typeof MetaEntry> {}

const MetaTreezor = types
  .model("MetaTreezor", {
    metaEntries: types.map(MetaEntry),
  })
  .views((self) => ({
    get onboardingId() {
      return (getParent(self) as any)?.businessOnboarding?.id;
    },
  }))
  .actions((self) => ({
    update(snapshot: { empty: boolean; docs: any[] }) {
      const metaEntriesMap: { [id: string]: MetaEntryType } = {};
      snapshot.docs.forEach((doc) => (metaEntriesMap[doc.id] = doc.data()));

      applySnapshot(self, { metaEntries: metaEntriesMap });
    },
  }))
  .actions((self) => {
    let stopSnapshot: null | (() => void) = null;

    return {
      stopListening() {
        if (stopSnapshot) stopSnapshot();
        stopSnapshot = null;
      },
      startListening() {
        this.stopListening();

        const db = getFirestore();
        const docref = collection(
          db,
          "onboardingRequests",
          self.onboardingId,
          "onboardingMeta",
        );

        stopSnapshot = onSnapshot(docref, self.update, (e) => console.log(e));
      },
    };
  })
  .actions((self) => ({
    afterAttach() {
      // this starts and stops to listen when the onboarding id changes
      const disposeRun = autorun(() => {
        if (self.onboardingId) self.startListening();
      });

      addDisposer(self, () => {
        self.stopListening();
        disposeRun();
      });
    },
  }));
export default MetaTreezor;
