import { ReactNode, useEffect } from "react";
import styled from "styled-components/macro";
import { observer } from "mobx-react";

import { BREAKPOINTS, LINKS } from "../utils/constants";
import { MainSection, MainSectionBlue } from "../components/Sections";
import { ButtonItem } from "../components/form/FormButtons";
import useAppState from "../state/useAppState";
import useOnboarding from "../state/useOnboarding";
import { sendOnboardingToTreezor } from "../backend";
import useToaster from "../hooks/useToaster";
import { BlueText } from "../theme";
import useNavigation from "../hooks/useNavigation";
import Spinner from "../components/Spinner";
import { PersonType } from "../state/model";
import useModal from "../hooks/useModal";

const TitleContainer = styled.div`
  display: flex;
  align-items: flex-end;
  gap: 20px;
  margin-bottom: 2rem;

  img {
    width: min(10vw, 100px);
  }

  @media (max-width: ${BREAKPOINTS.mobile}px) {
    flex-direction: column;
    align-items: center;

    img {
      width: 20vw;
    }
  }
`;
const Title = styled.h1`
  margin: 0;
  line-height: 1.3;
  font-size: var(--font-size-bigger);

  text-transform: uppercase;
  font-weight: bold;

  span {
    padding: 0 1rem;
    box-decoration-break: clone;
    -webkit-box-decoration-break: clone;
    background-color: var(--color-blue);
    color: var(--color-white);
  }
`;
const BigParagraph = styled.p`
  font-size: var(--font-size-normal-plus);
  font-weight: bold;
  line-height: 1.2em;
  text-transform: uppercase;

  span {
    color: var(--color-green);
  }
`;
const GridList = styled.ul`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  padding: 0;
  list-style-type: none;

  li {
    display: flex;
    gap: 10px;
    align-items: center;
    font-size: var(--font-size-normal);
  }
  img {
    width: 60px;
  }
  a {
    color: var(--color-green);
  }
  @media (max-width: ${BREAKPOINTS.mobile}px) {
    grid-template-columns: 1fr;
  }
`;
const Subtitle = styled.h2`
  font-size: var(--font-size-big);
  font-weight: bold;
  text-transform: uppercase;
  text-align: center;

  span {
    color: var(--color-green);
  }
`;
const StatusContainer = styled.div`
  margin-bottom: 3rem;
`;
const StatusTitle = styled.h3`
  width: fit-content;
  padding: 0.5rem;
  border-radius: 4px;
  font-size: var(--font-size-normal-plus);
  font-weight: bold;
  border: 1px solid var(--color-blue);
  color: var(--color-blue);
  background: var(--color-blue-vlight);
  text-transform: uppercase;
`;
const ActionContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;

  > div {
    display: flex;
    align-items: center;
  }
`;
const InformationParagraph = styled.p<{ error: boolean }>`
  font-weight: bold;
  color: ${({ error }) => (error ? "var(--color-red)" : "var(--color-blue)")};
`;
const StatusDetails = styled.div`
  margin: 1rem 0;

  ul {
    margin-top: -0.5rem;
    padding-left: 0.5rem;
    list-style-type: none;
  }
  li {
    display: flex;
    align-items: center;
    margin-bottom: 0.2rem;
  }

  img {
    width: 20px;
  }

  button {
    margin: 0;
    padding: 0;
    border: none;
    background: none;
    color: var(--color-blue);
    font-size: var(--font-size-normal-plus);
    font-weight: normal;
    text-decoration: underline;
    cursor: pointer;
  }
`;
const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  color: var(--color-blue);

  img {
    align-self: center;
    width: 20vh;
  }

  h3 {
    margin-bottom: 0.5rem;
    font-size: var(--font-size-big);
    text-transform: uppercase;
  }
`;

const StatusInformation = ({
  children,
  icon,
}: {
  children: ReactNode;
  icon?: "success" | "error";
}) => {
  return (
    <div>
      {icon === "error" && <img src="/icon_error.png" alt="icône erreur" />}
      {icon === "success" && <img src="/icon_success.png" alt="icône succès" />}
      <InformationParagraph error={icon === "error"}>
        {children}
      </InformationParagraph>
    </div>
  );
};

const StatusCommerce = observer(() => {
  const { navigateToTop } = useNavigation();
  const { businessSentToTreezor, loadingFromTreezor } = useAppState();
  const { businessInfo } = useOnboarding();

  let informationMessage =
    "Les informations saisies sont correctes, vous pouvez passer à l'étape suivante.";
  let informationIcon: "error" | "success" | undefined = "success";

  if (!businessInfo.legalName) {
    informationMessage = "";
    informationIcon = undefined;
  } else if (!businessInfo.isValid) {
    informationMessage =
      "Le formulaire n’a pas été correctement rempli. Merci de corriger les erreurs";
    informationIcon = "error";
  } else if (businessSentToTreezor) {
    informationMessage =
      "Nous avons bien reçu les informations du commerce\u00a0!";
    informationIcon = "success";
  }

  return (
    <StatusContainer>
      <StatusTitle>01. Votre commerce</StatusTitle>
      <p>
        Les informations légales de votre commerce pour l’enregistrement de
        l’entité comme bénéficiaire.
      </p>
      <ActionContainer>
        <ButtonItem
          text={businessSentToTreezor ? "Voir les informations" : "Démarrer"}
          onClick={() => navigateToTop("/commerce")}
          disabled={loadingFromTreezor}
        />
        <StatusInformation icon={informationIcon}>
          {informationMessage}
        </StatusInformation>
      </ActionContainer>
    </StatusContainer>
  );
});

const StatusRepLegal = observer(() => {
  const { navigateToTop } = useNavigation();
  const { legalRepSentToTreezor, loadingFromTreezor } = useAppState();
  const { legalRepresentative } = useOnboarding();

  let informationMessage =
    "Les informations saisies sont correctes, vous pouvez passer à l'étape suivante.";
  let informationIcon: "error" | "success" | undefined = "success";

  if (!legalRepresentative) {
    informationMessage = "";
    informationIcon = undefined;
  } else if (!legalRepresentative?.isValid) {
    informationMessage =
      "Le formulaire n’a pas été correctement rempli. Merci de corriger les erreurs";
    informationIcon = "error";
  } else if (legalRepSentToTreezor) {
    informationMessage =
      "Nous avons bien reçu les informations du représentant légal\u00a0!";
    informationIcon = "success";
  }

  return (
    <StatusContainer>
      <StatusTitle>02. Le représentant légal</StatusTitle>
      <p>Les informations du représentant légal du commerce.</p>

      <StatusDetails>
        <p>
          <strong>Représentant légal:</strong>{" "}
          {legalRepresentative
            ? `${legalRepresentative.firstname} ${legalRepresentative.lastname}`
            : "pas encore saisi"}
        </p>
      </StatusDetails>

      <ActionContainer>
        <ButtonItem
          text={legalRepSentToTreezor ? "Voir les informations" : "Démarrer"}
          onClick={() => navigateToTop("/representant")}
          disabled={loadingFromTreezor}
        />

        <StatusInformation icon={informationIcon}>
          {informationMessage}
        </StatusInformation>
      </ActionContainer>
    </StatusContainer>
  );
});

const BeneficiaryDetails = ({ beneficiary }: { beneficiary: PersonType }) => {
  const { navigateToTop } = useNavigation();
  const { adminLoggedIn, onboardingFullySubmitted, businessOnboarding } =
    useAppState();
  const personCanBeDeleted =
    !beneficiary.isLegalRepresentative &&
    (adminLoggedIn || !onboardingFullySubmitted);

  return (
    <li>
      {beneficiary.isValid ? (
        <img src="/icon_success.png" alt="icône succès" />
      ) : (
        <img src="/icon_error.png" alt="icône erreur" />
      )}
      <span>
        {`${beneficiary.firstname ?? "inconnu"} ${beneficiary.lastname ?? ""}, 
      ${beneficiary.shareOfCapital ?? "0"}%`}{" "}
        (
        <button
          onClick={() => {
            beneficiary.isLegalRepresentative
              ? navigateToTop("/representant")
              : navigateToTop(`/beneficiaire/${beneficiary.id}`);
          }}
        >
          {beneficiary.isValid
            ? "voir les informations"
            : "corriger les informations"}
        </button>
        {personCanBeDeleted && (
          <>
            {" ou "}
            <button
              onClick={() => businessOnboarding?.deleteBeneficiary(beneficiary)}
            >
              supprimer
            </button>
          </>
        )}
      </span>
      )
    </li>
  );
};

const StatusBenefs = observer(() => {
  const { navigateToTop } = useNavigation();
  const {
    benefsSentToTreezor,
    adminLoggedIn,
    loadingFromTreezor,
    livenessPending,
    livenessSuccess,
  } = useAppState();
  const { beneficiaries, createBeneficiary } = useOnboarding();

  const beneficiariesAreValid = beneficiaries.every(
    (beneficiary) => beneficiary.isValid,
  );

  const totalShareOfCapital = beneficiaries
    .map((beneficiary) => beneficiary.shareOfCapital ?? 0)
    .reduce((acc, share) => acc + share, 0);

  const beneficiariesNames = beneficiaries.map((beneficiary) =>
    `${beneficiary.firstname} ${beneficiary.lastname}`.toLocaleLowerCase(),
  );
  const beneficiariesHaveDuplicatedNames =
    new Set(beneficiariesNames).size !== beneficiariesNames.length;

  let informationMessage =
    "Les informations saisies sont correctes, vous pouvez passer à l'étape suivante.";
  let informationIcon: "error" | "success" | undefined = "success";

  if (beneficiaries.length === 0) {
    informationMessage = "";
    informationIcon = undefined;
  } else if (!beneficiariesAreValid) {
    informationMessage =
      "Le formulaire n’a pas été correctement rempli. Merci de corriger les erreurs";
    informationIcon = "error";
  } else if (beneficiariesHaveDuplicatedNames) {
    informationMessage =
      "Le même bénéficiaire est listé plusieurs fois. Merci d'en supprimer un";
    informationIcon = "error";
  } else if (totalShareOfCapital <= 75) {
    informationMessage =
      "Le pourcentage de capital est en dessous de 75%. Si il manque un bénéficiaire détenant au moins 25% du capital, merci de l'ajouter.";
    informationIcon = "success";
  } else if (benefsSentToTreezor) {
    informationMessage =
      "Nous avons bien reçu les informations des bénéficiaires\u00a0!";
    informationIcon = "success";
  }

  const addNewBeneficiaryHandler = () => {
    const { id: newBenefId } = createBeneficiary();
    navigateToTop(`/beneficiaire/${newBenefId}`);
    return;
  };

  return (
    <StatusContainer>
      <StatusTitle>03. Les bénéficiaires</StatusTitle>
      <p>
        Les informations des associés du commerce.{" "}
        <BlueText>(Chaque personne détenant au moins 25% du capital.)</BlueText>
      </p>

      <StatusDetails>
        <p>
          <strong>Bénéficiaire(s):</strong>
        </p>
        <ul>
          {beneficiaries.length > 0
            ? beneficiaries.map((beneficiary) => (
                <BeneficiaryDetails
                  key={beneficiary.id}
                  beneficiary={beneficiary}
                />
              ))
            : "pas encore saisi"}
        </ul>
      </StatusDetails>

      <ActionContainer>
        <ButtonItem
          text={
            beneficiaries.length > 0 ? "Ajouter un bénéficiaire" : "Démarrer"
          }
          onClick={addNewBeneficiaryHandler}
          disabled={
            (!adminLoggedIn && benefsSentToTreezor) ||
            loadingFromTreezor ||
            livenessPending ||
            livenessSuccess
          }
        />
        <StatusInformation icon={informationIcon}>
          {informationMessage}
        </StatusInformation>
      </ActionContainer>
    </StatusContainer>
  );
});

const StatusValidation = observer(() => {
  const { toastError } = useToaster();
  const { navigateToTop } = useNavigation();
  const {
    livenessSuccess,
    livenessPending,
    livenessFailed,
    loadingFromTreezor,
    setLoadingFromTreezor,
  } = useAppState();
  const {
    id: onboardingId,
    isValid: onboardingIsValid,
    businessInfo,
    legalRepresentative,
    beneficiaries,
  } = useOnboarding();

  let informationMessage = "";
  let informationIcon: "error" | "success" | undefined = undefined;

  if (
    !businessInfo.legalName ||
    !legalRepresentative ||
    beneficiaries.length === 0
  ) {
    informationMessage = "";
    informationIcon = undefined;
  } else if (!onboardingIsValid) {
    informationMessage = "Merci de corriger ou compléter les étapes ci-dessus";
    informationIcon = "error";
  } else if (livenessSuccess) {
    informationMessage = "Votre identité a été correctement identifiée\u00a0!";
    informationMessage = informationIcon = "success";
  } else if (livenessFailed) {
    informationMessage =
      "La vérification d'identité a échoué. Merci de la relancer";
    informationIcon = "error";
  }

  const handleOnboardingSubmission =
    async function handleOnboardingSubmission() {
      setLoadingFromTreezor(true);
      const response = await sendOnboardingToTreezor(onboardingId);
      setLoadingFromTreezor(false);

      if (response.success) {
        navigateToTop("/liveness");
      } else {
        toastError(
          `Erreur lors de l'envoi des données: ${response.error_type}`,
          true,
        );
      }
    };

  return (
    <StatusContainer>
      <StatusTitle>04. Validation de votre identité</StatusTitle>
      <p>
        Vérification de l'identité du{" "}
        <BlueText>
          représentant légal{" "}
          {legalRepresentative?.firstname
            ? `(${legalRepresentative.firstname} 
          ${legalRepresentative.lastname})`
            : ""}
        </BlueText>{" "}
        par système Liveness.
      </p>
      <ActionContainer>
        <ButtonItem
          text={
            loadingFromTreezor ? "Envoi des données en cours..." : "Démarrer"
          }
          onClick={handleOnboardingSubmission}
          disabled={
            !onboardingIsValid ||
            loadingFromTreezor ||
            livenessPending ||
            livenessSuccess
          }
        />
        {loadingFromTreezor && <Spinner />}
        <StatusInformation icon={informationIcon}>
          {informationMessage}
        </StatusInformation>
      </ActionContainer>
    </StatusContainer>
  );
});

const PendingModalContent = (
  <ModalContent>
    <img src="/illu_hourglass.svg" alt="illustration de sablier" />
    <h3>Merci&nbsp;!</h3>
    <p>
      Nos équipes vont vérifier les informations et revenir vers vous au plus
      vite.
    </p>
  </ModalContent>
);

const Dashboard = observer(() => {
  const { livenessPending } = useAppState();
  const { Modal: PendingModal, setModalDisplayed } =
    useModal(PendingModalContent);

  useEffect(() => {
    if (livenessPending) {
      setModalDisplayed(true);
    }
  }, [setModalDisplayed, livenessPending]);

  return (
    <>
      <PendingModal />

      <MainSection>
        <TitleContainer>
          <img src="/illu_coins_jar.svg" alt="illustration de tirelire" />
          <Title>
            <span>
              Félicitations, vous avez encaissé vos premiers montants
              LocallyPay&nbsp;!
            </span>
          </Title>
        </TitleContainer>

        <p>
          Vous arrivez donc à la dernière étape de création de compte virtuel
          qui vous permettra d’effectuer les virements des fonds vers votre
          compte bancaire.
        </p>
        <p>
          En tant qu’agent bancaire, nous devons fournir des informations
          légales à la Banque de France via notre partenaire bancaire Treezor
          qui doit valider votre identité et celle de votre commerce.
        </p>
        <p>
          Ces informations sont à saisir une seule fois et vous ouvre ensuite la
          possibiltié de virer les fonds recupérés via LocallyPay.
        </p>
        <p>
          Les informations que vous transmettez sont sécurisés et LocallyPay est
          agrémenté dans la protection des données, la sécurité du stockage et
          la privatisation des données. En savoir plus en consultant nos CGU
        </p>
      </MainSection>

      <MainSectionBlue>
        <BigParagraph>
          Nous allons vous accompagner dans ce processus pour être le plus
          efficace possible.
          <br />
          <span>
            Assurez-vous d’avoir à votre disposition les éléments suivants :
          </span>
        </BigParagraph>
        <GridList>
          <li>
            <img src="/illu_clock.svg" alt="illustration de montre" />
            <p>5 minutes de disponible</p>
          </li>
          <li>
            <img src="/illu_paper.svg" alt="illustration de documents" />
            <span>Un RIB du compte de votre commerce</span>
          </li>
          <li>
            <img src="/illu_paper.svg" alt="illustration de documents" />
            <span>
              Un KBIS de moins de trois mois
              <br />
              <a href={LINKS.kbis} target="_blank">
                Télécharger-le gratuitement ici
              </a>
            </span>
          </li>
          <li>
            <img src="/illu_id.svg" alt="illustration de carte d'identité" />
            <span>Une pièce d’identité à jour</span>
          </li>
        </GridList>
      </MainSectionBlue>

      <MainSection>
        <Subtitle>
          Prêt&nbsp;? <span>On y va&nbsp;!</span>
        </Subtitle>

        <StatusCommerce />

        <StatusRepLegal />

        <StatusBenefs />

        <StatusValidation />
      </MainSection>
    </>
  );
});

export default Dashboard;
