import { observer } from "mobx-react";

import Spinner from "../components/Spinner";
import { MainSection } from "../components/Sections";

export default observer(function Loading() {
  return (
    <MainSection centerContent>
      <h2>Chargement...</h2>
      <Spinner size={50} />
    </MainSection>
  );
});
