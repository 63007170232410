import toast from "react-hot-toast";
import { CustomToast } from "../components/Toast";

const useToaster = () => {
  const toastSuccess = (message: string) => {
    toast.success(<CustomToast>{message}</CustomToast>);
  };

  const toastError = (message: string, linkToDashboard?: boolean) => {
    toast.error(
      <CustomToast linkToDashboard={linkToDashboard}>{message}</CustomToast>,
    );
  };

  return { toastSuccess, toastError };
};
export default useToaster;
