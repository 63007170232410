import { types, flow, onSnapshot, Instance } from "mobx-state-tree";
import * as Sentry from "@sentry/react";
import BusinessOnboarding from "./model";
import BusinessCreation from "./businessCreationModel";
import MetaTreezor from "./metaModel";

import {
  getFirestore,
  getDoc,
  setDoc,
  doc,
  serverTimestamp,
} from "firebase/firestore";

const cleanUndefined = (v: any) => JSON.parse(JSON.stringify(v));

const AppState = types
  .model("AppState", {
    businessCreation: types.maybe(BusinessCreation),
    businessOnboarding: types.maybe(BusinessOnboarding),
    metaTreezor: types.optional(MetaTreezor, {}),
    adminLoggedIn: types.optional(types.boolean, false),
  })
  .volatile(() => ({
    loading: false,
    loadingFromTreezor: false,
    notFound: false,
  }))
  .views((self) => ({
    get ready() {
      return !self.loading && !self.notFound && !!self.businessOnboarding;
    },
    get onboardingFullySubmitted() {
      if (!self.businessOnboarding) {
        return false;
      }

      const metaEntries = self.metaTreezor.metaEntries;
      if (!metaEntries) return false;

      return (
        !!metaEntries.get("business")?.wasCreatedOnTreezor &&
        !!self.businessOnboarding.people.every(
          (person) => metaEntries.get(person.id)?.wasCreatedOnTreezor,
        )
      );
    },
    get businessSentToTreezor() {
      const metaEntries = self.metaTreezor.metaEntries;
      return !!metaEntries.get("business")?.wasCreatedOnTreezor;
    },
    get legalRepSentToTreezor() {
      if (
        !self.businessOnboarding ||
        !self.businessOnboarding.legalRepresentative
      ) {
        return false;
      }

      const legalRepId = self.businessOnboarding.legalRepresentative.id;
      const metaEntries = self.metaTreezor.metaEntries;
      return metaEntries.get(legalRepId)?.wasCreatedOnTreezor;
    },
    get benefsSentToTreezor() {
      if (!self.businessOnboarding) {
        return false;
      }

      const benefsIds = self.businessOnboarding.beneficiaries.map(
        (beneficiary) => beneficiary.id,
      );

      const metaEntries = self.metaTreezor.metaEntries;
      return benefsIds.every(
        (benefId) => metaEntries.get(benefId)?.wasCreatedOnTreezor,
      );
    },
    get livenessSuccess() {
      const legalRepId = self.businessOnboarding?.legalRepresentative?.id;
      const metaEntries = self.metaTreezor.metaEntries;
      if (!metaEntries || !legalRepId) return false;

      return metaEntries.get(legalRepId)?.livenessStatus === "SUCCESS";
    },
    get livenessPending() {
      const legalRepId = self.businessOnboarding?.legalRepresentative?.id;
      const metaEntries = self.metaTreezor.metaEntries;
      if (!metaEntries || !legalRepId) return false;

      return metaEntries.get(legalRepId)?.livenessStatus === "STARTED";
    },
    get livenessFailed() {
      const legalRepId = self.businessOnboarding?.legalRepresentative?.id;
      const metaEntries = self.metaTreezor.metaEntries;
      if (!metaEntries || !legalRepId) return false;

      return metaEntries.get(legalRepId)?.livenessStatus === "FAILED";
    },
  }))
  .actions((self) => ({
    setLoadingFromTreezor(val: boolean) {
      self.loadingFromTreezor = val;
    },
    setAdminLoggedIn(val: boolean) {
      self.adminLoggedIn = val;
    },
  }))
  .extend((self) => {
    let stopCurrentListener: null | Function = null;

    return {
      actions: {
        loadOnboardingFromDB: flow(function* loadOnboardingFromDB(
          businessOnboardingId,
        ) {
          if (
            self.businessOnboarding &&
            businessOnboardingId === self.businessOnboarding.id
          )
            return;

          if (
            self.businessOnboarding &&
            businessOnboardingId !== self.businessOnboarding.id &&
            stopCurrentListener
          ) {
            stopCurrentListener();
            delete self.businessOnboarding;
          }

          self.loading = true;
          const db = getFirestore();

          try {
            const dbSnap = yield getDoc(
              doc(db, "onboardingRequests", businessOnboardingId),
            );

            self.loading = false;

            if (!dbSnap.exists()) {
              self.notFound = true;
              return;
            }

            self.notFound = false;

            self.businessOnboarding = BusinessOnboarding.create({
              ...dbSnap.data(),
              id: dbSnap.id,
            });

            const { status, createdAt, businessId } = dbSnap.data();

            stopCurrentListener = onSnapshot(
              self.businessOnboarding,
              (snapshot) =>
                setDoc(
                  doc(db, "onboardingRequests", businessOnboardingId),
                  {
                    ...cleanUndefined({
                      ...snapshot,
                      status,
                      businessId,
                    }),
                    createdAt: createdAt || serverTimestamp(),
                    updatedAt: serverTimestamp(),
                  },
                  { merge: true },
                ),
            );
          } catch (e) {
            Sentry.captureException(e);
            console.error(e);
            self.notFound = true;
            self.loading = false;
          }
        }),
      },
    };
  });

export default AppState;

export interface AppStateInterface extends Instance<typeof AppState> {}
