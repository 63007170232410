import { useEffect } from "react";
import { observer } from "mobx-react";
import { useHistory } from "react-router-dom";

import NoticeArea from "../components/NoticeArea";
import {
  InputText,
  InputDate,
  InputNumber,
  InputPhone,
  InputAddress,
  Select,
  Checkbox,
} from "../components/form/FormItems";
import FormStatus from "../components/form/FormStatus";
import DropzoneItem from "../components/form/DropzoneItem";
import { ButtonBar, ButtonItem } from "../components/form/FormButtons";
import useOnboarding from "../state/useOnboarding";
import {
  COUNTRY,
  COUNTRY_LABELS,
  INCOME_RANGE,
  INCOME_RANGE_LABELS,
  PERSONAL_ASSETS,
  PERSONAL_ASSETS_LABELS,
} from "../state/constants";
import useAppState from "../state/useAppState";
import { RowTwoColumns } from "../components/form/FormRow";
import { MainSection } from "../components/Sections";
import { OnboardingLegalRepBreadcrumbs } from "../components/OnboardingBreadcrumbs";
import { LINKS } from "../utils/constants";

export default observer(function FormLegalRep() {
  const history = useHistory();
  const { loadingFromTreezor, adminLoggedIn, metaTreezor } = useAppState();
  const {
    id: onboardingId,
    status,
    legalRepresentative,
    createLegalRep,
  } = useOnboarding();

  useEffect(() => {
    if (!legalRepresentative) createLegalRep();
  }, [legalRepresentative, createLegalRep]);

  if (!legalRepresentative) return null;

  const disableChanges =
    loadingFromTreezor ||
    status === "closed" ||
    (!adminLoggedIn &&
      !!metaTreezor.metaEntries.get(legalRepresentative.id)
        ?.wasCreatedOnTreezor);

  return (
    <MainSection>
      <OnboardingLegalRepBreadcrumbs />

      {disableChanges ? (
        <NoticeArea>
          <p>
            Vos informations ont déjà été transmises, et ne peuvent plus être
            modifiées ici.
          </p>
          <p>
            <a href={LINKS.contact} target="_blank">
              Contactez-nous en cas de questions ou de changements à effectuer
            </a>
          </p>
        </NoticeArea>
      ) : (
        <NoticeArea>
          <p>
            Merci de saisir les informations concernant le représentant légal du
            commerce.
          </p>
        </NoticeArea>
      )}

      {legalRepresentative && (
        <form>
          <Checkbox
            id="isBeneficiary"
            checkboxText="Le représentant légal est également un bénéficiare de l’entreprise (il détient plus de 25% de l'entreprise)"
            value={legalRepresentative.isBeneficiary}
            onChange={legalRepresentative.setBeneficiary}
            disabled={disableChanges}
          />
          <RowTwoColumns>
            <InputText
              id="firstname"
              label="Prénom"
              placeholder="Alexandre"
              value={legalRepresentative.firstname}
              onChange={legalRepresentative.setFirstname}
              disabled={disableChanges}
            />

            <InputText
              id="lastname"
              label="Nom"
              placeholder="Tilleman"
              value={legalRepresentative.lastname}
              onChange={legalRepresentative.setLastname}
              disabled={disableChanges}
            />
          </RowTwoColumns>
          <RowTwoColumns>
            <InputDate
              id="birthday"
              label="Date de naissance"
              value={legalRepresentative.birthday}
              onChange={legalRepresentative.setBirthday}
              disabled={disableChanges}
            />

            <InputAddress
              id="placeOfBirth"
              label="Lieu de naissance"
              placeholder="L'Haye les Roses, France"
              precision="(regions)"
              value={legalRepresentative.placeOfBirth}
              onChange={legalRepresentative.placeOfBirth.updateDetails}
              errorMessages={legalRepresentative.placeOfBirthErrors}
              disabled={disableChanges}
            />
          </RowTwoColumns>
          <RowTwoColumns>
            <Select
              id="nationality"
              label="Nationalité"
              value={legalRepresentative.nationality}
              onChange={legalRepresentative.setNationality}
              options={[...COUNTRY]}
              optionLabels={COUNTRY_LABELS}
              disabled={disableChanges}
            />
          </RowTwoColumns>
          <RowTwoColumns>
            <InputText
              id="email"
              label="Email"
              placeholder="alexandre@locallypay.com"
              value={legalRepresentative.email}
              onChange={legalRepresentative.setEmail}
              errorMessages={legalRepresentative.emailErrors}
              disabled={disableChanges}
            />

            <InputPhone
              id="phoneNumber"
              label="Numéro de téléphone"
              placeholder="06 00 00 00 00"
              value={legalRepresentative.phoneNumber}
              onChange={legalRepresentative.setPhoneNumber}
              errorMessages={legalRepresentative.phoneErrors}
              disabled={disableChanges}
            />
          </RowTwoColumns>
          <InputAddress
            id="address"
            label="Adresse principale"
            placeholder="28 Rue René Barthélémy, Antony, France"
            value={legalRepresentative.address}
            onChange={legalRepresentative.address.updateDetails}
            errorMessages={legalRepresentative.addressErrors}
            disabled={disableChanges}
          />

          <RowTwoColumns>
            <Select
              id="incomeRange"
              label="Revenus"
              tooltipText="Sélectionner la tranche de revenu qui correspond à votre activité"
              value={legalRepresentative.incomeRange}
              onChange={legalRepresentative.setIncomeRange}
              options={[...INCOME_RANGE]}
              optionLabels={[...INCOME_RANGE_LABELS]}
              disabled={disableChanges}
            />

            <Select
              id="personalAssets"
              label="Patrimoine"
              value={legalRepresentative.personalAssets}
              onChange={legalRepresentative.setPersonalAssets}
              options={[...PERSONAL_ASSETS]}
              optionLabels={[...PERSONAL_ASSETS_LABELS]}
              disabled={disableChanges}
            />
          </RowTwoColumns>
          <RowTwoColumns>
            <InputText
              id="position"
              label="Fonction / Description du poste"
              placeholder="Co-Fondateur"
              tooltipText="Décrivez simplement votre poste dans ce commerce"
              value={legalRepresentative.position}
              onChange={legalRepresentative.setPosition}
              disabled={disableChanges}
            />

            {legalRepresentative.isBeneficiary && (
              <InputNumber
                id="shareOfCapital"
                label="Pourcentage du capital détenu"
                placeholder="49.1"
                value={legalRepresentative.shareOfCapital}
                onChange={legalRepresentative.setShareOfCapital}
                errorMessages={legalRepresentative.shareOfCapitalErrors}
                disabled={disableChanges}
              />
            )}
          </RowTwoColumns>

          <Checkbox
            id="frenchFiscalResident"
            label="Résidence fiscale"
            checkboxText="Je certifie que je suis uniquement un résident fiscal français"
            value={legalRepresentative.frenchFiscalResident}
            onChange={legalRepresentative.setFrenchFiscalResident}
            errorMessages={legalRepresentative.frenchFiscalResidentErrors}
            disabled={disableChanges}
          />

          <Checkbox
            id="specifiedUSPerson"
            label="US Person"
            checkboxText="Je certifie que je ne suis pas une US Person"
            value={legalRepresentative.notUSPerson}
            onChange={legalRepresentative.setNotUSPerson}
            disabled={disableChanges}
          />
          {!legalRepresentative.notUSPerson && (
            <>
              <DropzoneItem
                id="NIFFormId"
                label="FATCA / W9 (Formulaire NIF)"
                references={legalRepresentative.NIFFormId}
                updateReference={legalRepresentative.addNIFFormRef}
                deleteReference={legalRepresentative.deleteNIFFormRef}
                disabled={disableChanges}
              />

              <RowTwoColumns>
                <InputText
                  id="taxNumber"
                  label="Tax identifier"
                  placeholder="12-3456789"
                  value={legalRepresentative.taxNumber}
                  onChange={legalRepresentative.setTaxNumber}
                  disabled={disableChanges}
                />
              </RowTwoColumns>
            </>
          )}

          <FormStatus
            isLoading={loadingFromTreezor}
            modelData={legalRepresentative}
            disabled={false}
          />

          <ButtonBar>
            <ButtonItem
              text={disableChanges ? "Retour au résumé" : "Valider"}
              onClick={() => {
                history.push(`/onboarding/${onboardingId}/dashboard`);
              }}
            ></ButtonItem>
          </ButtonBar>
        </form>
      )}
    </MainSection>
  );
});
