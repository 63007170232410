import React, { createContext, useContext } from "react";
import { AppStateInterface } from "./appState";

const AppStateContext = createContext<null | AppStateInterface>(null);

export const AppStateContextProvider = ({
  value,
  children,
}: React.PropsWithChildren<{
  value: AppStateInterface;
}>) => {
  return (
    <AppStateContext.Provider value={value}>
      {children}
    </AppStateContext.Provider>
  );
};

export default function useAppState() {
  const ctx = useContext(AppStateContext);
  if (!ctx) throw new Error("You need to initialise your app state");
  return ctx;
}
