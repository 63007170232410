import { types, Instance, getParentOfType, detach } from "mobx-state-tree";
import { validateIBAN, validateBIC, electronicFormatIBAN } from "ibantools";
import { validate as validateEmail } from "email-validator";
import formatDate from "date-fns/format";
import parseDate from "date-fns/parse";
import { isPossiblePhoneNumber } from "libphonenumber-js/core";
import metadata from "libphonenumber-js/metadata.min.json";

import { v4 as uuidv4 } from "uuid";

import {
  LegalFormType,
  LEGAL_FORMS,
  TITLES,
  TitlesType,
  CompanySizesType,
  COMPANY_SIZES,
  TurnoverType,
  BenefitType,
  TURNOVER,
  BENEFIT,
  COUNTRY,
  CountryType,
  INCOME_RANGE,
  IncomeRangeType,
  PERSONAL_ASSETS,
  PersonalAssetsType,
} from "./constants";

// Types for select inputs
const LegalForm = types.union(...LEGAL_FORMS.map((bt) => types.literal(bt)));
const isLegalForm = (
  maybeLegalForm: unknown,
): maybeLegalForm is LegalFormType => {
  return (
    typeof maybeLegalForm === "string" &&
    LEGAL_FORMS.includes(maybeLegalForm as any)
  );
};
const CompanySize = types.union(
  ...COMPANY_SIZES.map((bt) => types.literal(bt)),
);
const isCompanySize = (maybe: unknown): maybe is CompanySizesType => {
  return typeof maybe === "string" && COMPANY_SIZES.includes(maybe as any);
};
const Turnover = types.union(...TURNOVER.map((bt) => types.literal(bt)));
const isTurnover = (maybe: unknown): maybe is TurnoverType => {
  return typeof maybe === "string" && TURNOVER.includes(maybe as any);
};
const Benefit = types.union(...BENEFIT.map((bt) => types.literal(bt)));
const isBenefit = (maybe: unknown): maybe is BenefitType => {
  return typeof maybe === "string" && BENEFIT.includes(maybe as any);
};

const Title = types.union(...TITLES.map((bt) => types.literal(bt)));
const isTitle = (maybe: unknown): maybe is TitlesType => {
  return typeof maybe === "string" && TITLES.includes(maybe as any);
};
const Country = types.union(...COUNTRY.map((bt) => types.literal(bt)));
const isCountry = (maybe: unknown): maybe is CountryType => {
  return typeof maybe === "string" && COUNTRY.includes(maybe as any);
};
const IncomeRange = types.union(...INCOME_RANGE.map((bt) => types.literal(bt)));
const isIncomeRange = (maybe: unknown): maybe is IncomeRangeType => {
  return typeof maybe === "string" && INCOME_RANGE.includes(maybe as any);
};
const PersonalAssets = types.union(
  ...PERSONAL_ASSETS.map((bt) => types.literal(bt)),
);
const isPersonalAssets = (maybe: unknown): maybe is PersonalAssetsType => {
  return typeof maybe === "string" && PERSONAL_ASSETS.includes(maybe as any);
};

const validateDate = (formattedDate: string, format: string) => {
  const date = parseDate(formattedDate, format, new Date());
  return !isNaN(date.getTime());
};

// Convert a date to the correct format
// Note that it returns the input string if the parsing is not working
const convertDateFormats = (
  formattedDate: string,
  fromFormat: string,
  toFormat: string,
) => {
  const date = parseDate(formattedDate, fromFormat, new Date());
  if (isNaN(date.getTime())) return formattedDate;
  return formatDate(date, toFormat);
};

export const Address = types
  .model("Address", {
    addressText: types.optional(types.string, ""),
    placeId: types.maybe(types.string),
    addressFull: types.optional(
      types.model({
        address1: types.maybe(types.string),
        address2: types.maybe(types.string),
        address3: types.maybe(types.string),
        city: types.maybe(types.string),
        postcode: types.maybe(types.string),
        country: types.maybe(types.string),
      }),
      {},
    ),
  })
  .views((self) => ({
    get isMissing() {
      return !self.placeId;
    },
  }))
  .actions((self) => ({
    clearFullAddress() {
      self.addressFull.address1 = undefined;
      self.addressFull.address2 = undefined;
      self.addressFull.address3 = undefined;
      self.addressFull.city = undefined;
      self.addressFull.postcode = undefined;
      self.addressFull.country = undefined;
    },
    updateDetails({
      address1,
      city,
      postcode,
      country,
      address2,
      address3,
      placeId,
      addressText,
    }: {
      city?: string;
      country?: string;
      address1?: string;
      address2?: string;
      address3?: string;
      postcode?: string;
      placeId?: string;
      addressText: string;
    }) {
      self.addressFull.address1 = address1;
      self.addressFull.address2 = address2;
      self.addressFull.address3 = address3;
      self.addressFull.city = city;
      self.addressFull.postcode = postcode;
      self.addressFull.country = country;
      self.placeId = placeId;
      self.addressText = addressText;
    },
  }))
  .views((self) => ({
    get errors() {
      if (self.addressText && !self.placeId)
        return [
          "Adresse imprécise. Sélectionnez une des recommandations proposées",
        ];
      return [];
    },
  }));
export interface AddressType extends Instance<typeof Address> {}

export const BusinessInfo = types
  .model("BusinessInfo", {
    legalName: types.maybe(types.string),
    SIREN: types.maybe(types.string),
    legalRegistrationDate: types.maybe(types.string),
    legalForm: types.maybe(LegalForm),
    capital: types.maybe(types.number),

    address: types.optional(Address, {}),
    email: types.maybe(types.string),
    phoneNumber: types.maybe(types.string),

    companySize: types.maybe(CompanySize),
    NAFCode: types.maybe(types.string),
    VATCode: types.maybe(types.string),

    turnover: types.maybe(Turnover),
    benefit: types.maybe(Benefit),

    KBISId: types.map(types.maybeNull(types.string)),

    IBAN: types.maybe(types.string),
    SWIFT: types.maybe(types.string),

    noActivityOutsideEU: types.optional(types.boolean, true),
    noFinancialActivity: types.optional(types.boolean, true),
    acceptCGV: types.optional(types.boolean, true),
  })
  .views((self) => ({
    get formattedLegalRegistrationDate() {
      if (!self.legalRegistrationDate) return self.legalRegistrationDate;
      return convertDateFormats(
        self.legalRegistrationDate,
        "yyyy-MM-dd",
        "dd/MM/yyyy",
      );
    },

    get hasKBISUploaded() {
      return Array.from(self.KBISId.entries()).some(
        ([, path]) => path !== null,
      );
    },
  }))
  .actions((self) => ({
    setLegalName(newName: string) {
      self.legalName = newName;
    },
    setSIREN(newSIREN: string) {
      self.SIREN = newSIREN;
    },
    setLegalRegistrationDate(newRegistrationDate: string) {
      self.legalRegistrationDate = convertDateFormats(
        newRegistrationDate,
        "dd/MM/yyyy",
        "yyyy-MM-dd",
      );
    },
    setDate(newRegistrationDate: string) {
      self.legalRegistrationDate = newRegistrationDate;
    },
    setLegalForm(newLegalForm: string) {
      if (!isLegalForm(newLegalForm)) return;
      self.legalForm = newLegalForm;
    },
    setCapital(newCapital: number) {
      self.capital = newCapital ? newCapital : 0;
    },
    setEmail(newEmail: string) {
      self.email = newEmail;
    },
    setPhoneNumber(newPhoneNumber: string) {
      self.phoneNumber = newPhoneNumber;
    },
    setCompanySize(newCompanySize: string) {
      if (!isCompanySize(newCompanySize)) return;
      self.companySize = newCompanySize;
    },
    setNAFCode(newNAFCode: string) {
      self.NAFCode = newNAFCode;
    },
    setVATCode(newVATCode: string) {
      self.VATCode = newVATCode;
    },
    setTurnover(newTurnover: string) {
      if (!isTurnover(newTurnover)) return;
      self.turnover = newTurnover;
    },
    setBenefit(newBenefit: string) {
      if (!isBenefit(newBenefit)) return;
      self.benefit = newBenefit;
    },
    addKBISRef(KBISref: string, KBISPath: string) {
      self.KBISId.set(KBISref, KBISPath);
    },
    deleteKBISRef(KBISref: string) {
      self.KBISId.set(KBISref, null);
    },
    setIBAN(newIBAN: string) {
      self.IBAN = newIBAN;
    },
    setSWIFT(newSWIFT: string) {
      self.SWIFT = newSWIFT;
    },
    setNoActivityOutsideEU(noActivityOutsideEU: boolean) {
      self.noActivityOutsideEU = noActivityOutsideEU;
    },
    setNoFinancialActivity(noFinancialActivity: boolean) {
      self.noFinancialActivity = noFinancialActivity;
    },
    setAcceptCGV(acceptCGV: boolean) {
      self.acceptCGV = acceptCGV;
    },
  }))
  .views((self) => ({
    get legalRegistrationDateErrors() {
      if (!self.legalRegistrationDate) return [];
      const errors: string[] = [];
      if (!validateDate(self.legalRegistrationDate, "yyyy-mm-dd"))
        errors.push("Date non valide (format dd/mm/yyyy)");
      return errors;
    },

    get dateErrors() {
      if (self.legalRegistrationDate === undefined) return [];
      const errors: string[] = [];
      if (self.legalRegistrationDate === "")
        errors.push(
          "Format de date non valide. Nécessite une date complète, par exemple: 14.07.2021",
        );
      return errors;
    },

    get capitalErrors() {
      if (self.capital === undefined) return [];
      const errors: string[] = [];
      if (self.capital === 0)
        errors.push("Le capital social ne peut pas être de 0");
      return errors;
    },

    get emailErrors() {
      if (!self.email) return [];
      const errors: string[] = [];
      if (!validateEmail(self.email)) errors.push("Email non valide");
      return errors;
    },

    get addressErrors() {
      if (!self.address) return [];
      return self.address.errors;
    },

    get phoneErrors() {
      if (!self.phoneNumber) return [];
      const errors: string[] = [];
      if (!isPossiblePhoneNumber(self.phoneNumber, metadata))
        errors.push("Mauvais format de numéro de téléphone");
      return errors;
    },

    get SIRENErrors() {
      if (!self.SIREN) return [];
      const errors: string[] = [];
      if (self.SIREN.replace(/ /g, "").length !== 9)
        errors.push("Mauvais format de SIREN. Nécessite 9 chiffres");
      return errors;
    },

    get NAFErrors() {
      if (!self.NAFCode) return [];
      const errors: string[] = [];
      const regex = /^[\d]{4}[\D]{1}$/;
      if (!regex.test(self.NAFCode.replace(/ /g, "")))
        errors.push(
          "Mauvais format de code NAF. Nécessite 4 chiffres et une lettre, par exemple: 0000A",
        );
      return errors;
    },

    get VATErrors() {
      if (!self.VATCode) return [];
      const errors: string[] = [];
      const regex = /^FR[\d]{11}$/;
      if (!regex.test(self.VATCode.replace(" ", "")))
        errors.push(
          "Mauvais format de numéro TVA. Nécessite 2 lettres et 11 chiffres, par exemple: FR 65901240630",
        );
      return errors;
    },

    get SWIFTErrors() {
      if (!self.SWIFT) return [];
      const errors: string[] = [];
      if (!validateBIC(self.SWIFT).valid) errors.push("Mauvais format de code");
      return errors;
    },

    get IBANErrors() {
      if (!self.IBAN) return [];
      const errors: string[] = [];
      const iban = electronicFormatIBAN(self.IBAN);
      if (!iban || !validateIBAN(iban).valid)
        errors.push("Mauvais format de code");
      return errors;
    },

    get noActivityOutsideEUErrors() {
      if (self.noActivityOutsideEU) return [];
      return [
        "LocallyPay n'est pas disponible si vous maintenez des activités commerciales hors de l'Union Européenne.",
      ];
    },

    get noFinancialActivityErrors() {
      if (self.noFinancialActivity) return [];
      return [
        "LocallyPay n'est pas disponible si vous maintenez ce type d'activités financières.",
      ];
    },

    get acceptCGVErrors() {
      if (self.acceptCGV) return [];
      return [
        "Vous devez accepter les conditions générales de vente (CGV) de LocallyPay pour utiliser la plateforme.",
      ];
    },
  }))
  .views((self) => ({
    get missingFields() {
      const missingFields = [];
      if (!self.legalName) missingFields.push("legalName");
      if (!self.SIREN) missingFields.push("SIREN");
      if (!self.legalRegistrationDate)
        missingFields.push("legalRegistrationDate");
      if (!self.legalForm) missingFields.push("legalForm");
      if (!self.capital) missingFields.push("capital");
      if (self.address.isMissing) missingFields.push("address");
      if (!self.email) missingFields.push("email");
      if (!self.phoneNumber) missingFields.push("phoneNumber");
      if (!self.companySize) missingFields.push("companySize");
      if (!self.NAFCode) missingFields.push("NAFCode");
      if (!self.VATCode) missingFields.push("VATCode");
      if (!self.turnover) missingFields.push("turnover");
      if (!self.benefit) missingFields.push("benefit");
      if (!self.hasKBISUploaded) missingFields.push("KBISId");
      if (!self.IBAN) missingFields.push("IBAN");
      if (!self.SWIFT) missingFields.push("SWIFT");

      return missingFields;
    },

    get errorMessages() {
      const messages = new Map();

      if (self.legalRegistrationDateErrors.length) {
        messages.set("legalRegistrationDate", self.legalRegistrationDateErrors);
      }

      if (self.capitalErrors.length) {
        messages.set("capital", self.capitalErrors);
      }

      if (self.emailErrors.length) {
        messages.set("email", self.emailErrors);
      }

      if (self.addressErrors.length) {
        messages.set("address", self.addressErrors);
      }

      if (self.phoneErrors.length) {
        messages.set("phone", self.phoneErrors);
      }

      if (self.SIRENErrors.length) {
        messages.set("SIREN", self.SIRENErrors);
      }

      if (self.NAFErrors.length) {
        messages.set("NAF", self.NAFErrors);
      }

      if (self.VATErrors.length) {
        messages.set("VAT", self.VATErrors);
      }
      if (self.SWIFTErrors.length) {
        messages.set("SWIFT", self.SWIFTErrors);
      }

      if (self.IBANErrors.length) {
        messages.set("IBAN", self.IBANErrors);
      }

      if (self.noActivityOutsideEUErrors.length) {
        messages.set("noActivityOutsideEU", self.noActivityOutsideEUErrors);
      }

      if (self.noFinancialActivityErrors.length) {
        messages.set("noFinancialActivity", self.noFinancialActivityErrors);
      }

      if (self.acceptCGVErrors.length) {
        messages.set("acceptCGV", self.acceptCGVErrors);
      }

      return messages;
    },
    get isValid() {
      return !this.errorMessages.size && !this.missingFields.length;
    },
  }));
export interface BusinessInfoType extends Instance<typeof BusinessInfo> {}

export const Person = types
  .model("Person", {
    id: types.identifier,
    title: types.maybe(Title),
    firstname: types.maybe(types.string),
    lastname: types.maybe(types.string),
    birthday: types.maybe(types.string),
    placeOfBirth: types.optional(Address, {}),
    nationality: types.maybe(Country),

    email: types.maybe(types.string),
    phoneNumber: types.maybe(types.string),
    address: types.optional(Address, {}),

    position: types.maybe(types.string),
    incomeRange: types.maybe(IncomeRange),
    personalAssets: types.maybe(PersonalAssets),

    frenchFiscalResident: types.optional(types.boolean, true),
    notUSPerson: types.optional(types.boolean, true),

    shareOfCapital: types.maybe(types.number),
    NIFFormId: types.map(types.maybeNull(types.string)),
    taxNumber: types.maybe(types.string),

    personalIdId: types.map(types.maybeNull(types.string)),
    livenessId: types.maybe(types.string),
  })
  .views((self) => ({
    get emailErrors() {
      if (!self.email) return [];
      const errors: string[] = [];
      if (!validateEmail(self.email)) errors.push("Email non valide");
      return errors;
    },
    get isLegalRepresentative(): boolean {
      return (
        getParentOfType(self, BusinessOnboarding).legalRepresentative?.id ===
        self.id
      );
    },

    get isBeneficiary(): boolean {
      return getParentOfType(self, BusinessOnboarding).beneficiaries.some(
        (beneficiary) => beneficiary.id === self.id,
      );
    },

    get hasPersonalIdUploaded() {
      return Array.from(self.personalIdId.entries()).some(
        ([, path]) => path !== null,
      );
    },

    get hasNIFUploaded() {
      return Array.from(self.NIFFormId.entries()).some(
        ([, path]) => path !== null,
      );
    },
  }))
  .actions((self) => ({
    setTitle(newTitle: string) {
      if (!isTitle(newTitle)) return;
      self.title = newTitle;
    },
    setFirstname(newFirstname: string) {
      self.firstname = newFirstname;
    },
    setLastname(newLastname: string) {
      self.lastname = newLastname;
    },
    setBirthday(newBirthday: string) {
      self.birthday = newBirthday;
    },
    setNationality(newNationality: string) {
      if (!isCountry(newNationality)) return;
      self.nationality = newNationality;
    },
    setEmail(newEmail: string) {
      self.email = newEmail;
    },
    setPhoneNumber(newPhoneNumber: string) {
      self.phoneNumber = newPhoneNumber;
    },
    setPosition(newPosition: string) {
      self.position = newPosition;
    },
    setIncomeRange(newIncomeRange: string) {
      if (!isIncomeRange(newIncomeRange)) return;
      self.incomeRange = newIncomeRange;
    },
    setPersonalAssets(newPersonalAssets: string) {
      if (!isPersonalAssets(newPersonalAssets)) return;
      self.personalAssets = newPersonalAssets;
    },
    setFrenchFiscalResident(isFrenchFiscalResident: boolean) {
      self.frenchFiscalResident = isFrenchFiscalResident;
    },
    setNotUSPerson(notUSPerson: boolean) {
      self.notUSPerson = notUSPerson;
    },
    setShareOfCapital(newShareOfCapital: number) {
      self.shareOfCapital = newShareOfCapital ? newShareOfCapital : 0;
    },
    addNIFFormRef(newNIFFormRef: string, newNIFFormPath: string) {
      self.NIFFormId.set(newNIFFormRef, newNIFFormPath);
    },
    deleteNIFFormRef(NIFFormRef: string) {
      self.NIFFormId.set(NIFFormRef, null);
    },
    setTaxNumber(newTaxINumber: string) {
      self.taxNumber = newTaxINumber;
    },
    addPersonalIdId(newPersonalIdRef: string, newPersonalIdPath: string) {
      self.personalIdId.set(newPersonalIdRef, newPersonalIdPath);
    },
    deletePersonalIdRef(personalIdRef: string) {
      self.personalIdId.set(personalIdRef, null);
    },
    setLivenessId(newLivenessId: string) {
      self.livenessId = newLivenessId;
    },

    setBeneficiary(isBeneficiary: boolean) {
      const onboarding = getParentOfType(self, BusinessOnboarding);

      if (isBeneficiary && !self.isBeneficiary) {
        onboarding.toggleBeneficiary(self as PersonType);
      }
      if (!isBeneficiary && self.isBeneficiary) {
        onboarding.toggleBeneficiary(self as PersonType);
      }
    },
  }))
  .views((self) => ({
    get emailErrors() {
      if (!self.email) return [];
      const errors: string[] = [];
      if (!validateEmail(self.email)) errors.push("Email non valide");
      return errors;
    },

    get placeOfBirthErrors() {
      if (!self.placeOfBirth) return [];
      return self.placeOfBirth.errors;
    },

    get addressErrors() {
      return self.address.errors;
    },

    get frenchFiscalResidentErrors() {
      if (self.frenchFiscalResident) return [];
      return [
        "LocallyPay n'est pour l'instant que disponible pour les résidents fiscaux français.",
      ];
    },

    get phoneErrors() {
      if (!self.phoneNumber) return [];
      const errors: string[] = [];
      if (!isPossiblePhoneNumber(self.phoneNumber, metadata))
        errors.push("Mauvais format de numéro de téléphone");
      return errors;
    },

    get shareOfCapitalErrors() {
      if (self.shareOfCapital === undefined) return [];
      const errors: string[] = [];
      if (self.shareOfCapital === 0)
        errors.push("Le pourcentage de capital détenu ne peut pas être de 0");
      return errors;
    },
  }))
  .views((self) => ({
    get missingFields() {
      const missingFields = [];

      if (!self.firstname) missingFields.push("firstname");
      if (!self.lastname) missingFields.push("lastname");
      if (!self.birthday) missingFields.push("birthday");
      if (!self.placeOfBirth) missingFields.push("placeOfBirth");
      if (!self.nationality) missingFields.push("nationality");

      if (!self.email) missingFields.push("email");
      if (!self.phoneNumber) missingFields.push("phoneNumber");
      if (self.address.isMissing) missingFields.push("address");

      if (!self.position) missingFields.push("position");

      if (!self.incomeRange) missingFields.push("incomeRange");
      if (!self.personalAssets) missingFields.push("personalAssets");

      if (!self.notUSPerson) {
        if (!self.hasNIFUploaded) missingFields.push("NIFFormId");
        if (!self.taxNumber) missingFields.push("taxNumber");
      }

      if (self.isBeneficiary) {
        if (!self.shareOfCapital) missingFields.push("shareOfCapital");
      }

      if (!self.isLegalRepresentative) {
        if (!self.hasPersonalIdUploaded) missingFields.push("personalIdId");
      }

      return missingFields;
    },

    get errorMessages() {
      const messages = new Map();

      if (self.placeOfBirthErrors.length) {
        messages.set("placeOfBirth", self.placeOfBirthErrors);
      }

      if (self.emailErrors.length) {
        messages.set("email", self.emailErrors);
      }

      if (self.addressErrors.length) {
        messages.set("address", self.addressErrors);
      }

      if (self.phoneErrors.length) {
        messages.set("phone", self.phoneErrors);
      }

      if (self.frenchFiscalResidentErrors.length) {
        messages.set("frenchFiscalResident", self.frenchFiscalResidentErrors);
      }

      if (self.shareOfCapitalErrors.length) {
        messages.set("shareOfCapital", self.shareOfCapitalErrors);
      }

      return messages;
    },
    get isValid() {
      return !this.errorMessages.size && !this.missingFields.length;
    },
  }));
export interface PersonType extends Instance<typeof Person> {}

const BusinessOnboarding = types
  .model("BusinessOnboarding", {
    id: types.identifier,
    status: types.string,
    businessId: types.maybe(types.string),
    businessInfo: types.optional(BusinessInfo, {}),
    people: types.array(Person),
    legalRepresentative: types.maybe(types.reference(Person)),
    beneficiaries: types.array(
      types.reference(Person, {
        onInvalidated(ev) {
          ev.removeRef();
        },
      }),
    ),
  })
  .views((self) => ({
    get isValid() {
      return (
        self.businessInfo.isValid &&
        self.beneficiaries.length > 0 &&
        self.legalRepresentative !== undefined &&
        self.people.every((person) => person.isValid)
      );
    },
  }))
  .actions((self) => ({
    createLegalRep() {
      const person = Person.create({ id: uuidv4() });
      self.people.push(person);
      self.legalRepresentative = person;
      return person;
    },
    createBeneficiary() {
      const person = Person.create({ id: uuidv4() });
      self.people.push(person);
      self.beneficiaries.push(person);
      return person;
    },
    deleteBeneficiary(person: PersonType) {
      detach(person);
      self.people.remove(person);
    },
    toggleBeneficiary(person: PersonType) {
      if (person.isBeneficiary) {
        self.beneficiaries.remove(person);
      } else {
        self.beneficiaries.push(person);
      }
    },
    getBeneficiary(personId: string): PersonType | null {
      const beneficiary = self.beneficiaries.find(
        (beneficiary) => beneficiary.id === personId,
      );
      if (!beneficiary) return null;
      return beneficiary;
    },
  }));

export default BusinessOnboarding;

export interface BusinessOnboardingType
  extends Instance<typeof BusinessOnboarding> {}
