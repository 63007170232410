import styled from "styled-components/macro";
import { BREAKPOINT_MOBILE } from "../../theme";

const RowGrid = styled.div`
  display: grid;

  @media screen and ${BREAKPOINT_MOBILE} {
    display: block;
  }
`;

export const RowTwoColumns = styled(RowGrid)`
  grid-template-columns: 1fr 1fr;
`;

export const RowOneThirdTwoThirdColumn = styled(RowGrid)`
  grid-template-columns: 1fr 2fr;
`;

export const RowThreeColumns = styled(RowGrid)`
  grid-template-columns: 1fr 1fr 1fr;
`;
