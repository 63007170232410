import { observer } from "mobx-react";
import { MainSection } from "../components/Sections";

export default observer(function Error() {
  return (
    <MainSection centerContent>
      <h2>Erreur de chargement</h2>
      <p>
        Merci d'utiliser le lien qui vous a été envoyé pour charger le
        formulaire.
      </p>
    </MainSection>
  );
});

export function Crash() {
  return (
    <MainSection centerContent>
      <h2>Erreur</h2>
      <p>Une erreur s'est produite, nos équipes en ont été informées.</p>
    </MainSection>
  );
}
