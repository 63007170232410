export default function IconDownload({ width = 230 }) {
  return (
    <svg width={width} viewBox="0 0 60 60">
      <path
        d="M47.5 45h-35a2.5 2.5 0 0 0 0 5h35a2.5 2.5 0 0 0 0-5Z"
        fill="currentColor"
      />
      <path
        d="M10 42.5v5a2.5 2.5 0 0 0 5 0v-5a2.5 2.5 0 0 0-5 0ZM45 42.5v5a2.5 2.5 0 0 0 5 0v-5a2.5 2.5 0 0 0-5 0ZM30 37.5a2.5 2.5 0 0 1-1.45-.45l-10-7.05a2.5 2.5 0 1 1 2.9-4.075L30 31.9l8.5-6.4a2.5 2.5 0 0 1 3 4l-10 7.5a2.5 2.5 0 0 1-1.5.5Z"
        fill="currentColor"
      />
      <path
        d="M30 32.5a2.5 2.5 0 0 1-2.5-2.5V10a2.5 2.5 0 0 1 5 0v20a2.5 2.5 0 0 1-2.5 2.5Z"
        fill="currentColor"
      />
    </svg>
  );
}
