import { useState, useEffect, useRef, useCallback } from "react";

const useChangeFcn = function useChangeFcn<T>(fcn?: (value: T) => void) {
  const fcnRef = useRef(fcn);

  useEffect(() => {
    fcnRef.current = fcn;
  }, [fcn]);

  return useCallback((val) => {
    if (!fcnRef.current) return;
    return fcnRef.current(val);
  }, []);
};

export default function useLocalStateSync<T>(
  value?: T,
  onChange?: (value: T) => void,
  timeout = 5000,
) {
  const [localState, setLocalState] = useState(value);
  const changeFcn = useChangeFcn(onChange);

  const refreshTimeout = useRef(timeout);

  useEffect(() => {
    refreshTimeout.current = timeout;
  }, [timeout]);
  useEffect(() => {
    setLocalState(value);
  }, [value]);

  // This should trigger the onChange value after a timeout of time when the
  // localState has not changed, but no blur event has been triggered
  useEffect(() => {
    if (localState === value) return;

    const handle = setTimeout(() => {
      changeFcn(localState);
    }, refreshTimeout.current);

    return () => clearTimeout(handle);
  }, [localState, value, changeFcn]);

  const onBlur = () => {
    changeFcn(localState);
  };

  return [localState, setLocalState, onBlur] as const;
}
