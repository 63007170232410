import styled from "styled-components/macro";

import LogoIcon from "../icons/Logo";
import { BREAKPOINT_MOBILE } from "../theme";

const HeaderContainer = styled.header`
  display: flex;
  align-content: flex-start;

  @media screen and ${BREAKPOINT_MOBILE} {
    flex-flow: column wrap;
  }
`;

const Logo = styled.div`
  padding: 1rem 3rem;
  margin: auto 0;

  @media screen and ${BREAKPOINT_MOBILE} {
    padding: 1rem 1rem;
    margin: 0;
  }
`;
const LogoPlaceholder = styled(Logo)`
  visibility: hidden;

  @media screen and ${BREAKPOINT_MOBILE} {
    display: none;
  }
`;

const Title = styled.h1`
  margin: 0;
  line-height: 1.3;
  font-size: var(--font-size-biggest);

  text-transform: uppercase;
  font-weight: bold;

  margin: auto;
  width: 100%;
  width: min(100%, 940px);
`;

const ColorHighlight = styled.span`
  padding: 0 2rem;
  box-decoration-break: clone;
  -webkit-box-decoration-break: clone;
  background-color: var(--color-blue);
  color: white;
`;

const Header = ({ children }: { children?: React.ReactNode }) => {
  return (
    <HeaderContainer>
      <Logo>
        <LogoIcon />
      </Logo>

      {children && (
        <Title>
          <ColorHighlight>{children}</ColorHighlight>
        </Title>
      )}

      <LogoPlaceholder>
        <LogoIcon />
      </LogoPlaceholder>
    </HeaderContainer>
  );
};

export default Header;
