import { PersonType, BusinessInfoType } from "../../state/model";
import Spinner from "../Spinner";
import NoticeArea from "../NoticeArea";
import { BlueText, RedText } from "../../theme";

const messageSentData = (
  <p>
    <BlueText>
      Vos informations ont déjà été transmises, et ne peuvent plus être
      modifiées ici.
    </BlueText>
  </p>
);

const messageErrorData = (
  <p>
    <RedText>
      Certains champs ont des erreurs non-résolues. Vous pouvez toutefois sauver
      les informations actuelles et mettre à jour ces champs plus tard.
    </RedText>
  </p>
);

const messageMissing = (
  <p>
    <RedText>
      Certains champs ne sont pas remplis. Vous pouvez toutefois sauver les
      informations actuelles et remplir ces champs plus tard.
    </RedText>
  </p>
);

const messageCorrectData = (
  <p>
    <BlueText>
      Vos informations sont correctes, vous pouvez sauver et passer à la suite.
    </BlueText>
  </p>
);

export const FormStatus = function FormStatus({
  isLoading,
  modelData,
  disabled,
}: {
  isLoading: boolean;
  modelData: PersonType | BusinessInfoType;
  disabled: boolean;
}) {
  let message = messageCorrectData;
  if (disabled) {
    message = messageSentData;
  } else if (modelData.errorMessages.size > 0) {
    message = messageErrorData;
  } else if (modelData.missingFields.length > 0) {
    message = messageMissing;
  }

  return isLoading ? <Spinner /> : <NoticeArea>{message}</NoticeArea>;
};

export default FormStatus;
