import React, { useEffect, ReactElement } from "react";
import styled from "styled-components/macro";
import ReactSelect from "react-select";
import PlacesAutocomplete, {
  geocodeByPlaceId,
} from "react-places-autocomplete";
import PhoneInput from "react-phone-number-input/input";

import FormItem from "./FormItem";
import { AddressType } from "../../state/model";
import useLocalStateSync from "../../hooks/useLocalStateSync";

interface AddressOptionProps {
  readonly active: boolean;
}

const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
`;
const CheckboxText = styled.label`
  display: inline-block;
  margin-left: 0.5em;
  font-size: var(--font-size-normal);
`;

const placeholderStyle = `
  color: var(--color-gray);
  opacity: 1;
`;

const Input = styled.input<{ isError: boolean }>`
  box-sizing: border-box;
  width: 100%;
  padding: 0.6rem 0.8rem;
  border: 1px solid var(--color-gray-light);
  border-color: ${(props) => (props.isError ? "var(--color-red)" : "")};
  box-shadow: 1px 1px 2px var(--color-gray-light);
  border-radius: 5px;
  font: inherit;

  font-size: var(--font-size-normal);
  line-height: 1.5em;

  ::placeholder {
    ${placeholderStyle}
  }

  &:hover {
    border-color: hsl(0, 0%, 70%);
  }

  &[type="date"] {
    ${(props) => (!props.value ? placeholderStyle : "")};
  }

  &:disabled {
    background: var(--color-blue-vlight);
    color: var(--color-gray);
  }
`;

const selectStyles = {
  container: (provided: any) => ({ ...provided, flex: 1 }),
  control: (provided: any) => ({
    ...provided,
    border: "1px solid var(--color-gray-light)",
    boxShadow: "1px 1px 2px var(--color-gray-light)",
    borderRadius: "5px",
    fontSize: "var(--font-size-normal)",
  }),
  valueContainer: (provided: any) => ({
    ...provided,
    fontSize: "var(--font-size-normal)",
    padding: "calc(0.6rem - 4px) calc(1rem - 2px)",
  }),
  indicatorSeparator: (provided: any) => ({
    ...provided,
    backgroundColor: "var(--color-gray-light)",
  }),
};

const CheckboxItem = styled.input`
  accent-color: var(--color-blue);
  padding: 1em;
  min-width: 15px;
  height: 15px;
`;

const AddressInputContainer = styled.div`
  flex: 1;
`;
const AddressOptionsContainer = styled.div`
  margin-top: 0.5em;
  border: 1px solid var(--color-gray-light);
  box-shadow: 1px 1px 2px var(--color-gray-light);
  border-radius: 5px;

  &:hover {
    border-color: hsl(0, 0%, 70%);
  }
`;
const AddressOption = styled.div<AddressOptionProps>`
  cursor: pointer;
  padding: 0.5em 1em;
  background: ${(props) => (props.active ? "var(--color-green)" : "")};
  color: ${(props) => (props.active ? "white" : "")};
`;

export const InputText = function InputText({
  id,
  label,
  textarea,
  tooltipText,
  required = true,
  placeholder,
  value = "",
  onChange,
  errorMessages = [],
  disabled = false,
}: {
  id: string;
  label?: string;
  textarea?: boolean;
  tooltipText?: string;
  required?: boolean;
  placeholder?: string;
  value?: string;
  onChange: (value: string) => void;
  errorMessages?: string[];
  disabled?: boolean;
}) {
  const [inputText, setInputText, onBlur] = useLocalStateSync(value, onChange);

  const handleTextUpdate = function (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) {
    setInputText(event.currentTarget.value);
  };

  useEffect(() => {
    setInputText(value);
  }, [value, setInputText]);

  const inputProps = {
    placeholder: placeholder ?? "",
    onChange: handleTextUpdate,
    onBlur: onBlur,
    value: inputText,
    isError: !!errorMessages.length,
    disabled: disabled,
  };

  return (
    <FormItem
      id={id}
      label={label}
      tooltipText={tooltipText}
      required={required}
      errorMessages={errorMessages}
    >
      {textarea ? (
        <Input id={id} rows={3} as="textarea" {...inputProps} />
      ) : (
        <Input id={id} type="text" {...inputProps} />
      )}
    </FormItem>
  );
};

export const InputDate = function InputDate({
  id,
  label,
  tooltipText,
  required = true,
  placeholder,
  value = "",
  onChange,
  errorMessages = [],
  disabled = false,
}: {
  id: string;
  label?: string;
  tooltipText?: string;
  required?: boolean;
  placeholder?: string;
  value?: string;
  onChange: (value: string) => void;
  errorMessages?: string[];
  disabled?: boolean;
}) {
  const [inputText, setInputText, onBlur] = useLocalStateSync(value, onChange);

  const handleTextUpdate = function (event: React.FormEvent<HTMLInputElement>) {
    setInputText(event.currentTarget.value);
  };

  useEffect(() => {
    setInputText(value);
  }, [value, setInputText]);

  return (
    <FormItem
      id={id}
      label={label}
      tooltipText={tooltipText}
      required={required}
      errorMessages={errorMessages}
    >
      <Input
        id={id}
        type="date"
        placeholder={placeholder ?? ""}
        onChange={handleTextUpdate}
        onBlur={onBlur}
        value={inputText}
        isError={!!errorMessages.length}
        disabled={disabled}
      />
    </FormItem>
  );
};

export const InputNumber = function InputNumber({
  id,
  label,
  tooltipText,
  required = true,
  placeholder,
  value = 0,
  onChange,
  errorMessages = [],
  disabled = false,
}: {
  id: string;
  label?: string;
  tooltipText?: string;
  required?: boolean;
  placeholder?: string;
  value?: number;
  onChange: (value: number) => void;
  errorMessages?: string[];
  disabled?: boolean;
}) {
  const [inputText, setInputText, onBlur] = useLocalStateSync(value, onChange);

  const handleTextUpdate = function (event: React.FormEvent<HTMLInputElement>) {
    setInputText(parseFloat(event.currentTarget.value));
  };

  useEffect(() => {
    setInputText(value);
  }, [value, setInputText]);

  return (
    <FormItem
      id={id}
      label={label}
      tooltipText={tooltipText}
      required={required}
      errorMessages={errorMessages}
    >
      <Input
        id={id}
        type="number"
        placeholder={placeholder ?? ""}
        onChange={handleTextUpdate}
        onBlur={onBlur}
        value={inputText === 0 ? 0 : inputText ? inputText : ""}
        isError={!!errorMessages.length}
        disabled={disabled}
      />
    </FormItem>
  );
};

export const Select = function Select({
  id,
  label,
  tooltipText,
  required = true,
  value,
  onChange,
  errorMessages = [],
  options,
  optionLabels = [],
  disabled = false,
}: {
  id: string;
  label?: string;
  tooltipText?: string;
  required?: boolean;
  value?: string;
  onChange: (value: string) => void;
  errorMessages?: string[];
  options: Array<string>;
  optionLabels?: Array<string>;
  disabled?: boolean;
}) {
  const myOptions: { label: string; value: string }[] = options.map((f, i) => ({
    value: f,
    label: optionLabels[i] || f,
  }));

  return (
    <FormItem
      id={id}
      label={label}
      tooltipText={tooltipText}
      required={required}
      errorMessages={errorMessages}
    >
      <ReactSelect
        styles={selectStyles}
        placeholder="Choisir..."
        value={myOptions.find((v) => v.value === value)}
        options={myOptions}
        onChange={(v) => onChange(v?.value || "")}
        isDisabled={disabled}
      />
    </FormItem>
  );
};

export const Checkbox = function Checkbox({
  id,
  label,
  checkboxText,
  tooltipText,
  value,
  onChange,
  errorMessages = [],
  disabled = false,
}: {
  id: string;
  label?: string;
  checkboxText?: string | ReactElement;
  tooltipText?: string;
  value: boolean;
  onChange?: (value: boolean) => void;
  errorMessages?: string[];
  disabled?: boolean;
}) {
  const handleCheckboxUpdate = function (
    event: React.FormEvent<HTMLInputElement>,
  ) {
    onChange && onChange(!!event.currentTarget.checked);
  };

  return (
    <FormItem
      id={id}
      label={label}
      tooltipText={tooltipText}
      errorMessages={errorMessages}
    >
      <CheckboxContainer>
        <CheckboxItem
          id={id}
          type="checkbox"
          checked={value}
          onChange={handleCheckboxUpdate}
          disabled={disabled}
        />
        {checkboxText && (
          <CheckboxText htmlFor={id}>{checkboxText}</CheckboxText>
        )}
      </CheckboxContainer>
    </FormItem>
  );
};

interface AddressChange {
  city?: string;
  country?: string;
  address1?: string;
  postcode?: string;
  placeId?: string;
  addressText: string;
}

export const InputAddress = function InputAddress({
  id,
  label,
  tooltipText,
  required = true,
  placeholder,
  precision = "address",
  value,
  onChange,
  errorMessages = [],
  disabled = false,
}: {
  id: string;
  label?: string;
  tooltipText?: string;
  required?: boolean;
  placeholder?: string;
  precision?: "address" | "(regions)";
  value: AddressType;
  onChange: (v: AddressChange) => void;
  errorMessages?: string[];
  disabled?: boolean;
}) {
  const fetchAndUpdate = async ({
    placeId,
    addressText,
  }: {
    placeId?: string;
    addressText: string;
  }) => {
    if (!placeId) {
      onChange({ addressText });
      return;
    }
    const placeInfo = await geocodeByPlaceId(placeId);

    const change: AddressChange = {
      placeId,
      addressText,
    };

    let street = { route: "", nr: "" };
    placeInfo[0].address_components.forEach(
      ({
        types,
        long_name,
        short_name,
      }: {
        types: string[];
        long_name: string;
        short_name: string;
      }) => {
        if (types.includes("route")) street.route = long_name;
        if (types.includes("street_number")) street.nr = long_name;
        if (types.includes("locality")) change.city = long_name;
        if (types.includes("postal_code")) change.postcode = long_name;
        if (types.includes("country")) change.country = short_name;
      },
    );
    change.address1 = [street.route, street.nr].filter((v) => v).join(" ");
    onChange(change);
  };

  const [inputAddress, setInputAddress, onBlur] = useLocalStateSync(
    value,
    fetchAndUpdate,
  );

  const handleTextUpdate = function (value: string) {
    setInputAddress({
      ...inputAddress,
      addressText: value,
      placeId: "",
    } as AddressType);
  };

  const handleTextSelect = function (address: string, placeId?: string) {
    setInputAddress({
      ...inputAddress,
      addressText: address,
      placeId: placeId,
    } as AddressType);
  };

  useEffect(() => {
    setInputAddress(value);
  }, [value, setInputAddress]);

  return (
    <FormItem
      id={id}
      label={label}
      tooltipText={tooltipText}
      required={required}
      errorMessages={errorMessages}
    >
      <PlacesAutocomplete
        value={inputAddress?.addressText}
        onChange={handleTextUpdate}
        onSelect={handleTextSelect}
        searchOptions={{ types: [precision] }}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <AddressInputContainer>
            <Input
              {...getInputProps({
                placeholder: placeholder ?? "",
                onBlur: onBlur,
              })}
              isError={!!errorMessages.length}
              disabled={disabled}
            />
            {(loading || !!suggestions.length) && (
              <AddressOptionsContainer>
                {loading && <div>Chargement...</div>}
                {suggestions.map((suggestion) => {
                  return (
                    <AddressOption
                      {...getSuggestionItemProps(suggestion, {
                        active: suggestion.active,
                      })}
                      key={suggestion.description}
                    >
                      {suggestion.description}
                    </AddressOption>
                  );
                })}
              </AddressOptionsContainer>
            )}
          </AddressInputContainer>
        )}
      </PlacesAutocomplete>
    </FormItem>
  );
};

export const InputPhone = function InputPhone({
  id,
  label,
  tooltipText,
  required = true,
  placeholder,
  value = "",
  onChange,
  errorMessages = [],
  disabled = false,
}: {
  id: string;
  label?: string;
  tooltipText?: string;
  required?: boolean;
  placeholder?: string;
  value?: string;
  onChange: (value: string) => void;
  errorMessages?: string[];
  disabled?: boolean;
}) {
  const [inputText, setInputText, onBlur] = useLocalStateSync(value, onChange);

  const handleTextUpdate = function (value: string | undefined) {
    setInputText(value);
  };

  useEffect(() => {
    setInputText(value);
  }, [value, setInputText]);

  return (
    <FormItem
      id={id}
      label={label}
      tooltipText={tooltipText}
      required={required}
      errorMessages={errorMessages}
    >
      <PhoneInput
        id={id}
        placeholder={placeholder ?? ""}
        value={inputText ?? ""}
        onChange={handleTextUpdate}
        onBlur={onBlur}
        inputComponent={Input}
        defaultCountry="FR"
        style={{
          borderColor: errorMessages.length > 0 ? "var(--color-red)" : "",
        }}
        disabled={disabled}
      />
    </FormItem>
  );
};
