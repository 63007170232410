import { useState } from "react";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { observer } from "mobx-react";
import styled from "styled-components/macro";

import { ButtonItem } from "../components/form/FormButtons";
import useAppState from "../state/useAppState";
import { MainSection } from "../components/Sections";

const LoginInput = styled.input`
  margin-bottom: 1em;
  padding: 0.5em;
  width: 200px;
`;

export default observer(function Login() {
  const appState = useAppState();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");

  const loginHandler = async () => {
    try {
      await signInWithEmailAndPassword(getAuth(), email, password);
    } catch (e) {
      setError("Failed to log in.");
      console.error(e);
    }
  };

  const logoutHandler = async () => {
    getAuth().signOut();
    appState.setAdminLoggedIn(false);
  };

  return (
    <MainSection>
      {appState.adminLoggedIn ? (
        <>
          <h2>You are logged in</h2>
          <ButtonItem text="Log out" onClick={logoutHandler}></ButtonItem>
        </>
      ) : (
        <>
          <h2>Please log in</h2>
          <div>
            <LoginInput
              id="email"
              value={email}
              onChange={(event: React.FormEvent<HTMLInputElement>) =>
                setEmail(event.currentTarget.value)
              }
              placeholder="Email"
              autoFocus
            />
          </div>
          <div>
            <LoginInput
              id="password"
              type="password"
              value={password}
              onChange={(event: React.FormEvent<HTMLInputElement>) =>
                setPassword(event.currentTarget.value)
              }
              placeholder="Password"
            />
          </div>

          <ButtonItem text="Log in" onClick={loginHandler}></ButtonItem>

          {error && <p>{error}</p>}
        </>
      )}
    </MainSection>
  );
});
