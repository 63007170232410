import { useLocation, useHistory, Switch, Route } from "react-router-dom";
import styled from "styled-components/macro";
import format from "date-fns/format";
import { getSnapshot, applySnapshot } from "mobx-state-tree";
import useOnboarding from "../state/useOnboarding";
import { ButtonItem } from "./form/FormButtons";

const Menu = styled.div`
  background-color: white;
  border: 1px solid #e5e7eb;
  border-radius: 5px;
  position: fixed;
  right: 1em;
  bottom: 1em;
  padding: 0.5em;
  min-width: 100px;
  box-shadow: 1px 1px 2px #e5e7eb;
`;

export function isObject(item: any): boolean {
  return item && typeof item === "object" && !Array.isArray(item);
}

const deepMerge = (obj1: any, obj2: any): any => {
  const out = { ...obj1 };
  for (var attrname in obj2) {
    if (!obj2[attrname]) continue;
    if (isObject(obj1[attrname]) && isObject(obj2[attrname]))
      out[attrname] = deepMerge(obj1[attrname], obj2[attrname]);
    else out[attrname] = obj2[attrname];
  }
  return out;
};

const fillBusinessInfoSnapshot = (inputSnapshot: any): any => {
  const snapshot = JSON.parse(JSON.stringify(inputSnapshot));

  return deepMerge(
    {
      benefit: "50-149",
      legalName: "LocallyPax",
      NAFCode: "8299Z",
      capital: 36000,
      companySize: "1-9",
      legalForm: "5710",
      IBAN: "CH94 0900 0000 1732 1048 1",
      turnover: "250-999",
      email: `treezor+${format(new Date(), "yyyyMMddHHmm")}@locallypay.com`,
      SIREN: "901240630",
      KBISId: "onboardingRequests/test-file/KBISId.pdf",
      SWIFT: "ABNANL2A",
      VATCode: "FR 65901240630",
      phoneNumber: "+33684121212",
      legalRegistrationDate: "2021-08-06",
      noFinancialActivity: true,
      address: {
        addressText: "Avenue de Morges 25, Lausanne, Suisse",
        placeId: "ChIJC1buLdYxjEcRXZRcqlq4ggw",
        addressFull: {
          city: "Lausanne",
          country: "CH",
          address1: "Avenue de Morges 25",
          postcode: "1004",
        },
      },
    },
    snapshot,
  );
};

const fillPersonInfoSnapshot = (inputSnapshot: any): any => {
  const snapshot = JSON.parse(JSON.stringify(inputSnapshot));

  return deepMerge(
    {
      title: "M",
      firstname: "Alexandre",
      lastname: "Tilleman",
      address: {
        addressFull: {
          country: "FR",
          address1: "Rue René Barthélémy 28",
          postcode: "92160",
          city: "Antony",
        },
        addressText: "28 Rue René Barthélémy, Antony, France",
        placeId: "ChIJTx87Mx935kcRGe-JRzKnBAA",
      },
      phoneNumber: "+33612121212",
      personalIdId: "onboardingRequests/test-file/personalIdId.pdf",
      placeOfBirth: {
        addressFull: {
          country: "FR",
          city: "L'Haÿ-les-Roses",
        },
        addressText: "L'Haÿ-les-Roses, France",
        placeId: "ChIJnWPNV0lx5kcR9GmSosmVuKU",
      },
      frenchFiscalResident: true,
      nationality: "FR",
      position: "Co-Fondateur",
      incomeRange: "36-56",
      notUSPerson: true,
      email: `treezor+legalrep${format(
        new Date(),
        "yyyyMMddHHmm",
      )}@locallypay.com`,
      shareOfCapital: 49,
      birthday: "2021-10-01",
      personalAssets: "23-128",
      taxNumber: "qweqwe",
    },
    snapshot,
  );
};

const useToggleSearchParam = (searchParam: string): [boolean, () => void] => {
  const { search } = useLocation();
  const history = useHistory();
  const searchParams = new URLSearchParams(search);

  const niceViewer = searchParams.has(searchParam);
  const toggleView = () => {
    if (niceViewer) searchParams.delete(searchParam);
    else searchParams.set("display", "true");

    history.replace({ search: searchParams.toString() });
  };
  return [niceViewer, toggleView];
};

export default function FillerHelper() {
  const [isOpen] = useToggleSearchParam("filler");
  const onboarding = useOnboarding();

  const fillBusiness = () => {
    const newBusinessInfo = fillBusinessInfoSnapshot(
      getSnapshot(onboarding.businessInfo),
    );
    applySnapshot(onboarding.businessInfo, newBusinessInfo);
  };

  const fillLegalRep = () => {
    if (!onboarding.legalRepresentative) return;
    const newLegalRep = fillPersonInfoSnapshot(
      getSnapshot(onboarding.legalRepresentative),
    );
    applySnapshot(onboarding.legalRepresentative, newLegalRep);
  };

  const updateLatestBeneficiary = () => {
    const beneficiary =
      onboarding.beneficiaries[onboarding.beneficiaries.length - 1];
    if (!beneficiary) return;
    const newBeneficiary = fillPersonInfoSnapshot(getSnapshot(beneficiary));
    applySnapshot(beneficiary, newBeneficiary);
  };

  if (!isOpen) return null;
  return (
    <Menu>
      <Switch>
        <Route path={`/onboarding/:oid/commerce`}>
          <ButtonItem onClick={fillBusiness} text="fill business" />
        </Route>
        <Route path={`/onboarding/:oid/representant`}>
          <ButtonItem onClick={fillLegalRep} text="fill legal rep" />
        </Route>
        <Route path={`/onboarding/:oid/beneficiaire`}>
          <ButtonItem
            onClick={updateLatestBeneficiary}
            text="fill beneficiary"
          />
        </Route>
      </Switch>
    </Menu>
  );
}
